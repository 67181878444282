import * as types from './entity_reducer';

export function setSchema(state, collection, schema) {
    return {
        type: types.ENTITY_STATE_SET_SCHEMA,
        payload: {
            collection: collection,
            schema: schema,
        },
    };
}

export function setEntity(state, collection, entity) {
    return {
        type: types.ENTITY_STATE_SET_ENTITY,
        payload: {
            collection: collection,
            entity: entity,
        },
    };
}
export function setEntities(state, collection, entities) {
    return {
        type: types.ENTITY_STATE_SET_ENTITIES,
        payload: {
            collection: collection,
            entities: entities,
        },
    };
}

export function setEntitiesFetched(state, collection, value) {
    let fetched = Object.assign({}, state.fetched);
    fetched[collection] = value;
    return {
        type: types.ENTITY_STATE_ASSIGN,
        payload: {
            fetched: fetched,
        },
    };
}
