import * as store_helpers from './store_helpers';

export const MESSAGES_STATE_ASSIGN = 'MESSAGES_STATE_ASSIGN';
export const MESSAGES_STATE_SET_MESSAGE = 'MESSAGES_STATE_SET_MESSAGE';
export const MESSAGES_STATE_SET_MESSAGES = 'MESSAGES_STATE_SET_MESSAGES';
export const MESSAGES_CLEAR_MESSAGES = 'MESSAGES_CLEAR_MESSAGES';

let default_state = {
    all: [],
    by_id: {},
    by_ref: {},
    complete_messages_fetched: {},
};

function setMessageItem(current_state, msg) {
    let new_state = store_helpers.SetItem(msg, current_state);

    if (!new_state.by_ref.hasOwnProperty(msg.ref)) {
        new_state.by_ref[msg.ref] = [];
    }

    let index = new_state.by_ref[msg.ref].findIndex(
        existing_msg => existing_msg.id === msg.id,
    );
    if (index === -1) {
        new_state.by_ref[msg.ref].push(new_state.by_id[msg.id]);
    } else {
        new_state.by_ref[msg.ref][index] = new_state.by_id[msg.id];
    }

    return new_state;
}

export default function messageReducer(state = default_state, action) {
    switch (action.type) {
        case MESSAGES_STATE_ASSIGN: {
            return Object.assign({}, state, action.payload);
        }
        case MESSAGES_STATE_SET_MESSAGE: {
            let msg = action.payload;
            let new_state = setMessageItem(state, msg);
            new_state.by_ref[msg.ref].sort((a, b) => {
                return a.time > b.time ? 1 : -1;
            });
            return new_state;
        }
        case MESSAGES_STATE_SET_MESSAGES: {
            let msgs = action.payload;
            let new_state = Object.assign({}, state);
            let updated_refs = new Set();
            msgs.forEach(msg => {
                new_state = setMessageItem(new_state, msg);
                updated_refs.add(msg.ref);
            });
            updated_refs.forEach(ref => {
                new_state.by_ref[ref].sort((a, b) => {
                    return a.time > b.time ? 1 : -1;
                });
            });
            return new_state;
        }
        case MESSAGES_CLEAR_MESSAGES: {
            let new_state = Object.assign({}, default_state);
            return new_state;
        }
        default: {
            return state;
        }
    }
}
