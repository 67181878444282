import React from 'react';
import styled, {keyframes} from 'styled-components';
//import { FaTrash } from 'react-icons/fa';
//import oh from 'output-helpers';

class ItemOptionDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state ={
            open: false
        };

        const anim_fade_in = keyframes`
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        `;

        const anim_dropdown_enter = keyframes`
            0% {
                transform: translateY(-10px) scale(0);
            }
            100% {
                transform: translateY(5px) scale(1);
            }
        `;

        this.container = styled.div `
            cursor: pointer;
            display: inline-block;
            color: ${props => props.theme.colors.text};
            &:HOVER{
                opacity: 1;
            }
            .item_fade{
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
                display: none;
                z-index: 10;
                &.active{
                    display: block;
                    animation: ${anim_fade_in} 0.5s;
                }
            }
            .item_dropdown_block{
                position: absolute;
                top: 0;
                right: 0;
                vertical-align: top;
                z-index: 11;
                display: none;
                left: 0;
                text-align: left;
                transform-origin: top right;
                animation: ${anim_dropdown_enter} 0.3s both;
                &.active{
                    display: block;
                }
                ul{
                    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
                    list-style: none;
                    padding: 0;
                    margin-top: 40px;
                    background-color: #fff;
                    li{
                        color: ${props => props.theme.colors.text};
                        border-radius: 0 !important;
                        cursor: pointer;
                        padding: 20px;
                        font-size: 22px;
                        line-height: 1.5em;
                        border-bottom: 1px solid ${props => props.theme.colors.border};
                        &:last-child{
                            border-bottom: none;
                        }
                        &:HOVER{
                            background-color: ${props => props.theme.colors.brand[1]};
                            color: #fff;
                        }
                    }
                }
            }
        `;
    }

    render() {
        return (
            <this.container className="item_dropdown_wrapper" onClick={() => {this.setState({open: !this.state.open});}}>
                <div
                    className={`item_fade ${this.state.open ? 'active' : ''}`}
                    onClick={(e) => {

                    }}>
                </div>
                { this.props.children }
                <div className={`item_dropdown_block ${this.state.open ? 'active' : ''}`}>
                    <ul>
                        {
                            this.props.options.map((item, i) => {
                                return (
                                    <li key={i} onClick={() => {item.onClick();}}>
                                        {item.label}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </this.container>
        );
    }
}

export default ItemOptionDropdown;
