export const INTERACTION_STATE_ASSIGN = 'INTERACTION_STATE_ASSIGN';
export const INTERACTION_STATE_REMOVE_KEY = 'INTERACTION_STATE_REMOVE_KEY';

let default_state = {
    selected_category: 0,
    selected_view_type: 0,
    order_category: 0,
    order_task_layout: 0,
    entity_tab: 0
};

export default function userReducer(state = default_state, action) {
    switch (action.type) {
        case INTERACTION_STATE_ASSIGN: {
            return Object.assign({}, state, action.payload);
        }
        case INTERACTION_STATE_REMOVE_KEY: {
            let updated_state = Object.assign({}, state);
            if (updated_state.hasOwnProperty(action.payload)) {
                delete updated_state[action.payload];
            }
            return updated_state;
        }
        default: {
            return state;
        }
    }
}
