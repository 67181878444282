import * as React from 'react';
import styled from 'styled-components';
// this.props has everything passed in to config.props as well as the full config object in this.props.config.

class TriStateInput extends React.Component {
    constructor(props) {
        super(props);

        this.container = styled.div `
            text-align: left;
            p.multi_select_label {
                color: ${props => props.theme.colors.dark[1]};
                font-size: 14px;
                margin-bottom: 12px;
                font-weight: bold;
            }
            .grid_block{
                background-color: ${props => props.theme.colors.gray[3]};
                text-align: center;
                border-radius: 50px;
                position: relative;
                .grid_block_bg{
                    background-color: ${props => props.theme.colors.blue[1]};
                    position: absolute;
                    border-radius: 50px;
                    width: 33%;
                    top: 0;
                    bottom: 0;
                    transition: all 0.4s;
                }
                .grid_item{
                    position: relative;
                    cursor: pointer;
                    padding: 20px 0;
                    font-size: 16px;
                    display: inline-block;
                    width: 33%;
                    &.active{
                        font-weight: bold;
                        color: #fff;
                    }
                }
            }
        `;
    }

    render() {
        let cfg = this.props.config;

        const left_position = cfg.options.findIndex(option => option.value === this.props.value);

        return (
            <this.container className="user_input text_input">
                { cfg.label ? <p className="multi_select_label">{ cfg.label }</p> : null }
                <div className="grid_block">
                    <div className="grid_block_bg" style={{left: 33.333333333333 * left_position + '%'}}></div>
                    {
                        cfg.options.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`grid_item ${this.props.value === item.value ? 'active' : ''}`}
                                    onClick={() => {
                                        this.props.onChange(item.value);
                                    }}
                                    >
                                    <span>{item.label}</span>
                                </div>
                            );
                        })
                    }
                </div>
            </this.container>
        );
    }
}

export default TriStateInput;
