import oh from 'output-helpers';

let translations = {
    'sv-SE': {
        'v0.0.4.title': 'v0.0.4',
        'v0.0.4.new': 'Ladda inte om vid scroll.',
        'v0.0.1.title': 'v0.0.1',
        'v0.0.1.new': 'Första versionen för feedback.',
    },
    'en-US': {
        'v0.0.4.title': 'v0.0.4',
        'v0.0.4.new': 'Do not reload on overscroll.',
        'v0.0.1.title': 'v0.0.1',
        'v0.0.1.new': 'First release for customer testing and feedback.',
    },
    prefix: 'changelog_',
};
oh.addDictionary(translations);

let getChangelog = () => [
    {
        title: translate('v0.0.4.title'),
        date: '2019-05-20',
        new: [translate('v0.0.4.new')],
    },
    {
        title: translate('v0.0.1.title'),
        date: '2018-10-16',
        new: [translate('v0.0.1.new')],
    },
];

function translate(key) {
    return oh.translate('changelog_' + key);
}

export default getChangelog;
