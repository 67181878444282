import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withRouter } from 'react-router';
import withStore from 'with-store';
import oh from 'output-helpers';
import userInputs from 'user-inputs';

import StyledNavbar from '../base/StyledNavbar';
import InformationList from '../base/InformationList';
import LocalSpinner from '../base/LocalSpinner';
import TaskCard from '../base/TaskCard';
import Chat from '../base/Chat';
import * as task_helpers from '../../helpers/task_helpers';
import api_helpers from '../../helpers/api_helpers';
import Notifications from '../../notifications';
import * as profile_helpers from '../../helpers/profile_helpers';

const OrderCategory = {
    INFORMATION: 0,
    TASKS: 1,
    MESSAGES: 2,
};

const TasksLayout = {
    TASK_VEHICLE: 0,
    VEHICLE_TASK: 1,
};

const OrderState = {
    PUBLISHED: 'published',
    ASSIGNED: 'assigned',
    COMPLETED: 'completed',
    APPROVED: 'approved',
};

class OrderView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open_filter_entities: false,
        };

        const anim_slide_in = keyframes`
            0% {
                transform: translateX(100%);
            }
            100% {
                transform: 0;
            }
        `;

        this.container = styled.div`
            animation: ${anim_slide_in} 0.5s;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${props => props.theme.colors.background};
            span.link {
                margin: 2px;
            }
            .order_body{
                top: 128px;//${props =>
                    props.theme.sizes.sub_navbar.extended_height + 38}px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: auto;
                &.padding_top{
                    padding-top: 20px;
                }
                &.padding_bottom{
                    padding-bottom: 80px;
                }
            }
            .filter_body{
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 12px;
                background-color: #1663ee;
                z-index: 9;
                .filter_body_select{
                    font-size: 14px;
                    background-color: #fff;
                    border-radius: 4px;
                    border: 1px solid ${props => props.theme.colors.border};
                    padding: 14px 12px;
                    p{
                        display: inline-block;
                        &.right{
                            font-weight: bold;
                            float: right;
                        }
                    }
                }
            }
            .mark_done{
                  margin: auto;
                  width: 50%;
                  padding: 10px;
                  margin-bottom: 10px;
                  border-radius: 20px; 
                  display: block;
            }
            .task_layout_switch{
                background-color: rgba(0,0,0,0.4);
                border-radius: 50px;
                padding: 20px 0;
                position: relative;
                margin: 12px 12px 0;
                text-align: center;
                font-weight: bold;
                .task_layout_switch_bg{
                    transition: all 0.4s;
                    border-radius: 50px;
                    width: 50%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    background-color: ${props => props.theme.colors.blue[1]};
                }
                p{
                    z-index: 2;
                    position: relative;
                    color: #fff;
                    width: 50%;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        `;

        this.stateItem = styled.div`
            color: ${props =>
                props.theme.colors.states[props.state]
                    ? props.theme.colors.states[props.state]
                    : 'gray'};
            font-weight: bold;
            text-align: center;
            margin: 20px 12px;
            border-radius: 50px;
            padding: 12px;
            font-size: 20px;
            background-color: ${props =>
                props.theme.colors.states_dimmed[props.state]
                    ? props.theme.colors.states_dimmed[props.state]
                    : 'gray'};
            border: 1px solid
                ${props =>
                    props.theme.colors.states[props.state]
                        ? props.theme.colors.states[props.state]
                        : 'gray'};
        `;
    }

    componentDidMount() {
        let closePromptAndWarn = (event, cb) => {
            if (this.props.userPrompt.isOpen()) {
                let prompt_tag = this.props.userPrompt.getTag();
                if (prompt_tag !== 'order') {
                    return;
                }

                let selected_order = this.props.se.getSelectedOrder();
                if (selected_order === null) {
                    return;
                }

                if (cb(selected_order) === true) {
                    this.props.userPrompt.cancel();
                    this.props.userPrompt.alert({
                        props: {
                            title: oh.translate('concurrent_modification'),
                            message: oh.translate(
                                'concurrent_modification_desc',
                            ),
                        },
                    });
                }
            }
        };

        let closePromptAndWarnOrder = event => {
            closePromptAndWarn(event, selected_order => {
                if (selected_order.id === event.data.id) {
                    return true;
                }
                return false;
            });
        };
        let closePromptAndWarnTask = event => {
            closePromptAndWarn(event, selected_order => {
                let problem = false;
                if (/^tasks_/.test(event.type)) {
                    problem = event.data.some(
                        task => task.order_id === selected_order.id,
                    );
                } else if (/^task_/.test(event.type)) {
                    problem = event.data.order_id === selected_order.id;
                } else {
                    problem = event.data.id === selected_order.id;
                }
                return problem;
            });
        };

        Notifications.addEventInspector(this, 'order_updated', event => {
            closePromptAndWarnOrder(event);
        });
        Notifications.addEventInspector(this, 'order_deleted', event => {
            closePromptAndWarnOrder(event);
        });
        Notifications.addEventInspector(this, 'task_updated', event => {
            closePromptAndWarnTask(event);
        });
        Notifications.addEventInspector(this, 'tasks_updated', event => {
            closePromptAndWarnTask(event);
        });
        Notifications.addEventInspector(this, 'task_deleted', event => {
            closePromptAndWarnTask(event);
        });
        Notifications.addEventInspector(this, 'tasks_deleted', event => {
            closePromptAndWarnTask(event);
        });
    }

    setMessagesRead() {
        const this_order = this.props.se.getSelectedOrder();
        if (this_order === null) {
            return;
        }
        let messages_last_viewed = this.props.se.getLastViewed(
            this_order.id,
            'order_messages',
        );
        const modified =
            this_order.data.hasOwnProperty('last_message_posted_at') &&
            this_order.data.last_message_posted_at > messages_last_viewed;
        if (modified) {
            profile_helpers.setLastViewed(this_order.id, 'order_messages');
        }
    }

    componentWillUnmount() {
        Notifications.clearEventInspectors(this);
    }

    taskCardClickYes(task_id, entity_id) {
        let inputs = [
            {
                key: 'changes_were_made',
                default_value: 'changes_were_made',
                type: 'button',
                label: oh.translate('have_edit'),
                props: {
                    onClick: () => {
                        this.props.userPrompt.cancel();
                        this.props.se.setSelectedTask(task_id);
                        this.props.se.setSelectedEntity(entity_id);
                    },
                    green: true,
                    filled: true,
                    block: true,
                },
            },
            {
                key: 'changes_were_not_made',
                default_value: 'changes_were_not_made',
                type: 'button',
                label: oh.translate('have_not_edit'),
                props: {
                    onClick: () => {
                        this.props.userPrompt.cancel();
                        this.props.actions.spinner.add();
                        let assignee_id = this.props.store.users.my_id;
                        let task = this.props.store.tasks.by_id[task_id];
                        let altered_task = Object.assign({}, task, {
                            assignee_id: assignee_id,
                            completed: true,
                        });
                        return api_helpers
                            .updateTask(altered_task)
                            .then(updated_task => {
                                this.props.actions.tasks.setTask(updated_task);
                            })
                            .finally(() => {
                                this.props.actions.spinner.remove();
                            });
                    },
                    dark: true,
                    filled: true,
                    block: true,
                },
            },
            {
                key: 'cancel',
                default_value: 'cancel',
                type: 'button',
                label: oh.translate('cancel'),
                props: {
                    onClick: () => {
                        this.props.userPrompt.cancel();
                    },
                    red: true,
                    filled: true,
                    block: true,
                },
            },
        ];
        this.props.userPrompt.confirm({
            props: {
                title: oh.translate('have_you_edited_the_vehicle'),
                show_cancel_btn: false,
                show_confirm_btn: false,
            },
            inputs: inputs,
        });
        this.props.userPrompt.setTag('order');
    }
    taskCardClickNo(task_id, entity_id) {
        let entity = this.props.store.entity.by_id[entity_id];
        let task = this.props.store.tasks.by_id[task_id];
        let order = this.props.store.order.by_id[task.order_id];

        let prepend_str =
            '[' +
            entity.data['meta.vehicle_type'] +
            ' ' +
            entity.data['meta.id'] +
            ']:';
        this.props.userPrompt.confirm(
            {
                props: {
                    title: oh.translate('are_you_sure'),
                    confirm_button_label: oh.translate('yes'),
                    cancel_button_label: oh.translate('no'),
                },
                inputs: [
                    {
                        type: 'textarea',
                        key: 'desc',
                        label: oh.translate('task_uncomplete_desc'),
                        default_value: prepend_str + ' ',
                    },
                ],
            },
            values => {
                let desc = values.desc.trim();
                if (
                    desc.length > 0 &&
                    desc.startsWith(prepend_str) &&
                    desc.length === prepend_str.length
                ) {
                    this.props.userPrompt.cancel();
                    this.props.userPrompt.alert({
                        props: {
                            title: oh.translate('message_required'),
                            message: oh.translate('message_required_long'),
                        },
                    });
                    // no description given. ignore request
                    return;
                }

                withStore.actions.spinner.add();
                let now = new Date();
                Promise.resolve()
                    .then(() => {
                        if (desc.length > 0) {
                            return api_helpers
                                .postMessage({
                                    type: 'text',
                                    ref: 'order://' + task.order_id,
                                    data: {
                                        body: desc,
                                    },
                                })
                                .then(msg => {
                                    profile_helpers.setLastViewed(
                                        task.order_id,
                                        'order_messages',
                                    );
                                    this.props.actions.messages.setMessage(msg);
                                });
                        }
                    })
                    .then(() => {
                        let assignee_id = this.props.store.users.my_id;
                        let altered_task = Object.assign({}, task, {
                            assignee_id: assignee_id,
                            completed: false,
                        });
                        return api_helpers
                            .updateTask(altered_task)
                            .then(updated_task => {
                                withStore.actions.tasks.setTask(updated_task);
                            });
                    })
                    .then(() => {
                        let altered_order = Object.assign({}, order);
                        altered_order.data = Object.assign({}, order.data);
                        altered_order.data.last_message_posted_at = now.toISOString();

                        return api_helpers
                            .updateOrder(altered_order)
                            .then(updated_order => {
                                withStore.actions.order.setOrder(updated_order);
                            });
                    })
                    .finally(() => {
                        withStore.actions.spinner.remove();
                    });
            },
        );
        this.props.userPrompt.setTag('order');
    }
    taskCardClickNote(task_id, entity_id) {
        let task = this.props.store.tasks.by_id[task_id];
        let entity = this.props.store.entity.by_id[entity_id];

        let current_note = '';
        if (task.data.hasOwnProperty('contractor_note')) {
            current_note = task.data.contractor_note;
        }
        this.props.userPrompt.confirm(
            {
                props: {
                    title:
                        oh.translate('note_for') + ' ' + entity.data['meta.id'],
                    confirm_button_label: oh.translate('save'),
                    cancel_button_label: oh.translate('cancel'),
                },
                inputs: [
                    {
                        type: 'textarea',
                        key: 'note',
                        label: oh.translate('note'),
                        default_value: current_note,
                    },
                ],
            },
            values => {
                let altered_task = Object.assign({}, task, {
                    data: Object.assign({}, task.data, {
                        contractor_note: values.note,
                    }),
                });
                if (values.note.length === 0) {
                    delete altered_task.data.contractor_note;
                }
                withStore.actions.spinner.add();
                api_helpers
                    .updateTask(altered_task)
                    .then(updated_task => {
                        withStore.actions.tasks.setTask(updated_task);
                    })
                    .finally(() => {
                        withStore.actions.spinner.remove();
                    });
            },
        );
        this.props.userPrompt.setTag('order');
    }

    userAssignOrder(me, order) {
        withStore.actions.spinner.add();
        Promise.resolve()
            .then(() => {
                let altered_order = Object.assign(
                    {},
                    withStore.getState().order.by_id[order.id],
                );
                altered_order.state = OrderState.ASSIGNED;
                altered_order.associated_user_ids = (
                    altered_order.associated_user_ids || []
                ).filter(user_id => user_id !== me.id);
                altered_order.associated_user_ids.push(me.id);
                altered_order.assignee_org_id = me.installation_org_id || null;

                return api_helpers
                    .updateOrder(altered_order)
                    .then(updated_order => {
                        withStore.actions.order.setOrder(updated_order);
                    });
            })
            .finally(() => {
                withStore.actions.spinner.remove();
            });
    }

    userReleaseOrder(me, order) {
        withStore.actions.spinner.add();
        Promise.resolve()
            .then(() => {
                let altered_order = Object.assign(
                    {},
                    withStore.getState().order.by_id[order.id],
                );
                altered_order.state = OrderState.PUBLISHED;
                altered_order.associated_user_ids = [];
                altered_order.assignee_org_id = null;

                return api_helpers
                    .updateOrder(altered_order)
                    .then(updated_order => {
                        withStore.actions.order.setOrder(updated_order);
                    });
            })
            .finally(() => {
                withStore.actions.spinner.remove();
            });
    }

    userCompleteOrder(me, order, desc) {
        withStore.actions.spinner.add();
        let now = new Date();
        Promise.resolve()
            .then(() => {
                if (desc) {
                    return api_helpers
                        .postMessage({
                            type: 'text',
                            ref: 'order://' + order.id,
                            data: {
                                body: desc,
                            },
                        })
                        .then(msg => {
                            profile_helpers.setLastViewed(
                                order.id,
                                'order_messages',
                            );
                            this.props.actions.messages.setMessage(msg);
                        });
                }
                return null;
            })
            .then(() => {
                let tasks = withStore
                    .getState()
                    .tasks.all.filter(task => task.order_id === order.id)
                    .filter(task => typeof task.completed !== 'boolean')
                    .map(task => {
                        return Object.assign({}, task, {
                            assignee_id: me.id,
                            completed: false,
                        });
                    });

                if (tasks.length > 0) {
                    return api_helpers
                        .updateTasks(tasks)
                        .then(updated_tasks => {
                            this.props.actions.tasks.setTasks(updated_tasks);
                        });
                }
                return null;
            })
            .then(() => {
                let altered_order = Object.assign(
                    {},
                    withStore.getState().order.by_id[order.id],
                );
                altered_order.state = OrderState.COMPLETED;
                altered_order.data = Object.assign({}, altered_order.data);
                if (desc) {
                    altered_order.data.last_message_posted_at = now.toISOString();
                }
                return api_helpers
                    .updateOrder(altered_order)
                    .then(updated_order => {
                        withStore.actions.order.setOrder(updated_order);
                    });
            })
            .finally(() => {
                withStore.actions.spinner.remove();
            });
    }

    userReclaimOrder(me, order) {
        withStore.actions.spinner.add();
        Promise.resolve()
            .then(() => {
                let altered_order = Object.assign(
                    {},
                    withStore.getState().order.by_id[order.id],
                );
                altered_order.state = OrderState.ASSIGNED;
                return api_helpers
                    .updateOrder(altered_order)
                    .then(updated_order => {
                        withStore.actions.order.setOrder(updated_order);
                    });
            })
            .finally(() => {
                withStore.actions.spinner.remove();
            });
    }

    orderOptions(order) {
        let opts = [];
        let me = withStore.extensions.getUser(withStore.getState().users.my_id);

        if (order.state === OrderState.PUBLISHED) {
            opts.push({
                label: oh.translate('order_state_change_assign'),
                onClick: () => {
                    this.userAssignOrder(me, order);
                },
            });
        } else if (order.state === OrderState.ASSIGNED) {
            if (order.assignee_org_id === me.installation_org_id) {
                opts.push({
                    label: oh.translate('order_state_change_release'),
                    onClick: () => {
                        this.userReleaseOrder(me, order);
                    },
                });
                opts.push({
                    label: oh.translate('order_state_change_complete'),
                    onClick: () => {
                        let tasks = this.props.store.tasks.all
                            .filter(task => task.order_id === order.id)
                            .filter(
                                task => typeof task.completed !== 'boolean',
                            );

                        if (tasks.length > 0) {
                            this.props.userPrompt.confirm(
                                {
                                    props: {
                                        title: oh.translate('are_you_sure'),
                                        message: oh.translate(
                                            'describe_why_user_order_complete',
                                        ),
                                        confirm_button_label: oh.translate(
                                            'yes',
                                        ),
                                        cancel_button_label: oh.translate('no'),
                                    },
                                    inputs: [
                                        {
                                            type: 'textarea',
                                            key: 'desc',
                                            default_value: '',
                                        },
                                    ],
                                },
                                values => {
                                    let desc = values.desc.trim();
                                    this.userCompleteOrder(
                                        me,
                                        order,
                                        desc.length > 0 ? desc : null,
                                    );
                                },
                            );
                            this.props.userPrompt.setTag('order');
                        } else {
                            this.userCompleteOrder(me, order);
                        }
                    },
                });
            }
        } else if (order.state === OrderState.COMPLETED) {
            if (order.assignee_org_id === me.installation_org_id) {
                opts.push({
                    label: oh.translate('order_state_change_reclaim'),
                    onClick: () => {
                        this.userReclaimOrder(me, order);
                    },
                });
            }
            if (!order.data.done) {
                opts.push({
                    label: oh.translate('mark_done'),
                    onClick: () => {
                        const order_copy = { ...order };
                        order_copy.data.done = true;
                        api_helpers
                            .updateOrder(order_copy)
                            .then(updated_order => {
                                withStore.actions.order.setOrder(updated_order);
                            });
                    },
                });
            }
        }
        return opts;
    }

    renderEntityLinks(tasks) {
        let entity_links = [];
        if (tasks.length > 0) {
            tasks[0].entities.forEach((entity, entity_index) => {
                entity_links.push(
                    <span
                        key={entity_index}
                        className="link"
                        onClick={() => {
                            this.props.se.setSelectedEntity(entity.ref);
                        }}
                    >
                        {entity.id} - {entity.data['meta.vehicle_number']}
                    </span>,
                );
            });
        }
        return entity_links;
    }
    renderBody(this_order, tasks) {
        if (
            this.props.store.interaction.order_category ===
            OrderCategory.INFORMATION
        ) {
            const information_items = [
                {
                    label: oh.translate('entities'),
                    data: this.renderEntityLinks(tasks),
                    tags: true,
                },
                {
                    label: oh.translate('address'),
                    data: this_order.data.address
                        ? this_order.data.address
                        : '',
                },
                {
                    label: oh.translate('invoice_info'),
                    data: this_order.data.invoice_info,
                    free_text: true,
                },
                {
                    label: oh.translate('created_date'),
                    data: this_order.display_date,
                },
                {
                    label: oh.translate('created_by'),
                    data: this_order.creator.full_name || '',
                },
                {
                    label: oh.translate('associated_orgs'),
                    data: (this_order.getAssociatedOrgs() || [])
                        .map(x => x.name)
                        .join(', '),
                },
                {
                    label: oh.translate('assignee_org'),
                    data: this_order.assignee_org
                        ? this_order.assignee_org.name
                        : '',
                },
                {
                    label: oh.translate('associated_users'),
                    data: (this_order.getAssociatedUsers() || [])
                        .map(x => x.full_name)
                        .join(', '),
                },
            ];

            return (
                <div className="order_body">
                    <this.stateItem state={this_order.state}>
                        {this_order.state
                            ? this_order.state === 'completed' &&
                              this_order.data.done
                                ? oh.translate('singular_done')
                                : oh.translate('singular_' + this_order.state)
                            : ''}
                    </this.stateItem>
                    <InformationList items={information_items} />
                </div>
            );
        }

        if (
            this.props.store.interaction.order_category === OrderCategory.TASKS
        ) {
            return (
                <div className="order_body padding_top padding_bottom">
                    {this.props.store.interaction.order_task_layout ===
                    TasksLayout.TASK_VEHICLE
                        ? tasks.map((item, i) => {
                              return (
                                  <TaskCard
                                      entities={item.entities}
                                      key={i}
                                      task={item}
                                      onClickEntity={(
                                          type,
                                          task_id,
                                          entity_id,
                                      ) => {
                                          if (type === 'yes') {
                                              this.taskCardClickYes(
                                                  task_id,
                                                  entity_id,
                                              );
                                          } else if (type === 'no') {
                                              this.taskCardClickNo(
                                                  task_id,
                                                  entity_id,
                                              );
                                          } else {
                                              this.taskCardClickNote(
                                                  task_id,
                                                  entity_id,
                                              );
                                          }
                                      }}
                                      onClickEntityName={entity_id => {
                                          this.props.se.setSelectedEntity(
                                              entity_id,
                                          );
                                      }}
                                  />
                              );
                          })
                        : null}

                    {this.props.store.interaction.order_task_layout ===
                    TasksLayout.VEHICLE_TASK ? (
                        <div> </div>
                    ) : null}
                </div>
            );
        }

        if (
            this.props.store.interaction.order_category ===
            OrderCategory.MESSAGES
        ) {
            this.setMessagesRead();
            return (
                <div className="order_body no_padding">
                    <Chat
                        ref_id={
                            'order://' + this.props.se.getSelectedOrder().id
                        }
                    />
                </div>
            );
        }
    }

    render() {
        const this_order = this.props.se.getSelectedOrder();
        if (this_order === null) {
            return null;
        }

        let tasks = this.props.se.getTasksFromOrderId(this_order.id);
        if (tasks === null) {
            return (
                <this.container>
                    <LocalSpinner />
                </this.container>
            );
        }

        tasks = task_helpers.groupAndConvertTasksToTaskCardFormat(tasks);
        tasks = Object.keys(tasks).map(key => tasks[key]);

        let message_tab_classes = [];
        let last_viewed = this.props.se.getLastViewed(
            this_order.id,
            'order_messages',
        );
        if (
            this_order.data.hasOwnProperty('last_message_posted_at') &&
            this_order.data.last_message_posted_at > last_viewed
        ) {
            message_tab_classes.push('updated');
        }
        const navbar_categories = [
            {
                name: 'information',
                icon: 'information',
                onClick: () => {
                    this.props.actions.interaction.set({
                        order_category: OrderCategory.INFORMATION,
                    });
                },
            },
            {
                name: 'work',
                icon: 'work',
                onClick: () => {
                    this.props.actions.interaction.set({
                        order_category: OrderCategory.TASKS,
                    });
                },
            },
            {
                name: 'messages',
                icon: 'messages',
                classes: message_tab_classes,
                onClick: () => {
                    this.props.actions.interaction.set({
                        order_category: OrderCategory.MESSAGES,
                    });
                    profile_helpers.setLastViewed(
                        this_order.id,
                        'order_messages',
                    );
                },
            },
        ];
        return (
            <this.container>
                <StyledNavbar
                    bigTitle={this_order.id}
                    orderState={this_order.state}
                    navigation={{
                        onClick: () => {
                            this.props.se.setSelectedOrder(null);
                            this.props.actions.interaction.set({
                                order_category: OrderCategory.INFORMATION,
                            });
                        },
                    }}
                    actionButton={{
                        icon: 'check',
                        options: this.orderOptions(this_order),
                    }}
                    selected_category={
                        this.props.store.interaction.order_category
                    }
                    categories={navbar_categories}
                />
                {this.renderBody(this_order, tasks)}
            </this.container>
        );
    }
}

export default withRouter(withStore(userInputs(OrderView)));
