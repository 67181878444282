import React from 'react';
import styled from 'styled-components';
import { MdCheck, MdClose, MdSpeakerNotes, MdBuild } from 'react-icons/md';
import { FaUserTie } from 'react-icons/fa';
import withStore from 'with-store';
import userInputs from 'user-inputs';

class EntityListItem extends React.Component {
    constructor(props) {
        super(props);

        this.container = styled.li`
            background-color: #fff;
            display: ${props => (props.small ? 'inline-block' : 'block')};
            margin: ${props => (props.small ? '0 8px 8px 0' : '0')};
            border-radius: ${props => (props.small ? '4px' : 'unset')};
            width: ${props => (props.small ? '160px' : 'initial')};
            .entity_info_block {
                width: calc(100% - 192px);
                padding: ${props =>
                    props.small ? '8px 16px' : '14px 0px 14px 14px'};
            }
            .entity_options_block {
                cursor: pointer;
                padding: 0 12px;
                width: 192px;
                text-align: right;
                padding: 6px;
                &:hover {
                    opacity: 1;
                }
                .option {
                    text-align: center;
                    border: 1px solid ${props => props.theme.colors.border};
                    border-radius: 4px;
                    width: 52px;
                    height: 52px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 8px;
                    &:last-child {
                        margin-right: 0px;
                    }
                    &.completed_true {
                        &.active {
                            background-color: ${props =>
                                props.theme.colors.green[0]} !important;
                            color: #fff;
                        }
                    }
                    &.completed_false {
                        &.active {
                            background-color: ${props =>
                                props.theme.colors.red[0]} !important;
                            color: #fff;
                        }
                    }
                    svg {
                        margin-top: 8px;
                        width: 34px;
                        height: 34px;
                    }
                }
            }

            .entity_info_block,
            .entity_options_block {
                display: inline-block;
                vertical-align: middle;
            }

            .entity_info_block {
                position: relative;
            }

            .entity_name {
                font-weight: bold;
                font-size: 16px;
            }
            .entity_subtitle {
                font-size: 14px;
                line-height: 16px;
                opacity: 0.7;
            }
            .note_container {
                padding: 0 12px 8px;
                svg,
                p {
                    display: inline;
                    vertical-align: middle;
                }
                svg {
                    fill: #9aabc2;
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                }
            }
        `;
    }

    renderOptions() {
        return (
            <div className="entity_options_block">
                <div
                    className={`option`}
                    onClick={() => {
                        if (this.props.onClickNote) {
                            this.props.onClickNote();
                        }
                    }}
                >
                    <MdSpeakerNotes />
                </div>
                <div
                    className={`option completed_true ${
                        this.props.entity.completed === true ? 'active' : ''
                    }`}
                    onClick={() => {
                        if (this.props.onClickItemYes) {
                            this.props.onClickItemYes();
                        }
                    }}
                >
                    <MdCheck />
                </div>
                <div
                    className={`option completed_false ${
                        this.props.entity.completed === false ? 'active' : ''
                    }`}
                    onClick={() => {
                        if (this.props.onClickItemNo) {
                            this.props.onClickItemNo();
                        }
                    }}
                >
                    <MdClose />
                </div>
            </div>
        );
    }

    render() {
        return (
            <this.container
                small={this.props.small}
                className="entity_list_item"
            >
                <div
                    className="entity_info_block"
                    onClick={() => {
                        if (this.props.onClickItem) {
                            this.props.onClickItem();
                        }
                    }}
                >
                    <p className="entity_name">
                        {this.props.entity.id +
                            ' - ' +
                            this.props.entity.data['meta.vehicle_number']}
                    </p>
                </div>
                {this.renderOptions()}
                {this.props.entity.contractor_note ? (
                    <div className="note_container">
                        <MdBuild />
                        <p className="entity_subtitle">
                            {this.props.entity.contractor_note}
                        </p>
                    </div>
                ) : null}
                {this.props.entity.admin_note ? (
                    <div className="note_container">
                        <FaUserTie />
                        <p className="entity_subtitle">
                            {this.props.entity.admin_note}
                        </p>
                    </div>
                ) : null}
            </this.container>
        );
    }
}

export default userInputs(withStore(EntityListItem));
