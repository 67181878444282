import React from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '../base/Button';
import oh from 'output-helpers';

const GalleryDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin: 10px 10px 0;
`;

const shake_anim = keyframes`
    0% { transform: rotate(-3deg); }
    50% { transform: rotate(3deg); } 
    100% { transform: rotate(-3deg); }
`;

const ImgDiv = styled.div`
    background: url(${props => props.ImgUrl}) no-repeat center;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    width: 100%;
    height: 230px;
    border: 1px solid #d2d2d2;
    &.delete_me {
        animation: ${shake_anim} 0.5s linear infinite;
    }
    &:hover {
        opacity: 0.7;
    }
`;

const lightbox_anim = keyframes`
    0% {
        transform: rotateX(1deg) translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: rotateX(0) translateY(0);
    }
`;

const LightBoxContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 25px;
    & .lightbox {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        animation: ${lightbox_anim} 0.2s ease;
    }
    & .lightbox .img {
        flex: 1 1 auto;
        background: url(${props => props.ImgUrl}) no-repeat center;
        background-size: contain;
    }
`;

const ButtonContainer = styled.div`
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    flex-direction: column;
    flex: 0 1 75px;
`;

class Gallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lightbox_image_uri: null,
        };
    }

    closeLightBox() {
        this.setState({
            lightbox_image_uri: null,
        });
    }

    renderImage(image, index, delete_mode) {
        const base64image = btoa(
            new Uint8Array(image.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
            ),
        );
        const imageUrl = `data:${image.type};base64,${base64image}`;

        const openImageFn = () => {
            this.setState({
                lightbox_image_uri: imageUrl,
            });
        };

        return (
            <ImgDiv
                className={`${delete_mode ? 'delete_me' : ''}`}
                ImgUrl={imageUrl}
                key={`photo_${index}`}
                onClick={() => {
                    delete_mode ? this.props.onDelete(image.id) : openImageFn();
                }}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <GalleryDiv>
                    {this.props.images.map((image, index) =>
                        this.renderImage(image, index, this.props.delete_mode),
                    )}
                </GalleryDiv>
                {this.state.lightbox_image_uri && (
                    <LightBoxContainer
                        onClick={this.closeLightBox.bind(this)}
                        ImgUrl={this.state.lightbox_image_uri}
                    >
                        <div className={'lightbox'}>
                            <div className={'img'}></div>
                            <ButtonContainer>
                                <Button
                                    filled
                                    block
                                    green
                                    rounded={true}
                                    label={oh.translate('back')}
                                    onClick={this.closeLightBox.bind(this)}
                                />
                            </ButtonContainer>
                        </div>
                    </LightBoxContainer>
                )}
            </React.Fragment>
        );
    }
}
export default Gallery;
