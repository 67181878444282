export default {
    api: {
        sso: {
            api_uri: 'https://sso.test.allbin.se/v2',
            login_uri: 'https://login.test.allbin.se/v2',
        },
        entities: {
            api_uri:
                process.env.REACT_APP_ENTITIES_API_URI ||
                'https://entity-api.test.allbin.se',
        },
        tasks: {
            api_uri:
                process.env.REACT_APP_TASKS_API_URI ||
                'https://task-api.test.allbin.se',
        },
        profile: {
            api_uri:
                process.env.REACT_APP_PROFILE_API_URI ||
                'https://profiles.test.allbin.se/profile',
        },
        images: {
            api_uri:
                'https://europe-west1-allbinary-204807.cloudfunctions.net/image_api_test',
        },
    },
    slack: {
        webhook_uri:
            process.env.REACT_APP_SLACK_WEBHOOK_URI ||
            'https://hooks.slack.com/services/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',
    },
    google: {
        maps: {
            api_key:
                process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
                'AIzaSyA0tp0r6ImLSnn9vy4zXjZWar1F3U5eOaY',
        },
    },
    notifications: process.env.REACT_APP_NOTIFICATION_CONFIG
        ? JSON.parse(process.env.REACT_APP_NOTIFICATION_CONFIG)
        : {
              urls: [
                  'https://task-api.test.allbin.se/notifications',
                  'https://entity-api.test.allbin.se/notifications',
              ],
          },
    root_mobile_uri:
        process.env.REACT_APP_ROOT_URI || 'https://m.orders.test.allbin.se',
    service_name: process.env.REACT_APP_SERVICE_NAME || 'orders_test',
    production: process.env.NODE_ENV === 'production',

    open_states: ['published', 'assigned', 'completed'],
    managed_entities: ['vehicles'],

    send_errors_to_slack_in_dev: false,
    send_posts_to_slack: false,

    default_map_position: { lat: 57.7875109, lng: 14.2002984 },
    default_map_zoom: 13,

    default_language: 'sv-SE',
    star_color: ['#32CD32', '#FF7F50', '#B22222'],
    vapid_public_key:
        'BMRtITF8nQvJa-9AKNwvuLrkOymQWVfaVTAJu355rBYAUbVezNWpjNq7NI-qfAzAgGNKeTquS9g-irc6wXst9g0',
};
