import * as store_helpers from './store_helpers';

export const TAGS_STATE_ASSIGN = 'TAGS_STATE_ASSIGN';
export const TAGS_STATE_ADD_TAG = 'TAGS_STATE_ADD_TAG';
export const TAGS_STATE_ADD_TAGS = 'TAGS_STATE_ADD_TAGS';

let default_state = {
    fetched: false,
    all: [],
    by_id: {},
};

export default function tagReducer(state = default_state, action) {
    switch (action.type) {
        case TAGS_STATE_ASSIGN: {
            return Object.assign({}, state, action.payload);
        }
        case TAGS_STATE_ADD_TAG: {
            return store_helpers.SetItem(action.payload, state);
        }
        case TAGS_STATE_ADD_TAGS: {
            let updated_state = store_helpers.SetItems(
                action.payload.tags,
                state,
            );
            return updated_state;
        }
        default: {
            return state;
        }
    }
}
