import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';

export default class Button extends React.Component {
    constructor(props) {
        super(props);

        const anim_bounce_icon = keyframes`
            0% {
                margin-right: 0;
            }
            50% {
                margin-right: 12px;
            }
            100% {
                margin-right: 0px;
            }
        `;

        this.Container = styled.button`
            font-size: 16px;
            user-select: none;
            border: ${props =>
                props.filled
                    ? 'none'
                    : '1px solid ' + props.theme.colors.brand[0]};
            display: ${props => (props.block ? 'block' : 'inline-block')};
            width: ${props => (props.block ? '100%' : 'unset')};
            cursor: pointer;
            border-radius: ${props => (!props.rounded ? '6px' : '0px')};
            background-color: ${props =>
                props.filled ? props.theme.colors.brand[0] : 'transparent'};
            color: #fff;
            text-align: center;
            padding: ${props =>
                props.icon ? '12px 60px 12px 20px' : '18px 20px'};
            font-weight: bold;
            transition: all 0.3s;
            position: relative;
            &:focus {
                outline: none;
            }
            &.rounded {
                border-radius: 6px;
            }
            &.big {
                padding: 20px 44px;
            }
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
            &.default {
                background-color: #fff;
                border: 1px solid #000;
                color: #000;
                &:hover {
                    color: #fff;
                    background-color: #000;
                }
            }
            &.light {
                background-color: ${props =>
                    props.filled ? props.theme.colors.brand[1] : 'transparent'};
                border: ${props =>
                    props.filled
                        ? 'none'
                        : '1px solid ' + props.theme.colors.brand[1]};
            }
            &.dark {
                background-color: ${props =>
                    props.filled ? props.theme.colors.dark[0] : 'transparent'};
                border: ${props =>
                    props.filled
                        ? 'none'
                        : '1px solid ' + props.theme.colors.dark[0]};
                &:hover {
                    background-color: ${props =>
                        props.filled
                            ? props.theme.colors.dark[2]
                            : props.theme.colors.dark[2]};
                    border: ${props =>
                        props.filled
                            ? 'none'
                            : '1px solid ' + props.theme.colors.dark[2]};
                }
            }
            &.red {
                background-color: ${props =>
                    props.filled ? props.theme.colors.red[1] : 'transparent'};
                border: ${props =>
                    props.filled
                        ? 'none'
                        : '1px solid ' + props.theme.colors.red[1]};
                &:hover {
                    background-color: ${props =>
                        props.filled
                            ? props.theme.colors.red[3]
                            : props.theme.colors.red[0]};
                    border: ${props =>
                        props.filled
                            ? 'none'
                            : '1px solid ' + props.theme.colors.red[0]};
                }
            }
            &.green {
                background-color: ${props =>
                    props.filled ? props.theme.colors.green[1] : 'transparent'};
                border: ${props =>
                    props.filled
                        ? 'none'
                        : '1px solid ' + props.theme.colors.green[1]};
                &:hover {
                    background-color: ${props =>
                        props.filled
                            ? props.theme.colors.green[2]
                            : props.theme.colors.green[2]};
                    border: ${props =>
                        props.filled
                            ? 'none'
                            : '1px solid ' + props.theme.colors.green[2]};
                }
            }
            &.teal {
                background-color: ${props =>
                    props.filled ? props.theme.colors.teal[1] : 'transparent'};
                border: ${props =>
                    props.filled
                        ? 'none'
                        : '1px solid ' + props.theme.colors.teal[1]};
                &:hover {
                    background-color: ${props =>
                        props.filled
                            ? props.theme.colors.teal[2]
                            : props.theme.colors.teal[2]};
                    border: ${props =>
                        props.filled
                            ? 'none'
                            : '1px solid ' + props.theme.colors.teal[2]};
                }
            }
            &:hover {
                background-color: ${props =>
                    props.filled
                        ? props.theme.colors.brand[2]
                        : props.theme.colors.brand[0]};
                border: ${props =>
                    props.filled
                        ? 'none'
                        : '1px solid ' + props.theme.colors.brand[0]};
                box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
            }
            &:focus {
                .button_icon {
                    animation: ${anim_bounce_icon} 0.6s;
                }
            }
            .button_icon {
                position: absolute;
                top: 5px;
                right: 5px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.2);
                text-align: center;
                svg {
                    height: 30px;
                    vertical-align: middle;
                }
            }
        `;
    }

    render() {
        let classes = [];
        if (this.props.dark) {
            classes.push('dark');
        }
        if (this.props.light) {
            classes.push('light');
        }
        if (this.props.red) {
            classes.push('red');
        }
        if (this.props.green) {
            classes.push('green');
        }
        if (this.props.teal) {
            classes.push('teal');
        }
        if (this.props.disabled) {
            classes.push('disabled');
        }
        if (this.props.big) {
            classes.push('big');
        }
        if (this.props.default) {
            classes.push('default');
        }
        if (this.props.rounded) {
            classes.push('rounded');
        }

        return (
            <this.Container
                onMouseEnter={e =>
                    this.props.onMouseEnter ? this.props.onMouseEnter(e) : null
                }
                onMouseLeave={e =>
                    this.props.onMouseEnter ? this.props.onMouseLeave(e) : null
                }
                block={this.props.block}
                icon={this.props.icon}
                filled={this.props.filled}
                rounded={this.props.rounded}
                className={`button ${classes.join(' ')} ${
                    this.props.className
                }`}
                onClick={e =>
                    this.props.onClick ? this.props.onClick(e) : null
                }
            >
                {this.props.label}
                {this.props.icon ? (
                    <div className="button_icon">
                        <FaArrowRight />
                    </div>
                ) : null}
            </this.Container>
        );
    }
}
