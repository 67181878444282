import * as types from './audit_reducer';

/*
    An audit log:
    {
        id: <ref_id>,
        log: [
            {...},
            ...
        ]
    }
*/
export function setAudit(state, audit_log, type = null) {
    audit_log.id = type ? type + '://' + audit_log.id : audit_log.id;
    return {
        type: types.AUDIT_STATE_SET_AUDIT,
        payload: audit_log,
    };
}

export function setAudits(state, audit_log_arr, type = null) {
    audit_log_arr.map(
        audit_log =>
            (audit_log.id = type ? type + '://' + audit_log.id : audit_log.id),
    );
    return {
        type: types.AUDIT_STATE_SET_AUDITS,
        payload: audit_log_arr,
    };
}

export function clearAudit(state, ref_id, type = null) {
    ref_id = type ? type + '://' + ref_id : ref_id;

    let by_id_copy = state.by_id;
    if (state.by_id.hasOwnProperty(ref_id)) {
        //Only make a new object if necessary.
        by_id_copy = Object.assign({}, state.by_id);
        delete by_id_copy[ref_id];
    }
    let all_copy = state.all;
    let index = state.all.findIndex(audit_log => audit_log.id === ref_id);
    if (index > -1) {
        all_copy = [].concat(state.all);
        all_copy.splice(index, 1);
    }

    return {
        type: types.AUDIT_STATE_ASSIGN,
        payload: {
            by_id: by_id_copy,
            all: all_copy,
        },
    };
}
