import React from 'react';
import styled, {keyframes} from 'styled-components';

class LocalSpinner extends React.Component {

    constructor(props) {
        super(props);

        const anim_lds_spinner = keyframes`
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
        `;

        this.container = styled.div `
            text-align: center;
            opacity: 0.9;
            margin: 100px auto;
            width: 200px;
            height: 200px;
            .lds-spinner {
              display: inline-block;
              position: relative;
              width: 120px;
              height: 120px;
            }
            .lds-spinner div {
              transform-origin: 64px 64px;
              animation: ${anim_lds_spinner} 1.2s linear infinite;
            }
            .lds-spinner div:after {
                content: " ";
                display: block;
                position: absolute;
                top: 0px;
                left: 50px;
                width: 12px;
                height: 26px;
                border-radius: 20%;
                background: #8B9FD0;
            }
            .lds-spinner div:nth-child(1) {
              transform: rotate(0deg);
              animation-delay: -1.1s;
            }
            .lds-spinner div:nth-child(2) {
              transform: rotate(30deg);
              animation-delay: -1s;
            }
            .lds-spinner div:nth-child(3) {
              transform: rotate(60deg);
              animation-delay: -0.9s;
            }
            .lds-spinner div:nth-child(4) {
              transform: rotate(90deg);
              animation-delay: -0.8s;
            }
            .lds-spinner div:nth-child(5) {
              transform: rotate(120deg);
              animation-delay: -0.7s;
            }
            .lds-spinner div:nth-child(6) {
              transform: rotate(150deg);
              animation-delay: -0.6s;
            }
            .lds-spinner div:nth-child(7) {
              transform: rotate(180deg);
              animation-delay: -0.5s;
            }
            .lds-spinner div:nth-child(8) {
              transform: rotate(210deg);
              animation-delay: -0.4s;
            }
            .lds-spinner div:nth-child(9) {
              transform: rotate(240deg);
              animation-delay: -0.3s;
            }
            .lds-spinner div:nth-child(10) {
              transform: rotate(270deg);
              animation-delay: -0.2s;
            }
            .lds-spinner div:nth-child(11) {
              transform: rotate(300deg);
              animation-delay: -0.1s;
            }
            .lds-spinner div:nth-child(12) {
              transform: rotate(330deg);
              animation-delay: 0s;
            }
        `;
    }

    render() {
        return (
            <this.container>
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </this.container>
        );
    }
}

export default LocalSpinner;
