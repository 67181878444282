import withStore from 'with-store';
import oh from 'output-helpers';

import api_helpers from '../helpers/api_helpers';
import * as order_helpers from '../helpers/order_helpers';
import * as entity_helpers from '../helpers/entity_helpers';
import * as task_helpers from '../helpers/task_helpers';
import * as message_helpers from '../helpers/message_helpers';
import * as profile_helpers from '../helpers/profile_helpers';

let fetching_entity_tasks = {};
let fetching_order_tasks = {};
let fetching_messages = {};

export default {
    getOrders: {
        states: ['order', 'users', 'orgs'],
        mnemonic: 'always',
        fn: states => {
            return states.order.all.map(order => order_helpers.resolve(order));
        },
        help: `getOrders() - Returns an array of all orders, resolved.`,
    },
    getOrdersFromIds: {
        states: ['order', 'users', 'orgs'],
        mnemonic: 'argument_check',
        fn: (states, wanted_ids) => {
            return states.order.all
                .filter(order =>
                    wanted_ids.find(wanted_id => wanted_id === order.id),
                )
                .map(order => order_helpers.resolve(order));
        },
    },
    getOrdersFromCreatorUserId: {
        states: ['order'],
        mnemonic: 'argument_check',
        fn: (states, user_id) => {
            let filtered_orders = states.order.all.filter(
                order => order.creator === user_id,
            );
            return filtered_orders.map(order => order_helpers.resolve(order));
        },
    },
    getOrdersFromAssignedOrgId: {
        states: ['order'],
        mnemonic: 'argument_check',
        fn: (states, org_id) => {
            let filtered_orders = states.order.all.filter(
                order => order.assignee_org_id === org_id,
            );
            return filtered_orders.map(order => order_helpers.resolve(order));
        },
    },
    getOrdersFromAssociatedUserIds: {
        states: ['order'],
        mnemonic: 'argument_check',
        fn: (states, user_ids) => {
            let filtered_orders = states.order.all.filter(order => {
                return order.associated_user_ids.some(associated_id =>
                    user_ids.includes(associated_id),
                );
            });
            return filtered_orders.map(order => order_helpers.resolve(order));
        },
    },
    getOrdersFromAssociatedOrgIds: {
        states: ['order'],
        mnemonic: 'argument_check',
        fn: (states, org_ids) => {
            let filtered_orders = states.order.all.filter(order => {
                return order.associated_org_ids.some(associated_id =>
                    org_ids.includes(associated_id),
                );
            });
            return filtered_orders.map(order => order_helpers.resolve(order));
        },
    },
    getTasks: {
        states: ['tasks', 'users', 'orgs'],
        mnemonic: 'always',
        fn: states => {
            return states.tasks.map(task => task_helpers.resolve(task));
        },
    },
    getTask: {
        states: ['tasks', 'users', 'orgs'],
        mnemonic: 'argument_check',
        fn: (states, task_id) => {
            return task_helpers.resolve(states.tasks.by_id[task_id]);
        },
    },
    fetchTasksFromOrderId: {
        states: ['tasks'],
        mnemonic: 'never',
        fn: (states, order_id) => {
            return api_helpers.fetchTasks(order_id).then(tasks => {
                withStore.actions.tasks.setTasks(tasks);
                withStore.actions.tasks.setCompleteOrderFetched(order_id);
            });
        },
    },
    getTasksFromOrderId: {
        states: ['tasks', 'users', 'orgs'],
        mnemonic: 'never',
        fn: (states, order_id) => {
            if (states.tasks.complete_order_fetched.hasOwnProperty(order_id)) {
                delete fetching_order_tasks[order_id];
                return states.tasks.all
                    .filter(task => task.order_id === order_id)
                    .map(task => task_helpers.resolve(task));
            }

            if (fetching_order_tasks.hasOwnProperty(order_id)) {
                return null;
            }

            fetching_order_tasks[order_id] = true;

            api_helpers.fetchTasks(order_id).then(tasks => {
                withStore.actions.tasks.setTasks(tasks);
                withStore.actions.tasks.setCompleteOrderFetched(order_id);
            });

            return null;
        },
    },
    getTasksFromEntityRef: {
        states: ['tasks'],
        mnemonic: 'never',
        fn: (states, entity_ref) => {
            if (
                states.tasks.complete_entity_fetched.hasOwnProperty(entity_ref)
            ) {
                delete fetching_entity_tasks[entity_ref];
                return (states.tasks.by_entity_ref[entity_ref] || []).map(
                    task => task_helpers.resolve(task),
                );
            }

            if (fetching_entity_tasks.hasOwnProperty(entity_ref)) {
                return null;
            }

            fetching_entity_tasks[entity_ref] = true;

            api_helpers.fetchTasks(null, entity_ref).then(tasks => {
                withStore.actions.tasks.setTasks(tasks);
                withStore.actions.tasks.setCompleteEntityFetched(entity_ref);
            });

            return null;
        },
    },
    getUser: {
        states: ['users'],
        mnemonic: 'argument_check',
        fn: (states, user_id) => {
            let users = states.users;
            if (users.by_id.hasOwnProperty(user_id) === false) {
                throw new Error('Tried to access user who is not in state.');
            }
            let user = Object.assign({}, users.by_id[user_id]);
            if (user.installation_org_id === -1) {
                user.installation_org =
                    users.orgs_by_id[user.installation_org_id];
            } else {
                user.installation_org = oh.translate('no_org');
            }
            return user;
        },
    },
    getUsers: {
        states: ['users'],
        mnemonic: 'always',
        fn: states => {
            return states.users.all.map(user => {
                return Object.assign({}, user, {
                    orgs: user.orgs.map(
                        org_id => states.users.orgs_by_id[org_id],
                    ),
                });
            });
        },
        help: `getUsers() - Returns an array of all users with their orgs shallowly resolved.`,
    },
    getTag: {
        states: ['tags'],
        mnemonic: 'argument_check',
        fn: (states, tag_id) => {
            let tags = states.tags;
            if (tags.by_id.hasOwnProperty(tag_id) === false) {
                throw new Error('Tried to access tag which is not in state.');
            }
            let tag = Object.assign({}, tags.by_id[tag_id]);
            return tag;
        },
    },
    getOrg: {
        states: ['users'],
        mnemonic: 'argument_check',
        fn: (states, org_id) => {
            let users = states.users;
            if (users.orgs_by_id.hasOwnProperty(org_id) === false) {
                throw new Error('Tried to access org which is not in state.');
            }
            let org = Object.assign({}, users.orgs_by_id[org_id]);
            org.users = org.users.map(user_id => users.by_id[user_id]);
            return org;
        },
    },
    getOrgs: {
        states: ['users'],
        mnemonic: 'always',
        fn: states => {
            return states.users.all_orgs.map(org => {
                return Object.assign({}, org, {
                    users: org.users.map(
                        user_id => states.users.by_id[user_id],
                    ),
                });
            });
        },
        help: `getOrgs() returns an array of all orgs and the users shallowly resolved.`,
    },
    showSpinner: {
        states: ['spinner'],
        mnemonic: 'never',
        fn: states => {
            return states.spinner.count > 0;
        },
    },
    showSmallSpinner: {
        states: ['spinner'],
        mnemonic: 'never',
        fn: states => {
            return states.spinner.small_count > 0;
        },
    },
    getMessagesFromRef: {
        states: ['messages', 'users'],
        mnemonic: 'never',
        fn: (states, ref) => {
            if (states.messages.complete_messages_fetched.hasOwnProperty(ref)) {
                delete fetching_messages[ref];
                if (states.messages.by_ref.hasOwnProperty(ref)) {
                    return states.messages.by_ref[ref].map(msg =>
                        message_helpers.resolve(msg),
                    );
                }
                //We have got messages response from server, but there were no messages.
                return [];
            }

            if (fetching_messages.hasOwnProperty(ref)) {
                return null;
            }

            fetching_messages[ref] = true;

            api_helpers.fetchMessagesByRef(ref).then(messages => {
                withStore.actions.messages.setMessages(messages);
                withStore.actions.messages.setCompleteMessagesFetched(ref);
            });

            return null;
        },
    },
    getAudit: {
        states: ['audits'],
        mnemonic: 'argument_check',
        fn: (states, id, type = null) => {
            id = type ? type + '://' + id : id;
            return states.by_id[id];
        },
        help: `getAudit(id, type = null) -
        id <any> - Reference id for the order, task or entity.
        type <optional, string> - Optionally a type can be supplied, in which case the ID should only be the ID portion of the reference.
        EX: getAudit(32, "order"); Returns audit log for order 32 ("order://32")`,
    },

    getVehicleFromId: {
        states: ['entity'],
        mnemonic: 'argument_check',
        fn: (states, id) => {
            return entity_helpers.resolve(states.entity.by_id[id]);
        },
    },

    getVehicles: {
        states: ['entity'],
        mnemonic: 'argument_check',
        fn: states => {
            return states.entity.all
                .filter(x => x.collection === 'vehicles')
                .map(entity_helpers.resolve);
        },
    },

    getSelectedEntity: {
        states: ['interaction', 'entity'],
        mnemonic: 'always',
        fn: states => {
            if (
                states.interaction.hasOwnProperty('selected_entity_id') &&
                typeof states.interaction.selected_entity_id === 'string'
            ) {
                let sel_entity_id = states.interaction.selected_entity_id;
                if (states.entity.by_id.hasOwnProperty(sel_entity_id)) {
                    return entity_helpers.resolve(
                        states.entity.by_id[sel_entity_id],
                    );
                }
                //There is a selection but the entity does not exist in state!
                return null;
            }
            return null;
        },
        help: `getSelectedEntity() -
        RETURNS the selected entity if that entity exists. If no selection has been made or entity does not exist returns null.`,
    },
    setSelectedEntity: {
        states: ['interaction'],
        mnemonic: 'never',
        fn: (states, id) => {
            withStore.actions.interaction.set({ selected_entity_id: id });
            if (id) {
                profile_helpers.setLastViewed(id, 'entity');
            }
        },
        help: `setSelectedEntity(id) - Set the currently selected entity id to the supplied id. Can also be null to unset the current selection.`,
    },

    getSelectedTask: {
        states: ['interaction', 'tasks'],
        mnemonic: 'always',
        fn: states => {
            if (
                states.interaction.hasOwnProperty('selected_task_id') &&
                typeof states.interaction.selected_task_id === 'number'
            ) {
                let sel_task_id = states.interaction.selected_task_id;
                if (states.tasks.by_id.hasOwnProperty(sel_task_id)) {
                    return task_helpers.resolve(
                        states.tasks.by_id[sel_task_id],
                    );
                }
                return null;
            }
            return null;
        },
        help: `getSelectedTask() -
        RETURNS the selected task, if that task exists. If no selection has been made or task does not exist, null is returned.`,
    },
    setSelectedTask: {
        states: ['interaction'],
        mnemonic: 'never',
        fn: (states, id) => {
            withStore.actions.interaction.set({ selected_task_id: id });
            if (id) {
                profile_helpers.setLastViewed(id, 'task');
            }
        },
        help: `setSelectedTask(id) - Set the currently selected task id. Can also be null to unset the current selection`,
    },

    getSelectedOrder: {
        states: ['interaction', 'order'],
        mnemonic: 'always',
        fn: states => {
            if (
                states.interaction.hasOwnProperty('selected_order_id') &&
                typeof states.interaction.selected_order_id === 'number'
            ) {
                let sel_order_id = states.interaction.selected_order_id;
                if (states.order.by_id.hasOwnProperty(sel_order_id)) {
                    return order_helpers.resolve(
                        states.order.by_id[sel_order_id],
                    );
                }
                //There is a selection but the entity does not exist in state!
                return null;
            }
            return null;
        },
        help: `getSelectedOrder() -
        RETURNS the selected order if that order exists. If no selection has been made or order does not exist returns null.`,
    },
    setSelectedOrder: {
        states: ['interaction'],
        mnemonic: 'never',
        fn: (states, id) => {
            withStore.actions.interaction.set({ selected_order_id: id });
            if (id) {
                profile_helpers.setLastViewed(id, 'order');
            }
        },
        help: `setSelectedOrder(id) - Set the currently selected order id to the supplied id. Can also be null to unset the current selection.`,
    },
    clearLocalFetchingFlags: {
        states: ['interaction'],
        mnemonic: 'never',
        fn: states => {
            fetching_order_tasks = {};
            fetching_entity_tasks = {};
            fetching_messages = {};
        },
        help: `clearLocalFetchingFlags() - Clear fetching states in case of reconnect/blur`,
    },
};
