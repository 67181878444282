import * as types from './users_reducer';

export function setUsersState(state, users_by_id, orgs_by_id) {
    let all = Object.keys(users_by_id).map(id => users_by_id[id]);
    let all_orgs = Object.keys(orgs_by_id).map(id => orgs_by_id[id]);
    return {
        type: types.USERS_STATE_SET,
        payload: Object.assign({}, state, {
            by_id: users_by_id,
            all: all,
            orgs_by_id: orgs_by_id,
            all_orgs: all_orgs,
        }),
    };
}

export function setMyId(state, my_id) {
    return {
        type: types.USERS_STATE_SET_MY_ID,
        payload: my_id,
    };
}

export function setFetched(state, fetched) {
    return {
        type: types.USERS_STATE_SET,
        payload: Object.assign({}, state, {
            fetched: fetched,
        }),
    };
}
