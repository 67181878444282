import withStore from 'with-store';
import oh from 'output-helpers';

import api_helpers from '../helpers/api_helpers';

export function groupAndConvertTasksToTaskCardFormat(tasks) {
    return tasks.reduce((a, c) => {
        if (!a.hasOwnProperty(c.data.group_id)) {
            a[c.data.group_id] = {
                order_id: c.order_id,
                type: c.type,
                data: {
                    description: c.data.description,
                },
                tags: c.tags,
                entities: [],
            };
        }
        let vehicle = withStore.extensions.getVehicleFromId(c.entity);
        a[c.data.group_id].entities.push({
            id: vehicle.data['meta.id'],
            task_id: c.id,
            completed: c.completed,
            admin_note: c.data.admin_note,
            contractor_note: c.data.contractor_note,
            ref: vehicle.id,
            data: vehicle.data,
        });

        return a;
    }, {});
}

export function addTasks(task_arr) {
    if (!Array.isArray(task_arr)) {
        throw new Error('Invalid property; addTasks takes an array of tasks.');
    }
    if (task_arr.some(task => !task.hasOwnProperty('order_id'))) {
        throw new Error("Missing property 'order_id' on task to add.");
    }
    return api_helpers.addTasks(task_arr);
}

export function fetchTaskAudit(task_id) {
    api_helpers.fetchTaskAudit(task_id).then(audit_log => {
        withStore.actions.audit.setAudit(audit_log, 'task');
    });
}
export function resolve(task) {
    let resolved = Object.assign({}, task);
    if (resolved.assignee_id) {
        resolved.assignee = withStore.extensions.getUser(resolved.assignee_id);
    }
    resolved.date_created = oh.dateToMoment(resolved.date_created);
    resolved.creator = withStore.extensions.getUser(resolved.creator_id);
    resolved.tags = (resolved.associated_tag_ids || []).map(tag_id =>
        withStore.extensions.getTag(tag_id),
    );

    resolved.fetchAudit = () => {
        fetchTaskAudit(resolved.id);
    };

    return resolved;
}

export function pack(task) {
    let task_for_server = {
        id: task.id,
        last_modified: task.last_modified,
        completed: task.completed,
        data: task.data,
    };

    if (task.hasOwnProperty('assignee_id')) {
        task_for_server.assignee_id = task.assignee_id;
    }

    if (task.location) {
        task_for_server.location = task.location;
    }

    if (task.associated_tag_ids) {
        task_for_server.associated_tag_ids = task.associated_tag_ids;
    }

    return task_for_server;
}

export function unpack(task_from_server) {
    return task_from_server;
}
