import React from 'react';
import styled from 'styled-components';

class InformationList extends React.Component {

    constructor(props) {
        super(props);

        this.container = styled.div `
        background-color: #fff;
        .card_body{
            padding: 12px 16px;
            .task_description{
                font-size: 14px;
            }
        }
        .card_list{
            ul{
                li{
                    padding: 12px 12px;
                    border-bottom: 1px solid ${props => props.theme.colors.border};
                    &:last-child{
                        border-radius: 0 0 4px 4px;
                        border-bottom: 0;
                    }
                    &.disabled{
                        opacity: 0.5;
                    }
                    .card_list_label{
                        font-size: ${props => props.bigLabel ? '18px' : '14px'};
                        margin-bottom: 8px;
                        opacity: ${props => props.bigLabel ? '1' : '0.7'};
                    }
                    .card_list_data{
                        font-weight: bold;
                        font-size: 16px;
                        &.free_text{
                            font-weight: normal;
                            white-space: pre;
                            background-color: #eeeeee;
                            padding: 4px 8px;
                            border-radius: 4px;
                            opacity: 0.8;
                            font-size: 14px;
                            line-height: 16px;
                        }
                        &.tags{
                            white-space: nowrap:
                        }
                        span{
                            &.link{
                                display: inline-block;
                                margin-bottom: 4px;
                                background-color: #252424;
                                padding: 3px 8px;
                                border-radius: 40px;
                                color: #fff;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        `;

    }

    render() {
        return (
            <this.container bigLabel={this.props.bigLabel}>
                <div className="card_head">
                    <div className="card_title">
                        {this.props.title}
                    </div>
                </div>
                <div className="card_list">
                    <ul>
                        {
                            this.props.items.map((item, i) => {
                                return (
                                    <li className={``} key={i} onClick={() => item.onClick ? item.onClick() : null}>
                                        <p className="card_list_label">
                                            {item.label}
                                        </p>
                                        <p className={`card_list_data ${item.free_text ? 'free_text' : ''} ${item.tags ? 'tags' : ''}`}>
                                            {item.data}
                                        </p>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </this.container>
        );
    }
}

export default InformationList;
