import * as types from './tags_reducer';

export function setTags(state, tag_arr) {
    return {
        type: types.TAGS_STATE_ADD_TAGS,
        payload: {
            tags: tag_arr,
        },
    };
}

export function addTag(state, tag) {
    return {
        type: types.TAGS_STATE_ADD_TAG,
        payload: tag,
    };
}

export function setFetched(state, value) {
    return {
        type: types.TAGS_STATE_ASSIGN,
        payload: {
            fetched: value,
        },
    };
}
