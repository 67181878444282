import React from 'react';
import styled, { keyframes } from 'styled-components';
import oh from 'output-helpers';
import { MdKeyboardArrowLeft, MdCheck } from 'react-icons/md';

class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.category_width = 100 / props.categories.length;
        const border_height = 5;

        const anim_bounce_in = keyframes`
            0%{
                opacity: 0;
                transform: scale(0.3) translate3d(0,0,0);
            }
            50%{
                opacity: 0.9;
                transform: scale(1.1);
            }
            80%{
                opacity: 1;
                transform: scale(0.89);
            }
            100%{
                opacity: 1;
                transform: scale(1) translate3d(0,0,0);
            }
        `;

        this.container = styled.div`
            background-color: ${props => props.theme.colors.blue[1]};
            color: #fff;
            padding: 0 0 0;
            height: ${props =>
                props.fullHeight
                    ? props.theme.sizes.sub_navbar.extended_height
                    : props.theme.sizes.sub_navbar.height}px;
            position: fixed;
            top: ${props => (props.fullHeight ? '0px' : '52px')};
            left: 0;
            right: 0;
            overflow: ${props => (props.styledCategories ? 'visible' : 'auto')};
            white-space: nowrap;
            width: 100%;
            ul {
                white-space: nowrap;
                height: ${props =>
                    props.fullHeight
                        ? props.theme.sizes.sub_navbar.extended_height -
                          border_height
                        : props.theme.sizes.sub_navbar.height -
                          border_height}px;
                li {
                    width: ${this.category_width}%;
                    height: ${props =>
                        props.fullHeight
                            ? props.theme.sizes.sub_navbar.extended_height -
                              border_height
                            : props.theme.sizes.sub_navbar.height -
                              border_height}px;
                    cursor: pointer;
                    padding: ${props => (props.steps ? '0 0 10px' : '20px 0')};
                    float: left;
                    font-size: 16px;
                    text-align: center;
                    opacity: 0.5;
                    font-weight: 400;
                    transition: all 0.3s;
                    &:hover {
                        opacity: 1;
                    }
                    &.styled {
                        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                        background-color: #fff;
                        color: ${props => props.theme.colors.text};
                        border-radius: 12px;
                        margin: 0 0;
                        height: 80px;
                        width: 33%;
                        opacity: 1;
                        font-size: 12px;
                        padding: 0;
                        text-align: center;
                        svg {
                            display: block;
                            width: 40px;
                            height: 40px;
                            margin: 6px auto;
                        }
                        &.active {
                            background-color: ${props =>
                                props.theme.colors.blue[1]};
                            color: #fff;
                        }
                    }
                    &.active {
                        opacity: 1;
                        font-weight: bold;
                        .navbar_step {
                            animation: ${anim_bounce_in} 0.6s;
                            background-color: ${props =>
                                props.theme.colors.blue[1]};
                            color: #fff;
                        }
                    }
                }
            }
            .border_container {
                height: ${border_height}px;
                width: ${this.category_width * props.categories.length}%;
                position: relative;
                .border_item {
                    transition: left 0.3s;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: ${border_height}px;
                    width: ${this.category_width}%;
                    background-color: #fff;
                }
            }
            .navbar_top {
                height: ${props => props.theme.sizes.sub_navbar.height}px;
                .navigation,
                .big_title,
                .action_button {
                    display: inline-block;
                    vertical-align: middle;
                }
                .big_title {
                    width: calc(100% - 100px);
                    font-weight: bold;
                    font-size: 22px;
                    padding: 8px 0;
                    text-align: center;
                }
                .navigation,
                .action_button {
                    width: 50px;
                    svg {
                        margin-top: 5px;
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            .navbar_step {
                margin: 15px auto;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                background-color: #fff;
                font-size: 20px;
                font-weight: bold;
                display: table;
                text-align: center;
                color: ${props => props.theme.colors.dark[0]};
                transition: all 0.3s;
                p {
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        `;
    }

    renderBigTitle() {
        if (!this.props.bigTitle) {
            return null;
        }
        return <p className="big_title">{this.props.bigTitle}</p>;
    }

    renderCategories() {
        if (!this.props.categories || this.props.categories.length < 1) {
            return null;
        }
        return (
            <div className="categories_container">
                <ul>
                    {this.props.categories.map((item, i) => {
                        return (
                            <li
                                key={i}
                                className={`${
                                    this.props.styledCategories ? 'styled' : ''
                                } ${
                                    i === this.props.selected_category
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => {
                                    item.onClick();
                                }}
                            >
                                {this.props.steps ? (
                                    <div className="navbar_step">
                                        <p>{i + 1}</p>
                                    </div>
                                ) : null}
                                {oh.translate(item.name)}{' '}
                                {item.count !== undefined
                                    ? `(${item.count})`
                                    : ''}
                            </li>
                        );
                    })}
                </ul>
                {this.props.border ? (
                    <div className="border_container">
                        <div
                            className="border_item"
                            style={{
                                left:
                                    this.category_width *
                                        this.props.selected_category +
                                    '%',
                            }}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    renderActionButton() {
        if (!this.props.actionButton) {
            return null;
        }
        return (
            <div
                className="action_button"
                onClick={() => this.props.actionButton.onClick()}
            >
                <MdCheck />
            </div>
        );
    }

    renderNavigation() {
        if (!this.props.navigation) {
            return null;
        }
        return (
            <div
                className="navigation"
                onClick={() => this.props.navigation.onClick()}
            >
                <MdKeyboardArrowLeft />
            </div>
        );
    }

    render() {
        return (
            <this.container
                light={this.props.light}
                fullHeight={this.props.fullHeight}
                steps={this.props.steps}
                styledCategories={this.props.styledCategories}
            >
                {this.props.navigation ||
                this.props.bigTitle ||
                this.props.actionButton ? (
                    <div className="navbar_top">
                        {this.renderNavigation()}
                        {this.renderBigTitle()}
                        {this.renderActionButton()}
                    </div>
                ) : null}
                <div className="navbar_bottom">{this.renderCategories()}</div>

                {this.props.statusFlag ? (
                    <div className="order_status_flag">
                        <p>{this.props.statusFlag}</p>
                    </div>
                ) : null}
                {this.props.children}
            </this.container>
        );
    }
}

export default Navbar;
