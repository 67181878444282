import React from 'react';
import styled from 'styled-components';
import oh from 'output-helpers';
import { MdKeyboardArrowLeft, MdMenu, MdInfo, MdBuild, MdMessage } from 'react-icons/md';
import ItemOptionDropdown from './ItemOptionDropdown';
import {IoMdPhotos} from "react-icons/all";

class StyledNavbar extends React.Component {

    constructor(props) {
        super(props);

        this.container = styled.div `
            background-color: ${props => props.theme.colors.states[props.orderState] ? props.theme.colors.states[props.orderState] : props.theme.colors.blue[1]};
            color: #fff;
            height: 128px;
            .navbar_top{
                height: ${props => props.theme.sizes.sub_navbar.height}px;
                .navigation, .big_title, .action_button{
                    display: inline-block;
                    vertical-align: middle;
                }
                .big_title{
                    width: calc(100% - 100px);
                    font-weight: bold;
                    font-size: 22px;
                    padding: 8px 0;
                    text-align: center;
                }
                .navigation, .item_dropdown_wrapper{
                    vertical-align: middle;
                    width: 50px;
                    svg{
                        fill: #fff;
                        margin-top: 5px;
                    }
                }
                .navigation{
                    svg{
                        width: 48px;
                        height: 48px;
                    }
                }
                .item_dropdown_wrapper{
                    svg{
                        width: 40px;
                        height: 40px;
                    }
                }
            }
            ul.categories_list{
                list-style: none;
                padding: 0;
                margin: 4px 0;
                display: flex;
                justify-content: space-between;
                text-align: center;
                li{
                    color: #fff;
                    width: 30%;
                    margin: 0 1%;
                    padding: 10px 0;
                    border-radius: 8px;
                    font-size: 14px;
                    opacity: 0.7;
                    &.active{
                        background-color: rgba(0,0,0,0.6);
                        color: #fff;
                        opacity: 1;
                    }
                    &.updated{
                        background-color: ${props => props.theme.colors.red[1]};
                    }
                    svg{
                        display: block;
                        margin: auto;
                        width: 30px;
                        height: 30px;
                        margin-bottom: 4px;
                    }
                }
            }
        `;

    }

    getCategoryIcon(category) {
        switch (category.icon) {
            case 'information':
                return <MdInfo/>;
            case 'work':
                return <MdBuild/>;
            case 'messages':
                return <MdMessage/>;
            case 'photos':
                return <IoMdPhotos/>;
            default:
                return (<div></div>);
        }
    }

    renderBigTitle() {
        if (!this.props.bigTitle) { return null; }
        return (
            <p className="big_title">
                {this.props.bigTitle}
            </p>
        );
    }

    renderCategories() {
        if (!this.props.categories || this.props.categories.length < 1) { return null; }
        return (
            <div className="categories_container">
                <ul className="categories_list">
                    {
                        this.props.categories.map((item, i) => {
                            let classes = item.classes || [];
                            classes.push("styled");
                            if (this.props.selected_category === i) {
                                classes.push("active");
                            }
                            return (
                                <li key={i}
                                    className={(classes.length > 0) ? classes.join(" ") : null}
                                    onClick={() => {
                                        item.onClick();
                                    }}
                                    >
                                    {
                                        this.props.steps ?
                                        <div className="navbar_step">
                                            <p>{i+1}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        this.getCategoryIcon(item)
                                    }
                                    {oh.translate(item.name)}
                                </li>
                            );
                        })
                    }
                </ul>
                {
                    this.props.border ?
                    <div className="border_container">
                        <div className="border_item" style={{left: this.category_width * this.props.selected_category + 'px'}}>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    }

    renderActionButton() {
        if (!this.props.actionButton) { return null; }
        return (
            <ItemOptionDropdown options={this.props.actionButton.options}>
                <MdMenu />
            </ItemOptionDropdown>
        );
    }

    renderNavigation() {
        if (!this.props.navigation) { return null; }
        return (
            <div className="navigation" onClick={() => this.props.navigation.onClick()}>
                <MdKeyboardArrowLeft />
            </div>
        );
    }

    render() {
        return (
            <this.container
                orderState={this.props.orderState}
                light={this.props.light}
                steps={this.props.steps}
            >
                {
                    this.props.navigation || this.props.bigTitle || this.props.actionButton ?
                    <div className="navbar_top">
                        {this.renderNavigation()}
                        {this.renderBigTitle()}
                        {this.renderActionButton()}
                    </div>
                    :
                    null
                }
                <div className="navbar_bottom">
                    {this.renderCategories()}
                </div>
                {
                    this.props.statusFlag ?
                    <div className="order_status_flag">
                        <p>
                            {this.props.statusFlag}
                        </p>
                    </div>
                    :
                    null
                }
                {this.props.children}
            </this.container>
        );
    }
}

export default StyledNavbar;
