import React from "react";
import styled, { keyframes } from "styled-components";
import oh from "output-helpers";
import withStore from "with-store";
import LocalSpinner from "./LocalSpinner";
import api_helpers from "../../helpers/api_helpers";
import { MdSend } from "react-icons/md";
import Notifications from "../../notifications";
import * as profile_helpers from "../../helpers/profile_helpers";

class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.has_scrolled = false;
    this.state = {
      new_message: ""
    };

    const chat_write_height = 100;

    const anim_fade_down = keyframes`
            0% {
                opacity: 0;
                transform: translateY(-5px);
            }
            100% {
                opacity: 1;
                transform: translateY(0px);
            }
        `;

    this.container = styled.div`
            height: 100%;
            .chat_write{
                box-shadow: 0 -3px 5px rgba(0,0,0,0.1);
                min-height: ${chat_write_height}px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #fff;
                padding: 10px 12px;
                .chat_write_input, .chat_write_action{
                    display: inline-block;
                    vertical-align: middle;
                }
                .chat_write_input{
                    width: calc(100% - 50px);
                    padding-right: 14px;
                }
                .chat_write_action{
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    &.active{
                        svg{
                            fill: ${props => props.theme.colors.blue[1]};
                        }
                    }
                    svg{
                        width: 34px;
                        height: 34px;
                        margin-top: 6px;
                        fill: ${props => props.theme.colors.gray[1]};
                    }
                }
                textarea{
                    border-radius: 20px;
                    border: 1px solid ${props => props.theme.colors.border};
                    padding: 12px 12px;
                    width: 100%;
                    font-size: 16px;
                    height: 80px;
                    resize: none;
                    outline: none;
                    &:focus, &:active{
                        border-color: ${props => props.theme.colors.blue[1]};
                    }
                }
            }
            .chat_messages{
                overflow: auto;
                height: calc(100% - ${chat_write_height}px);
                padding: 20px 0;
                .chat_message_container{
                    margin: 12px;
                    &.from_me{
                        text-align: right;
                        .chat_message_avatar{
                            float: right;
                            margin-right: 0 !important;
                            .full_name_hover{
                                right: 0;
                            }
                        }
                        .chat_message_text{
                            margin-right: 12px;
                            .chat_message_text_bubble{
                                background-color: ${p =>
                                  p.theme.colors.blue[1]} !important;
                                color: #fff !important;
                                border-radius: 14px 4px 14px 14px !important;
                            }
                        }
                    }
                    .chat_message{
                        .chat_message_avatar, .chat_message_text{
                            display: inline-block;
                            vertical-align: middle;
                        }
                        .chat_message_date{
                            display: inline-block;
                            margin-bottom: 4px;
                            font-size: 12px;
                            opacity: 0.7;
                        }
                        .chat_message_avatar{
                            margin-top: 8px;
                            border-radius: 50%;
                            width: 50px;
                            height: 50px;
                            background-color: rgb(255, 197, 150);
                            color: rgb(222, 145, 82);
                            padding-top: 15px;
                            font-size: 18px;
                            text-align: center;
                            margin-right: 10px;
                            font-weight: bold;
                            position: relative;
                            &:HOVER{
                                .full_name_hover{
                                    display: block;
                                    animation: ${anim_fade_down} 0.4s;
                                }
                            }
                            p{
                                vertical-align: middle;
                                display: inline-block;
                            }
                            .full_name_hover{
                                z-index: 5;
                                display: none;
                                position: absolute;
                                top: 58px;
                                border-radius: 4px
                                background-color: ${props =>
                                  props.theme.colors.dark[1]};
                                color: #fff;
                                padding: 4px 8px;
                                font-size: 12px;
                                white-space: nowrap;
                            }
                        }
                        .chat_message_text{
                            max-width: 80%;
                            display: inline-block;
                            .chat_message_text_bubble{
                                text-align: left;
                                white-space: pre-line;
                                box-shadow: 0 3px 6px #61616124;
                                font-size: 14px;
                                line-height: 16px;
                                border-radius: 4px 14px 14px 14px;
                                padding: 8px 10px;
                                background-color: #fff;
                                word-break: break-word;
                                color: ${p => p.theme.colors.text};
                            }
                        }
                    }
                }

            }

        `;
  }

  postMessage(text, existing_order) {
    let now = new Date();

    this.props.actions.spinner.add();
    api_helpers
      .postMessage({
        type: "text",
        ref: this.props.ref_id,
        data: {
          body: text,
          send_time: new Date().toISOString()
        }
      })
      .then(msg => {
        profile_helpers.setLastViewed(existing_order.id, "order_messages");
        this.props.actions.messages.setMessage(msg);
        this.scrollToBottom();

        if (!existing_order) {
          return null;
        }

        let altered_order = Object.assign({}, existing_order);
        altered_order.data = Object.assign({}, existing_order.data);
        altered_order.data.last_message_posted_at = now.toISOString();

        return api_helpers.updateOrder(altered_order).then(updated_order => {
          this.props.actions.order.setOrder(updated_order);
        });
      })
      .finally(() => {
        this.props.actions.spinner.remove();
      });
  }

  componentDidMount() {
    Notifications.addEventInspector(this, "message_created", event => {
      if (event.data.ref === this.props.ref_id) {
        this.scrollToBottom();
      }
    });
    this.scrollToBottom();
  }

  componentDidUpdate() {
    let messages = this.props.se.getMessagesFromRef(this.props.ref_id);
    if (messages !== null && this.has_scrolled === false) {
      this.scrollToBottom();
      this.has_scrolled = true;
    }
  }

  componentWillUnmount() {
    Notifications.clearEventInspectors(this);
  }

  scrollToBottom() {
    if (this.messageList) {
      const scrollHeight = this.messageList.scrollHeight;
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  getExistingOrderFromRefId() {
    if (this.props.ref_id.startsWith("order://") === false) {
      return null;
    }
    let order_id = this.props.ref_id.split("/").pop();
    return this.props.store.order.by_id[order_id];
  }

  render() {
    let messages = this.props.se.getMessagesFromRef(this.props.ref_id);
    if (messages === null) {
      return <LocalSpinner />;
    }
    return (
      <this.container>
        <div
          className="chat_messages"
          ref={div => {
            this.messageList = div;
          }}
        >
          {messages.map((msg, i) => {
            return (
              <div
                key={i}
                className={`chat_message_container ${
                  msg.from.id === this.props.store.users.my_id ? "from_me" : ""
                }`}
              >
                <div className="chat_message">
                  <div className="chat_message_avatar">
                    {msg.from.first_name.substring(0, 1)}
                    {msg.from.last_name.substring(0, 1)}
                    <span className="full_name_hover">
                      {msg.from.full_name}
                    </span>
                  </div>

                  <div className="chat_message_text">
                    <div className="chat_message_date">{msg.time}</div>
                    <div className="chat_message_text_bubble">{msg.body}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="chat_write">
          <div className="chat_write_input">
            <textarea
              onKeyPress={e => {
                if (
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  this.state.new_message.trim().length > 0
                ) {
                  this.postMessage(
                    this.state.new_message.trim(),
                    this.getExistingOrderFromRefId()
                  );
                  this.setState({
                    new_message: ""
                  });
                  e.preventDefault();
                } else if (
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  this.state.new_message.trim().length === 0
                ) {
                  e.preventDefault();
                }
              }}
              rows="1"
              placeholder={oh.translate("enter_message")}
              value={this.state.new_message}
              onChange={e => this.setState({ new_message: e.target.value })}
            />
          </div>
          <div
            className={`chat_write_action ${
              this.state.new_message.length > 0 ? "active" : ""
            }`}
            onClick={() => {
              if (this.state.new_message.length > 0) {
                this.postMessage(
                  this.state.new_message,
                  this.getExistingOrderFromRefId()
                );
                this.setState({
                  new_message: ""
                });
              }
            }}
          >
            <MdSend />
          </div>
        </div>
      </this.container>
    );
  }
}

export default withStore(Chat);
