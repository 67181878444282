function makeEntityMatchStr(collection, schema, entity) {
    let args = [];
    args.push(entity.data['meta.id']);

    if (
        entity.data.hasOwnProperty('meta.depot') &&
        typeof entity.data['meta.depot'] === 'string' &&
        entity.data['meta.depot'].length > 0
    ) {
        args.push(entity.data['meta.depot']);
    }
    if (
        entity.data.hasOwnProperty('meta.vehicle_number') &&
        typeof entity.data['meta.vehicle_number'] === 'number'
    ) {
        args.push(entity.data['meta.vehicle_number'].toString());
    }

    let prop = schema.properties.find(x => x.name === 'Chassinummer');
    if (prop) {
        if (entity.data[prop.key]) {
            args.push(entity.data[prop.key]);
        }
    }

    return args.join(':');
}

export function unpack(collection, schema, entity_from_server) {
    let entity = entity_from_server;
    entity.match_str = makeEntityMatchStr(collection, schema, entity);
    entity.name = entity.id;
    entity.id = 'entity://' + collection + '/' + encodeURIComponent(entity.id);
    entity.collection = collection;
    schema.properties.forEach(prop => {
        if (entity.data.hasOwnProperty(prop.key) === false) {
            if (prop.type === 'string') {
                entity.data[prop.key] = '';
            } else {
                entity.data[prop.key] = null;
            }
        }
    });
    return entity;
}

export function pack(collection, schema, entity) {
    let for_server = {};
    for_server.id = entity.data['meta.id'];
    for_server.last_modified = entity.last_modified;
    for_server.data = {};

    let schema_keys = schema.properties.map(prop => prop.key);
    Object.keys(entity.data).forEach(key => {
        if (schema_keys.includes(key) === false || entity.data[key] === null) {
            return;
        }

        if (schema.by_id[key].type === 'string' && entity.data[key] === '') {
            return;
        }

        for_server.data[key] = entity.data[key];
    });
    return for_server;
}

export function getIdFromRef(ref) {
    return decodeURIComponent(ref.split('/').pop());
}

export function resolve(entity) {
    let ret = Object.assign({}, entity);
    ret.data = Object.assign({}, entity.data);
    return ret;
}
