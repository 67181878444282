import React from 'react';
import styled from 'styled-components';
import Button from '../base/Button';
import oh from 'output-helpers';
import StyledNavbar from '../base/StyledNavbar';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const InnerContainer = styled.div`
    position: absolute;
    top: 128px;
    bottom: 142px;
    left: 0;
    right: 0;
    padding: 40px;
    overflow-y: scroll;
`;

const ButtonContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    flex-direction: column;
    .last-btn {
        margin-top: 10px;
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

class ImagesHandling extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onClose = props.onClose;
        this.onUsage = props.onTakePhoto;
        this.state = {
            file_data_uri: null,
        };
        this.input_ref = React.createRef();
    }

    componentDidMount() {
        this.input_ref.current.click();
    }

    onFilePickerChange = e => {
        if (!e.target.files[0]) {
            return;
        }

        const file = e.target.files[0];

        const file_reader = new FileReader();

        if (file_reader && file) {
            file_reader.readAsDataURL(file);
            file_reader.onload = () => {
                this.setState({
                    ...this.state,
                    file_data_uri: file_reader.result,
                });
            };

            file_reader.onerror = err => {
                console.error(err);
            };
        }
    };

    render() {
        return (
            <Container>
                <input
                    ref={this.input_ref}
                    type="file"
                    accept="image/x-png,image/jpeg,image/gif"
                    style={{ display: 'none' }}
                    onChange={this.onFilePickerChange}
                />
                <StyledNavbar
                    bigTitle={oh.translate('photos')}
                    navigation={{
                        onClick: () => {
                            this.onClose();
                        },
                    }}
                    selected_category={''}
                    categories={''}
                />
                <InnerContainer>
                    {this.state.file_data_uri && (
                        <Image src={this.state.file_data_uri} />
                    )}
                </InnerContainer>
                {this.state.file_data_uri && (
                    <ButtonContainer>
                        <Button
                            filled
                            block
                            green
                            rounded={false}
                            label={oh.translate('use_image')}
                            onClick={() => {
                                const base64_data = this.state.file_data_uri.split(
                                    ',',
                                )[1];
                                const mime_type = this.state.file_data_uri
                                    .split(',')[0]
                                    .split(':')[1]
                                    .split(';')[0];
                                this.onUsage(base64_data, mime_type);
                                this.onClose();
                            }}
                        />
                        {this.input_ref.current && (
                            <Button
                                className={'last-btn'}
                                filled
                                block
                                dark
                                rounded={true}
                                label={oh.translate('change_image')}
                                onClick={() => {
                                    this.input_ref.current.click();
                                }}
                            />
                        )}
                    </ButtonContainer>
                )}
            </Container>
        );
    }
}

export default ImagesHandling;
