import React from 'react';
import SSO from 'sso-helper';
import withStore from 'with-store';
import Styled, {keyframes} from 'styled-components';
import { withRouter } from 'react-router';
import oh from 'output-helpers';
import * as profile_helpers from '../helpers/profile_helpers';
import { MdAssignmentInd } from 'react-icons/md';
import Notifications from '../notifications';
import * as notification_helpers from '../helpers/notification_helpers';
import registerServiceWorker from "../register_service_worker";

function onPageUnload(e) {
    if (window.sso && window.sso.isLoggedIn()) {
        profile_helpers.updateProfile({ last_app_exit: new Date().toISOString() });
    }
}

window.addEventListener("beforeunload", function (event) {
    onPageUnload(event);
});

function setupSSO(props) {
    let sso_config = null;
    if (props.config.api.hasOwnProperty('sso')) {
        sso_config = {
            login_uri: props.config.api.sso.login_uri,
            token_provider_uri: props.config.api.sso.api_uri,
            jwt_acquire_callback: () => {
                if (props.config.notifications) {
                    Notifications.setConfig(Object.assign({}, props.config.notifications, {store: withStore}));
                    notification_helpers.registerAllListeners();
                }
                registerServiceWorker(props.config.vapid_public_key);
                profile_helpers.doAfterFirstFetch(() => {
                    profile_helpers.setLastLogin();
                });
                props.history.push(props.location.pathname);
                //Update language preference.
                oh.setConfig({ lang: window.sso.getJWT().getClaim("lang") });

            },
            jwt_release_callback: (was_logout) => {
                window.sso = null;
                if (props.config.notifications) {
                    notification_helpers.clearAllListeners();
                    Notifications.disconnect();
                }
                if (!was_logout) {
                    document.location = '/';
                }
            }
        };
    }
    window.sso = new SSO(props.config.service_name, sso_config);
    window.sso.init().then(() => {
        if (!window.sso.isLoggedIn()) {
            let uri = props.config.api.sso.login_uri + '/login?service=' + encodeURIComponent(props.config.service_name);
            if (props.config.root_mobile_uri && props.config.production) {
                uri += '&returnUrl=' + encodeURIComponent(props.config.root_mobile_uri);
            }
            document.location.href = uri;
            return;
        }
        if (props.config.notifications) {
            Notifications.setConfig(Object.assign({}, props.config.notifications, {store: withStore}));
            notification_helpers.registerAllListeners();
        }
        registerServiceWorker(props.config.vapid_public_key);
    }).catch((err) => {
        console.log("Object.keys(err):", Object.keys(err));
        console.log(err);
    });
}

function updateAppEntry(profile) {
    profile_helpers.updateProfile({
        //Put what was the previous current entry as the last.
        last_app_entry: profile.current_app_entry,
        //Add new current app entry.
        current_app_entry: new Date().toISOString()
    });
}

//This component expects to have a 'config' property.
class LoginCheck extends React.Component {
    constructor(props) {
        super(props);

        if (!window.sso) {
            setupSSO(props);
        }

        this.state = {
            logged_in: false
        };

        const anim_bounce = keyframes`
            0% {
                transform: translateY(-12px);
            }
            50% {
                transform: translateY(0px);
            }
            99% {
                transform: translateY(-12px);
            }
        `;

        this.Container = Styled.div`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            .icon_container{
                margin: 200px auto 100px;
                svg{
                    animation: ${anim_bounce} 1s infinite;
                    opacity: 0.3;
                    width: 120px;
                    height: 120px;
                }
            }
        `;
    }

    componentDidUpdate(prev_props, prev_state) {
        if (!window.sso || !window.sso.isLoggedIn()) {
            setupSSO(this.props);
        }
        if (prev_state.logged_in === false && this.state.logged_in === false && window.sso.isLoggedIn()) {
            this.setState({ logged_in: true });
            profile_helpers.doAfterFirstFetch((profile) => {
                updateAppEntry(profile);
            });
        }
    }

    componentDidMount() {
        if (window.sso && window.sso.isLoggedIn()) {
            this.setState({ logged_in: true, bla: "bar" });
            profile_helpers.doAfterFirstFetch((profile) => {
                updateAppEntry(profile);
            });
        }
    }

    render() {
        if (!window.sso || !window.sso.isLoggedIn()) {
            return (
                <this.Container>
                    <div className="icon_container">
                        <MdAssignmentInd />
                    </div>
                </this.Container>
            );
        }

        if (this.props.store.users.my_id === -1) {
            oh.setConfig({ lang: window.sso.getJWT().getClaim('lang') });
            this.props.actions.users.setMyId(window.sso.getJWT().getClaim('uid'));
            //Ensure that the above state change has gone through before rendering children.
            return (<div></div>);
        }

        return (
            <div>
                { this.props.children }
            </div>
        );
    }
}

export default withRouter(withStore(LoginCheck));
