import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import userInputs from 'user-inputs';
import AllbinBar from 'allbin-bar';
import withStore from 'with-store';
import oh from 'output-helpers';
import { MdAssignment, MdDirectionsBus } from 'react-icons/md';
import Navbar from '../base/Navbar';
import OrderListItem from '../base/OrderListItem';
import VehicleList from '../base/VehicleList';
import getChangelog from '../../changelog';
import * as profile_helpers from '../../helpers/profile_helpers';
import ImageCreateEntity from '../../img/create_entity.png';
import * as order_helpers from '../../helpers/order_helpers';

const OrderState = {
    MINE: 0,
    OTHERS: 1,
    COMPLETED: 2,
    APPROVED: 3,
};

const OrderStateIndex = {
    published: 0,
    assigned: 1,
    completed: 2,
    approved: 3,
};

const ViewType = {
    ORDERS: 0,
    VEHICLES: 1,
};

class MainView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_term_orders: '',
            search_term_vehicles: '',
        };

        this.container = styled.div`
            .navbar {
                background-color: ${props =>
                    props.theme.colors.blue[1]} !important;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
            }
            input.search {
                border-radius: 20px 20px 20px 20px;
                width: calc(100% - 20px);
                margin: 5px 10px 5px 10px;
                height: 40px;
                border: none;
                padding-left: 15px;
            }

            .main_body {
                top: 104px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 58px;
                overflow: auto;
            }
            .footer {
                height: 58px;
                border-top: 1px solid ${props => props.theme.colors.border};
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: ${props => props.theme.colors.gray[3]};
                .footer_segment {
                    width: 50%;
                    display: inline-block;
                    padding: 8px 0;
                    text-align: center;
                    opacity: 0.5;
                    &.active {
                        opacity: 1;
                        background-color: ${props =>
                            props.theme.colors.blue[1]};
                        color: #fff;
                        svg {
                            fill: #fff;
                        }
                    }
                    &:first-child {
                        border-right: 1px solid
                            ${props => props.theme.colors.border};
                    }
                    svg {
                        margin-bottom: 2px;
                        width: 26px;
                        height: 26px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
            .empty_state {
                padding: 60px 20px;
                text-align: center;
                .empty_state_symbol {
                    height: 150px;
                    margin: auto;
                    margin-bottom: 50px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url(${ImageCreateEntity});
                }
                .empty_state_title {
                    font-weight: bold;
                    margin-bottom: 14px;
                    font-size: 20px;
                }
                .empty_state_subtitle {
                    opacity: 0.7;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        `;

        this.order_state_categories = [
            {
                name: 'mine',
                onClick: () => {
                    this.props.actions.interaction.set({
                        selected_category: OrderState.MINE,
                    });
                },
            },
            {
                name: 'others',
                onClick: () => {
                    this.props.actions.interaction.set({
                        selected_category: OrderState.OTHERS,
                    });
                },
            },
            {
                name: 'completed',
                onClick: () => {
                    this.props.actions.interaction.set({
                        selected_category: OrderState.COMPLETED,
                    });
                },
            },
            {
                name: 'approved',
                onClick: () => {
                    this.props.actions.interaction.set({
                        selected_category: OrderState.APPROVED,
                    });
                    order_helpers.fetchApproved();
                },
            },
        ];
    }

    renderOrders(my_orders, other_orders, completed_orders, approved_orders) {
        let orders = [];
        if (
            this.props.store.interaction.selected_category === OrderState.MINE
        ) {
            orders = my_orders;
            if (orders.length === 0) {
                return (
                    <div className="empty_state">
                        <div className="empty_state_symbol mine" />
                        <p className="empty_state_title">
                            {oh.translate('you_have_no_associated_orders')}
                        </p>
                        <p className="empty_state_subtitle">
                            {oh.translate(
                                'you_have_no_associated_orders_subtitle',
                            )}
                        </p>
                    </div>
                );
            }
        } else if (
            this.props.store.interaction.selected_category === OrderState.OTHERS
        ) {
            orders = other_orders;
            if (orders.length === 0) {
                return (
                    <div className="empty_state">
                        <div className="empty_state_symbol mine" />
                        <p className="empty_state_title">
                            {oh.translate('no_orders')}
                        </p>
                        <p className="empty_state_subtitle">
                            {oh.translate('your_organization_has_no_orders')}
                        </p>
                    </div>
                );
            }
        } else if (
            this.props.store.interaction.selected_category ===
            OrderState.COMPLETED
        ) {
            orders = completed_orders;
            if (orders.length === 0) {
                return (
                    <div className="empty_state">
                        <div className="empty_state_symbol completed" />
                        <p className="empty_state_title">
                            {oh.translate('no_orders_in_completed_state')}
                        </p>
                        <p className="empty_state_subtitle">
                            {oh.translate(
                                'no_orders_in_completed_state_subtitle',
                            )}
                        </p>
                    </div>
                );
            }
        } else if (
            this.props.store.interaction.selected_category ===
            OrderState.APPROVED
        ) {
            orders = approved_orders;
            if (orders.length === 0) {
                return (
                    <div className="empty_state">
                        <div className="empty_state_symbol completed" />
                        <p className="empty_state_title">
                            {oh.translate('no_orders_in_completed_state')}
                        </p>
                        <p className="empty_state_subtitle">
                            {oh.translate(
                                'no_orders_in_completed_state_subtitle',
                            )}
                        </p>
                    </div>
                );
            }
        }

        if (orders.length === 0) {
            return <p>{oh.translate('no_published_orders')}</p>;
        }
        if (this.state.search_term_orders.length > 0) {
            orders = orders.filter(order =>
                order.match_str
                    .toLowerCase()
                    .includes(this.state.search_term_orders.toLowerCase()),
            );
        }
        if (orders.length === 0) {
            return <p>{oh.translate('no_search_results')}</p>;
        }
        return (
            <ul>
                {orders.map((order, i) => {
                    let order_last_viewed = new Date(
                        this.props.se.getLastViewed(order.id, 'order'),
                    );
                    let messages_last_viewed = this.props.se.getLastViewed(
                        order.id,
                        'order_messages',
                    );
                    return (
                        <OrderListItem
                            updated={
                                order.last_modified >
                                order_last_viewed.getTime()
                            }
                            newMessage={
                                (order.data.hasOwnProperty(
                                    'last_message_posted_at',
                                ) &&
                                    order.data.last_message_posted_at >
                                        messages_last_viewed) ||
                                (messages_last_viewed === null &&
                                    order.data.last_message_posted_at !==
                                        undefined)
                            }
                            order={order}
                            onClick={() => {
                                this.props.se.setSelectedOrder(order.id);
                                profile_helpers.setLastViewed(
                                    order.id,
                                    'order',
                                );
                            }}
                            key={i}
                        >
                            {i}
                        </OrderListItem>
                    );
                })}
            </ul>
        );
    }

    getMyOrders(all_orders) {
        return all_orders.filter(
            order =>
                order.associated_user_ids.includes(
                    this.props.store.users.my_id,
                ) &&
                order.state !== 'completed' &&
                order.state !== 'approved',
        );
    }

    getOtherOrders(all_orders) {
        return all_orders.filter(
            order =>
                order.state !== 'completed' &&
                order.state !== 'approved' &&
                !order.associated_user_ids.includes(
                    this.props.store.users.my_id,
                ),
        );
    }
    getApprovedOrders(all_orders) {
        return all_orders.filter(order => order.state === 'approved');
    }
    getCompletedOrders(all_orders) {
        return all_orders.filter(order => order.state === 'completed');
    }

    renderFooter() {
        let order_btn_classes = ['footer_segment'];
        let vehicle_btn_classes = ['footer_segment'];

        if (
            this.props.store.interaction.selected_view_type === ViewType.ORDERS
        ) {
            order_btn_classes.push('active');
        } else {
            vehicle_btn_classes.push('active');
        }

        return (
            <div className="footer">
                <div
                    className={order_btn_classes.join(' ')}
                    onClick={() => {
                        this.props.actions.interaction.set({
                            selected_view_type: ViewType.ORDERS,
                        });
                    }}
                >
                    <MdAssignment />
                    <p>{oh.translate('orders')}</p>
                </div>
                <div
                    className={vehicle_btn_classes.join(' ')}
                    onClick={() => {
                        this.props.actions.interaction.set({
                            selected_view_type: ViewType.VEHICLES,
                        });
                    }}
                >
                    <MdDirectionsBus />
                    <p>{oh.translate('vehicles')}</p>
                </div>
            </div>
        );
    }

    renderViewType(my_orders, other_orders, completed_orders, approved_orders) {
        if (
            this.props.store.interaction.selected_view_type === ViewType.ORDERS
        ) {
            return (
                <div>
                    <Navbar
                        count
                        border
                        selected_category={
                            this.props.store.interaction.selected_category
                        }
                        categories={this.order_state_categories}
                    />
                    <div className="main_body">
                        {this.renderOrders(
                            my_orders,
                            other_orders,
                            completed_orders,
                            approved_orders,
                        )}
                    </div>
                    {this.renderFooter()}
                </div>
            );
        }

        return (
            <div>
                <Navbar border selected_category={0} categories={[]}>
                    <input
                        className="search"
                        type="text"
                        name="search"
                        placeholder={oh.translate('search')}
                        value={this.state.search_term_vehicles}
                        onChange={event =>
                            this.setState({
                                search_term_vehicles: event.target.value,
                            })
                        }
                    />
                </Navbar>
                <div className="main_body">
                    <VehicleList search={this.state.search_term_vehicles} />
                </div>
                {this.renderFooter()}
            </div>
        );
    }

    render() {
        const user = this.props.se.getUser(this.props.store.users.my_id);
        let all_orders = this.props.se.getOrdersFromAssociatedOrgIds([
            user.installation_org_id,
        ]);
        all_orders = all_orders
            .sort((a, b) => {
                let ret = OrderStateIndex[a.state] - OrderStateIndex[b.state];
                if (ret === 0) {
                    ret = a.id - b.id;
                }
                return ret;
            })
            .filter(
                order =>
                    !order.assignee_org_id ||
                    order.assignee_org_id === user.installation_org_id,
            )
            .sort((a, b) => {
                const a_prio = a.data.priority ? a.data.priority : 0;
                const b_prio = b.data.priority ? b.data.priority : 0;
                if (a_prio > b_prio) {
                    return -1;
                }
                if (a_prio < b_prio) {
                    return 1;
                }
                return 0;
            });

        const my_orders = this.getMyOrders(all_orders);
        const other_orders = this.getOtherOrders(all_orders);
        const completed_orders = this.getCompletedOrders(all_orders);
        const approved_orders = this.getApprovedOrders(all_orders);

        this.order_state_categories[OrderState.MINE].count = my_orders.length;
        this.order_state_categories[OrderState.OTHERS].count =
            other_orders.length;
        this.order_state_categories[OrderState.COMPLETED].count =
            completed_orders.length;
        this.order_state_categories[OrderState.APPROVED].count = undefined;

        return (
            <this.container>
                <AllbinBar
                    language={window.sso.getJWT().getClaim('lang')}
                    background={'#1563EE'}
                    navbar={true}
                    styled_service_name={true}
                    logout={true}
                    service_name={'Installation'}
                    enable_password_reset={false}
                    dashboard_link={false}
                    contact={true}
                    changelog={getChangelog()}
                    help={false}
                    show_credentials={true}
                />
                {this.renderViewType(
                    my_orders,
                    other_orders,
                    completed_orders,
                    approved_orders,
                )}
            </this.container>
        );
    }
}

export default withRouter(withStore(userInputs(MainView)));
