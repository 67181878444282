import React from 'react';
import userInputs from 'user-inputs';
import withStore from 'with-store';
import oh from 'output-helpers';
import styled from 'styled-components';

// import Notifications from '../../notifications';
import LocalSpinner from './LocalSpinner';
import TaskCard from './TaskCard';
import { EntityViewTab } from '../../defs/entity_view_defs';
import api_helpers from '../../helpers/api_helpers';
import * as profile_helpers from '../../helpers/profile_helpers';

class EntityTaskList extends React.Component {

    constructor(props) {
        super(props);

        if (!props.entity) {
            throw new Error("EntityTaskList requires entity property.");
        }

        this.container = styled.div `
            padding: 12px 0;
            background-color: ${props => props.theme.colors.background};
            .order_title{
                margin: 24px 12px 12px;
                font-size: 21px;
                font-weight: bold;
            }
        `;

    }

    taskCardClickYes(task_id, entity_id) {
        let inputs = [
            {
                key: "changes_were_made",
                default_value: "changes_were_made",
                type: "button",
                label: oh.translate("have_edit"),
                props: {
                    onClick: () => {
                        this.props.userPrompt.cancel();
                        this.props.se.setSelectedTask(task_id);
                        if (this.props.setEntityViewTab) {
                            this.props.setEntityViewTab(EntityViewTab.INVENTORY);
                        }
                    },
                    green: true,
                    filled: true,
                    block: true
                }
            },
            {
                key: "changes_were_not_made",
                default_value: "changes_were_not_made",
                type: "button",
                label: oh.translate("have_not_edit"),
                props: {
                    onClick: () => {
                        this.props.userPrompt.cancel();
                        this.props.actions.spinner.add();
                        let assignee_id = this.props.store.users.my_id;
                        let task = this.props.store.tasks.by_id[task_id];
                        let altered_task = Object.assign({}, task, { assignee_id: assignee_id, completed: true });
                        return api_helpers.updateTask(altered_task).then((updated_task) => {
                            this.props.actions.tasks.setTask(updated_task);
                        }).finally(() => {
                            this.props.actions.spinner.remove();
                        });
                    },
                    dark: true,
                    filled: true,
                    block: true
                }
            },
            {
                key: "cancel",
                default_value: "cancel",
                type: "button",
                label: oh.translate("cancel"),
                props: {
                    onClick: () => {
                        this.props.userPrompt.cancel();
                    },
                    red: true,
                    filled: true,
                    block: true
                }
            }
        ];
        this.props.userPrompt.confirm({
            props: {
                title: oh.translate("have_you_edited_the_vehicle"),
                show_cancel_btn: false,
                show_confirm_btn: false
            },
            inputs: inputs
        });
        this.props.userPrompt.setTag("task");
    }
    taskCardClickNo(task_id, entity_id) {
        let entity = this.props.store.entity.by_id[entity_id];
        let task = this.props.store.tasks.by_id[task_id];
        let order = this.props.store.order.by_id[task.order_id];

        let prepend_str = "[" + entity.data["meta.vehicle_type"] + " " + entity.data["meta.id"] + "]:";
        this.props.userPrompt.confirm({
            props: {
                title: oh.translate('are_you_sure'),
                confirm_button_label: oh.translate("yes"),
                cancel_button_label: oh.translate("no")
            },
            inputs: [{
                type: "textarea",
                key: "desc",
                label: oh.translate('task_uncomplete_desc'),
                default_value: prepend_str + " "
            }]
        }, (values) => {
            let desc = values.desc.trim();
            if (desc.length > 0 && desc.startsWith(prepend_str) && desc.length === prepend_str.length) {
                this.props.userPrompt.cancel();
                this.props.userPrompt.alert({
                    props: {
                        title: oh.translate('message_required'),
                        message: oh.translate('message_required_long')
                    }
                });
                // no description given. ignore request
                return;
            }

            withStore.actions.spinner.add();
            let now = new Date();
            Promise.resolve().then(() => {
                if (desc.length > 0) {
                    return api_helpers.postMessage({
                        type: "text",
                        ref: "order://" + task.order_id,
                        data: {
                            body: desc
                        }
                    }).then((msg) => {
                        profile_helpers.setLastViewed(task.order_id, "order_messages");
                        this.props.actions.messages.setMessage(msg);
                    });
                }
            }).then(() => {
                let assignee_id = this.props.store.users.my_id;
                let altered_task = Object.assign({}, task, {assignee_id: assignee_id, completed: false});
                return api_helpers.updateTask(altered_task)
                    .then((updated_task) => {
                        withStore.actions.tasks.setTask(updated_task);
                    });
            }).then(() => {
                let altered_order = Object.assign({}, order);
                altered_order.data = Object.assign({}, order.data);
                altered_order.data.last_message_posted_at = now.toISOString();

                return api_helpers.updateOrder(altered_order)
                    .then((updated_order) => {
                        withStore.actions.order.setOrder(updated_order);
                    });
            }).finally(() => {
                withStore.actions.spinner.remove();
            });
        });
        this.props.userPrompt.setTag("task");
    }
    taskCardClickNote(task_id, entity_id) {
        let task = this.props.store.tasks.by_id[task_id];

        let current_note = "";
        if (task.data.hasOwnProperty("contractor_note")) {
            current_note = task.data.contractor_note;
        }
        this.props.userPrompt.confirm({
            props: {
                title: oh.translate('note_for') + " " + entity_id,
                confirm_button_label: oh.translate("save"),
                cancel_button_label: oh.translate("cancel")
            },
            inputs: [{
                type: "textarea",
                key: "note",
                label: oh.translate('note'),
                default_value: current_note
            }]
        }, (values) => {
            let altered_task = Object.assign(
                {},
                task,
                {
                    data: Object.assign(
                        {},
                        task.data,
                        {
                            contractor_note: values.note
                        }
                    )
                }
            );
            if (values.note.length === 0) {
                delete altered_task.data.contractor_note;
            }
            withStore.actions.spinner.add();
            api_helpers.updateTask(altered_task).then((updated_task) => {
                withStore.actions.tasks.setTask(updated_task);
            }).finally(() => {
                withStore.actions.spinner.remove();
            });
        });
        this.props.userPrompt.setTag("task");
    }

    renderTasks(tasks) {

        let grouped_tasks = tasks.reduce((a, c) => {
            if (!a.hasOwnProperty(c.order_id)) {
                a[c.order_id] = {
                    tasks: []
                };
            }
            let task = {
                order_id: c.order_id,
                type: c.type,
                data: {
                    description: c.data.description
                },
                tags: c.tags,
                entities: []
            };
            task.entities.push({
                id: this.props.entity.data["meta.id"],
                task_id: c.id,
                completed: c.completed,
                admin_note: c.data.admin_note,
                contractor_note: c.data.contractor_note,
                ref: this.props.entity.id,
                data: this.props.entity.data
            });
            a[c.order_id].tasks.push(task);
            return a;
        }, {});

        return Object.keys(grouped_tasks).sort((a, b) => a.order_id - b.order_id).map((order_id) => {
            let tasks = grouped_tasks[order_id].tasks.sort((a, b) => {
                if (a.type < b.type) {
                    return -1;
                }
                return 1;
            });
            return (
                <div key={order_id}>
                    <div className="order_title">
                        { oh.translate("order") + " #" + order_id }
                    </div>
                    {
                        tasks.map((task, i) => {
                            return <TaskCard
                                entities={task.entities}
                                key={i}
                                task={task}
                                onClickEntity={(type, task_id, entity_id) => {
                                    if (type === "yes") {
                                        this.taskCardClickYes(task_id, entity_id);
                                    } else if (type === "no") {
                                        this.taskCardClickNo(task_id, entity_id);
                                    } else {
                                        this.taskCardClickNote(task_id, entity_id);
                                    }
                                }}
                            />;
                        })
                    }
                </div>
            );
        });

    }

    render() {
        let tasks = this.props.se.getTasksFromEntityRef(this.props.entity.id);

        if (tasks === null) {
            return <LocalSpinner />;
        }
        return (
            <this.container>
                { this.renderTasks(tasks) }
            </this.container>
        );
    }
}

export default withStore(userInputs(EntityTaskList));
