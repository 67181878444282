import React from 'react';
import styled from 'styled-components';
import { MdMessage } from 'react-icons/md';
import oh from 'output-helpers';
import default_config from '../../default_config';
import { FaExclamation } from 'react-icons/all';

class OrderListItem extends React.Component {
    constructor(props) {
        super(props);
        this.container = styled.li`
            background-color: #fff;
            border-bottom: 1px solid ${props => props.theme.colors.border};
            .order_info_block {
                padding: 12px;
                width: 50%;
            }
            .order_message_block {
                width: 15%;
                display: inline-block;
                text-align: center;
                font-size: 14px;
                svg {
                    fill: ${props => props.theme.colors.red[1]};
                }
            }
            .order_icon_block {
                width: 20%;
                text-align: center;
                white-space: nowrap;
                text-align: right;
                .state_label {
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 20px;
                    background-color: ${props =>
                        props.theme.colors.states_dimmed[props.state]
                            ? props.theme.colors.states_dimmed[props.state]
                            : 'gray'};
                    color: ${props =>
                        props.theme.colors.states[props.state]
                            ? props.theme.colors.states[props.state]
                            : '#000'};
                    padding: 6px 12px;
                    font-size: 12px;
                    font-weight: bold;
                }
                svg {
                    vertical-align: middle;
                    width: 40px;
                    height: 40px;
                }
            }
            .order_prio_block {
                width: 5%;
                display: inline-block;
                font-size: 12px;
                text-align: center;
            }
            .order_id_block {
                display: inline-block;
                width: 5%;
                font-size: 14px;
                vertical-align: middle;
                text-align: center;
            }
            .order_info_block,
            .order_icon_block {
                display: inline-block;
                vertical-align: middle;
            }
            .order_title {
                font-weight: bold;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 4px;
            }
            .order_description {
                white-space: nowrap;
                font-size: 10px;
                line-height: 14px;
                opacity: 0.6;
                overflow: hidden;
            }
        `;
    }

    getIcon() {
        if (this.props['newMessage']) {
            return <MdMessage className="message" />;
        }
    }
    getPriority() {
        if (this.props.order.data.priority) {
            return (
                <FaExclamation
                    fill={
                        default_config.star_color[
                            this.props.order.data.priority - 1
                        ]
                    }
                />
            );
        }
        return null;
    }
    getEntities() {
        if (this.props.order.entities === undefined) {
            return '';
        }
        const entities = this.props.order.entities.map(e => {
            const split = e.split('/');
            return decodeURI(split[split.length - 1]);
        });
        return entities.join(',');
    }
    render() {
        let classes = this.props.classes || [];
        classes.push('order_list_item');
        if (this.props['newMessage']) {
            classes.push('new_message');
        }
        return (
            <this.container
                className={classes.join(' ')}
                state={this.props.order.state}
                onClick={() => {
                    this.props.onClick();
                }}
            >
                <p className="order_id_block">{this.props.order.id}</p>
                <p className="order_prio_block">{this.getPriority()}</p>
                <div className="order_info_block">
                    <p className="order_title">{this.getEntities()}</p>
                    <p className="order_description">
                        {this.props.order.data.address ? (
                            this.props.order.data.address
                        ) : (
                            <i>{oh.translate('no_address')}</i>
                        )}
                    </p>
                </div>
                <div className="order_message_block">{this.getIcon()}</div>
                <div className="order_icon_block">
                    <p
                        className={`state_label state_${this.props.order.state}`}
                    >
                        {this.props.order.state
                            ? this.props.order.state === 'completed' &&
                              this.props.order.data.done
                                ? oh.translate('singular_done')
                                : oh.translate(
                                      'singular_' + this.props.order.state,
                                  )
                            : ''}
                    </p>
                </div>
            </this.container>
        );
    }
}

export default OrderListItem;
