import * as store_helpers from './store_helpers';

export const TASKS_STATE_CLEAR = 'TASKS_STATE_CLEAR';
export const TASKS_STATE_ASSIGN = 'TASKS_STATE_ASSIGN';
export const TASKS_STATE_SET_TASK = 'TASKS_STATE_SET_TASK';
export const TASKS_STATE_SET_TASKS = 'TASKS_STATE_SET_TASKS';
export const TASKS_STATE_DELETE_TASKS = 'TASKS_STATE_DELETE_TASKS';

let default_state = {
    fetched: false,
    complete_entity_fetched: {},
    complete_order_fetched: {},
    all: [],
    by_id: {},
    by_entity_ref: {},
};

function setTaskItem(current_state, task) {
    let new_state = store_helpers.SetItem(task, current_state);

    if (!new_state.by_entity_ref.hasOwnProperty(task.entity)) {
        new_state.by_entity_ref[task.entity] = [];
    }

    let index = new_state.by_entity_ref[task.entity].findIndex(
        existing_task => existing_task.id === task.id,
    );
    if (index === -1) {
        new_state.by_entity_ref[task.entity].push(new_state.by_id[task.id]);
    } else {
        new_state.by_entity_ref[task.entity][index] = new_state.by_id[task.id];
    }

    return new_state;
}

function deleteTaskItem(current_state, task) {
    let new_state = store_helpers.DeleteItem(task, current_state);
    if (new_state.by_entity_ref.hasOwnProperty(task.entity)) {
        new_state.by_entity_ref[task.entity] = new_state.by_entity_ref[
            task.entity
        ].filter(existing_task => existing_task.id !== task.id);
    }
    return new_state;
}

export default function taskReducer(state = default_state, action) {
    switch (action.type) {
        case TASKS_STATE_CLEAR: {
            return Object.assign({}, default_state);
        }
        case TASKS_STATE_ASSIGN: {
            return Object.assign({}, state, action.payload);
        }
        case TASKS_STATE_SET_TASK: {
            return setTaskItem(state, action.payload);
        }
        case TASKS_STATE_SET_TASKS: {
            let tasks = action.payload;
            let new_state = Object.assign({}, state);
            tasks.forEach(task => {
                new_state = setTaskItem(new_state, task);
            });
            return new_state;
        }
        case TASKS_STATE_DELETE_TASKS: {
            let tasks = action.payload;
            let new_state = Object.assign({}, state);
            tasks.forEach(task => {
                new_state = deleteTaskItem(new_state, task);
            });
            return new_state;
        }
        default: {
            return state;
        }
    }
}
