import * as types from './order_reducer';

export function setOrder(state, order) {
    return {
        type: types.ORDER_STATE_SET_ORDER,
        payload: order,
    };
}

export function setOrders(state, order_arr) {
    return {
        type: types.ORDER_STATE_SET_ORDERS,
        payload: order_arr,
    };
}

export function deleteOrder(state, order) {
    return {
        type: types.ORDER_STATE_DELETE_ORDER,
        payload: order,
    };
}

export function setFetched(state, value) {
    return {
        type: types.ORDER_STATE_ASSIGN,
        payload: {
            fetched: value,
        },
    };
}

export function setApprovedFetched(state, value) {
    return {
        type: types.ORDER_STATE_ASSIGN,
        payload: {
            approved_fetched: value,
        },
    };
}

export function clearState(state) {
    return {
        type: types.ORDER_STATE_CLEAR,
        payload: {},
    };
}
