import withStore from 'with-store';
import oh from 'output-helpers';

export function pack(msg) {
    return msg;
}

export function unpack(msg_from_server) {
    return msg_from_server;
}

export function resolve(msg) {
    let user = withStore.extensions.getUser(msg.user_id);
    return {
        ...msg.data,
        from: user,
        time: oh.formatDateAsString(msg.time, 'YYYY-MM-DD HH:mm'),
    };
}
