import React from 'react';
import oh from 'output-helpers';
import userInputs from 'user-inputs';
import withStore from 'with-store';
import styled from 'styled-components';
import Button from './Button';
import InformationList from './InformationList';
import api_helpers from '../../helpers/api_helpers';
import Notifications from '../../notifications';

class EntityPropertyList extends React.Component {
    constructor(props) {
        super(props);

        this.language = window.sso.getJWT().getClaim('lang');

        if (!props.schema) {
            throw new Error('EntityPropertyList requires schema property.');
        }
        if (!props.entity) {
            throw new Error('EntityPropertyList requires entity property.');
        }

        this.state = {
            entity_changeset: Object.assign({}, props.entity.data),
            editing: false,
        };

        this.container = styled.div`
            .view_actions {
                padding: 12px;
                button {
                    margin-top: 12px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        `;
    }

    componentDidUpdate(prev_props) {
        if (prev_props.entity.id !== this.props.entity.id) {
            this.setState({
                entity_changeset: Object.assign({}, this.props.entity.data),
                editing: false,
            });
        } else if (
            prev_props.entity.last_modified !== this.props.entity.last_modified
        ) {
            this.setState({
                entity_changeset: Object.assign({}, this.props.entity.data),
                editing: false,
            });
        }
    }

    componentDidMount() {
        let checkEntity = event => {
            if (this.state.editing === false) {
                return;
            }
            let selected_entity = this.props.entity;

            if (selected_entity.data['meta.id'] === event.data.id) {
                if (this.props.userPrompt.isOpen()) {
                    this.props.userPrompt.cancel();
                }

                this.props.userPrompt.alert({
                    props: {
                        title: oh.translate('concurrent_modification'),
                        message: oh.translate('concurrent_modification_desc'),
                    },
                });
            }
        };

        Notifications.addEventInspector(this, 'entity_updated', checkEntity);
    }

    componentWillUnmount() {
        Notifications.clearEventInspectors(this);
        this.props.se.setSelectedTask(null);
    }

    triggerPromptUser(prop) {
        let inputs = [];
        if (prop.type === 'string') {
            inputs.push({
                type: 'text',
                key: 'new_value',
                default_value:
                    this.state.entity_changeset.hasOwnProperty(prop.key) &&
                    this.state.entity_changeset[prop.key] !== null
                        ? this.state.entity_changeset[prop.key]
                        : '',
            });
        } else if (prop.type === 'number') {
            inputs.push({
                type: 'text',
                key: 'new_value',
                default_value:
                    this.state.entity_changeset.hasOwnProperty(prop.key) &&
                    this.state.entity_changeset[prop.key] !== null
                        ? this.state.entity_changeset[prop.key].toString()
                        : '',
            });
        } else if (prop.type === 'entity') {
            inputs.push({
                type: 'text',
                key: 'new_value',
                barcode: true,
                default_value:
                    this.state.entity_changeset.hasOwnProperty(prop.key) &&
                    this.state.entity_changeset[prop.key] !== null
                        ? decodeURIComponent(
                              this.state.entity_changeset[prop.key]
                                  .split('/')
                                  .pop(),
                          )
                        : '',
            });
        } else if (prop.type === 'boolean') {
            inputs.push({
                type: 'tri_state',
                key: 'new_value',
                options: [
                    { value: false, label: oh.translate('no') },
                    { value: null, label: oh.translate('unknown') },
                    { value: true, label: oh.translate('yes') },
                ],
                default_value: this.state.entity_changeset.hasOwnProperty(
                    prop.key,
                )
                    ? this.state.entity_changeset[prop.key]
                    : null,
            });
        } else if (prop.type === 'enum') {
            inputs.push({
                type: 'select',
                key: 'new_value',
                options: prop.alternatives.map(opt => {
                    return { value: opt, label: opt };
                }),
                default_value:
                    this.state.entity_changeset.hasOwnProperty(prop.key) &&
                    prop.alternatives.indexOf(
                        this.state.entity_changeset[prop.key],
                    ) !== -1
                        ? this.state.entity_changeset[prop.key]
                        : prop.alternatives[0],
            });
        } else {
            throw new Error(
                'Unable to render property of unsupported type: ' + prop.type,
            );
        }
        this.props.userPrompt.confirm(
            {
                props: {
                    title: prop.name,
                },
                inputs: inputs,
            },
            values => {
                let update = {};
                if (prop.type === 'entity') {
                    update[prop.key] =
                        'entity://misc/' + encodeURIComponent(values.new_value);
                } else if (prop.type === 'number') {
                    update[prop.key] = parseInt(values.new_value, 10);
                } else {
                    update[prop.key] = values.new_value;
                }
                this.setState({
                    entity_changeset: Object.assign(
                        {},
                        this.state.entity_changeset,
                        update,
                    ),
                });
            },
        );
        this.props.userPrompt.setTag('entity');
    }

    renderButtons() {
        if (this.state.editing) {
            return (
                <div>
                    <Button
                        filled
                        block
                        green
                        label={oh.translate('save')}
                        onClick={() => {
                            this.props.actions.spinner.add();
                            Promise.resolve()
                                .then(() => {
                                    let selected_entity = this.props.entity;
                                    let altered_entity = Object.assign(
                                        {},
                                        selected_entity,
                                    );
                                    altered_entity.data = Object.assign(
                                        {},
                                        altered_entity.data,
                                        this.state.entity_changeset,
                                    );

                                    return api_helpers
                                        .updateEntity(
                                            'vehicles',
                                            altered_entity,
                                        )
                                        .then(updated_entity => {
                                            this.props.actions.entity.setEntity(
                                                'vehicles',
                                                updated_entity,
                                            );
                                            return updated_entity;
                                        });
                                })
                                .then(() => {
                                    let selected_task = this.props.se.getSelectedTask();
                                    if (!selected_task) {
                                        return;
                                    }
                                    let assignee_id = this.props.store.users
                                        .my_id;
                                    let altered_task = Object.assign(
                                        {},
                                        selected_task,
                                        {
                                            assignee_id: assignee_id,
                                            completed: true,
                                        },
                                    );
                                    return api_helpers
                                        .updateTask(altered_task)
                                        .then(updated_task => {
                                            this.props.actions.tasks.setTask(
                                                updated_task,
                                            );
                                        });
                                })
                                .finally(() => {
                                    this.setState({ editing: false });
                                    this.props.actions.spinner.remove();
                                });
                        }}
                    />
                    <Button
                        filled
                        block
                        red
                        label={oh.translate('cancel')}
                        onClick={() => {
                            this.setState({
                                editing: false,
                                entity_changeset: Object.assign(
                                    {},
                                    this.props.entity.data,
                                ),
                            });
                        }}
                    />
                </div>
            );
        }
        return (
            <div>
                <Button
                    filled
                    block
                    dark
                    label={oh.translate('edit')}
                    onClick={() => {
                        this.setState({ editing: true });
                    }}
                />
            </div>
        );
    }

    render() {
        let information_items = this.props.schema.properties
            .filter(prop => {
                if (prop.key === 'meta.photos') {
                    return false;
                }
                return true;
            })
            .map(prop => {
                let value =
                    !this.state.entity_changeset.hasOwnProperty(prop.key) ||
                    this.state.entity_changeset[prop.key] === null
                        ? null
                        : this.state.entity_changeset[prop.key];
                if (value !== null) {
                    if (prop.type === 'entity') {
                        value = decodeURIComponent(value.split('/').pop());
                    } else if (prop.type === 'boolean') {
                        if (typeof value === 'boolean') {
                            value = value
                                ? oh.translate('yes')
                                : oh.translate('no');
                        } else {
                            throw new Error(
                                'Invalid value type for boolean property.',
                            );
                        }
                    }
                }

                return {
                    label:
                        prop.name ||
                        this.props.schema.translations[this.language][prop.key],
                    modifiable: prop.modifiable,
                    data: value,
                    onClick: () => {
                        if (
                            this.state.editing === true &&
                            prop.modifiable === true
                        ) {
                            this.triggerPromptUser(prop);
                        }
                        if (this.state.editing === false) {
                            this.props.userPrompt.alert({
                                props: {
                                    title: oh.translate(
                                        'you_are_not_in_edit_mode',
                                    ),
                                    message: oh.translate('press_edit'),
                                },
                            });
                        }
                        if (
                            this.state.editing === true &&
                            (!prop.modifiable || prop.modifiable === false)
                        ) {
                            this.props.userPrompt.alert({
                                props: {
                                    title: oh.translate(
                                        'this_property_is_not_modifiable',
                                    ),
                                    message: oh.translate(
                                        'contact_admin_if_it_is_incorrect',
                                    ),
                                },
                            });
                        }
                    },
                };
            });
        return (
            <this.container>
                <div className="view_actions">{this.renderButtons()}</div>
                <InformationList items={information_items} bigLabel />
            </this.container>
        );
    }
}

export default withStore(userInputs(EntityPropertyList));
