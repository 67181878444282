import withStore from 'with-store';
import Notifications from '../notifications';

import * as entity_helpers from '../helpers/entity_helpers';
import * as order_helpers from '../helpers/order_helpers';
import * as task_helpers from '../helpers/task_helpers';
import api_helpers from '../helpers/api_helpers';

export function clearAllListeners() {
    console.log('notification_helpers.clearAllListeners');
    // NOTE: clears ALL event listeners, not just the ones registered in this file.
    // None else is supposed to register listeners, though, so that shouldn't be a problem.
    Notifications.clearEventListeners();
}

export function registerAllListeners() {
    console.log('notification_helpers.registerAllListeners');
    // internals
    Notifications.addEventListener(this, 'connected', () => {
        console.log('Notifications connected');

        // Reload all data
        api_helpers.resumeRequests();
        Notifications.unpause();

        const selected_order = withStore.extensions.getSelectedOrder();
        if (selected_order) {
            withStore.extensions.fetchTasksFromOrderId(selected_order.id);
        }

        let entity_collections = withStore.getState().config.managed_entities;
        entity_collections.forEach(collection => {
            api_helpers.fetchEntitySchema(collection).then(schema => {
                withStore.actions.entity.setSchema(collection, schema);
                return api_helpers.fetchEntities(collection).then(entities => {
                    withStore.actions.entity.setEntities(collection, entities);
                    withStore.actions.entity.setEntitiesFetched(
                        collection,
                        true,
                    );
                });
            });
        });

        let users = withStore.getState().users;
        if (users.fetched) {
            let my_org_id = users.by_id[users.my_id].installation_org_id;
            api_helpers.fetchOrders(my_org_id).then(orders => {
                withStore.actions.order.setOrders(orders);
            });
        }

        withStore.actions.messages.clearMessages();

        /* -- skipping this to keep state at notification reconnection
        withStore.extensions.setSelectedOrder(null);
        withStore.extensions.setSelectedTask(null);
        withStore.extensions.clearLocalFetchingFlags();
        */
    });

    Notifications.addEventListener(this, 'disconnected', () => {
        console.log('Notifications disconnected');

        api_helpers.cancelAndPreventRequests();
    });

    // task-api
    Notifications.addEventListener(this, 'order_created', event => {
        let order = event.data;
        console.log("Ran event handler for 'order_created'");
        withStore.actions.order.setOrder(order_helpers.unpack(order));
    });
    Notifications.addEventListener(this, 'order_updated', event => {
        let order = event.data;
        withStore.actions.order.setOrder(order_helpers.unpack(order));
    });
    Notifications.addEventListener(this, 'order_deleted', event => {
        let order = event.data;
        withStore.actions.order.deleteOrder(order_helpers.unpack(order));
    });

    Notifications.addEventListener(this, 'task_created', event => {
        let task = event.data;
        withStore.actions.tasks.setTask(task_helpers.unpack(task));
        order_helpers.loadOrder(task.order_id);
    });
    Notifications.addEventListener(this, 'tasks_created', event => {
        let tasks = event.data;
        withStore.actions.tasks.setTasks(tasks.map(task_helpers.unpack));
        order_helpers.loadOrder(tasks[0].order_id);
    });
    Notifications.addEventListener(this, 'task_updated', event => {
        let task = event.data;
        withStore.actions.tasks.setTask(task_helpers.unpack(task));
    });
    Notifications.addEventListener(this, 'tasks_updated', event => {
        let tasks = event.data;
        withStore.actions.tasks.setTasks(tasks.map(task_helpers.unpack));
    });
    Notifications.addEventListener(this, 'task_deleted', event => {
        let task = event.data;
        withStore.actions.tasks.deleteTasks([task_helpers.unpack(task)]);
    });
    Notifications.addEventListener(this, 'tasks_deleted', event => {
        let tasks = event.data;
        withStore.actions.tasks.deleteTasks(tasks.map(task_helpers.unpack));
    });

    Notifications.addEventListener(this, 'tag_created', event => {
        let tag = event.data;
        withStore.actions.tags.addTag(tag);
    });

    // entity-api
    Notifications.addEventListener(this, 'entity_created', event => {
        let collection = event.collection;
        let entity = event.data;
        let schema = withStore.getState().entity.schema[collection];
        withStore.actions.entity.setEntity(
            collection,
            entity_helpers.unpack(collection, schema, entity),
        );
    });
    Notifications.addEventListener(this, 'entity_updated', event => {
        let collection = event.collection;
        let entity = event.data;
        let schema = withStore.getState().entity.schema[collection];
        withStore.actions.entity.setEntity(
            collection,
            entity_helpers.unpack(collection, schema, entity),
        );
    });
    Notifications.addEventListener(this, 'entity_deleted', event => {
        //let collection = event.collection;
        //let entity = event.data;
        // Not possible to delete entities, but if it were: action(s) for delete entity (and all tasks referencing it ???)
    });

    // message-api
    Notifications.addEventListener(this, 'message_created', event => {
        let message = event.data;
        withStore.actions.messages.setMessage(message);
    });
}
