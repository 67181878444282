import React from 'react';
import styled from 'styled-components';
//import oh from 'output-helpers';

class Tag extends React.Component {

    constructor(props) {
        super(props);
        this.container = styled.div `
            color: #fff;
            display: inline-block;
            background-color: #2196f3;
            white-space: nowrap;
            align-items: center;
            border-radius: 16px;
            vertical-align: middle;
            justify-content: center;
            margin-right: 8px;
            margin-bottom: 4px;
            &:last-child{
                margin-right: 0;
            }
            span{
                outline: none;
                padding: 4px 8px;
                display: inline-flex;
                font-size: 0.8125rem;
            }
        `;
    }

    render() {
        return (
            <this.container >
                <span>
                    {this.props.label}
                </span>
            </this.container>
        );
    }
}

export default Tag;
