import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withRouter } from 'react-router';
import withStore from 'with-store';
import oh from 'output-helpers';
import userInputs from 'user-inputs';
import Button from '../base/Button';
import ImagesHandling from './ImagesHandling';
import api_helpers from '../../helpers/api_helpers';

import StyledNavbar from '../base/StyledNavbar';
import EntityPropertyList from '../base/EntityPropertyList';
import EntityTaskList from '../base/EntityTaskList';
import Notifications from '../../notifications';
import { EntityViewTab } from '../../defs/entity_view_defs';
import Gallery from '../base/Gallery';
import LocalSpinner from '../base/LocalSpinner';

class EntityView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open_filter_entities: false,
            images_open: false,
            photos_fetched: false,
            fetching: false,
            delete_mode: false,
        };

        const anim_slide_in = keyframes`
            0% {
                transform: translateX(100%);
            }
            100% {
                transform: 0;
            }
        `;

        this.container = styled.div`
            animation: ${anim_slide_in} 0.5s;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${props => props.theme.colors.background};
            span.link {
                margin: 2px;
            }
            .order_body{
                top: 128px;//${props =>
                    props.theme.sizes.sub_navbar.extended_height + 38}px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: auto;
                &.padding_top{
                    padding-top: 20px;
                }
                &.padding_bottom{
                    padding-bottom: 80px;
                }
            }
            .entity_camera_button {
                padding: 12px;
            }
            .gallery {
                background-color: ${props => props.theme.colors.background};
            }
            .entity_how_to_remove_photo {
                margin: 10px 0 2px;
            }
        `;

        this.stateItem = styled.div`
            color: #fff;//${props =>
                props.theme.colors.states[props.state]
                    ? props.theme.colors.states[props.state]
                    : 'gray'};
            font-weight: bold;
            text-align: center;
            margin: 20px 12px;
            border-radius: 50px;
            padding: 12px;
            font-size: 20px;
            background-color: ${props =>
                props.theme.colors.states[props.state]
                    ? props.theme.colors.states[props.state]
                    : 'gray'};
        `;
    }

    componentDidMount() {
        let closePromptAndWarn = () => {
            if (this.props.userPrompt.isOpen()) {
                this.props.userPrompt.cancel();
            }
            this.props.userPrompt.alert({
                props: {
                    title: oh.translate('concurrent_modification'),
                    message: oh.translate('concurrent_modification_desc'),
                },
            });
        };

        let checkOrder = event => {
            let selected_order = this.props.se.getSelectedOrder();
            if (selected_order && this.props.userPrompt.isOpen()) {
                let prompt_tag = this.props.userPrompt.getTag();
                if (prompt_tag === 'task') {
                    if (selected_order.id === event.data.id) {
                        closePromptAndWarn();
                    }
                }
            }
        };

        let checkTask = event => {
            let selected_order = this.props.se.getSelectedOrder();
            if (selected_order && this.props.userPrompt.isOpen()) {
                let prompt_tag = this.props.userPrompt.getTag();
                if (prompt_tag === 'task') {
                    let problem = false;
                    if (/^tasks_/.test(event.type)) {
                        problem = event.data.some(
                            task => task.order_id === selected_order.id,
                        );
                    } else if (/^task_/.test(event.type)) {
                        problem = event.data.order_id === selected_order.id;
                    }
                    if (problem) {
                        closePromptAndWarn();
                    }
                }
            }
        };

        Notifications.addEventInspector(this, 'order_updated', event => {
            checkOrder(event);
        });
        Notifications.addEventInspector(this, 'order_deleted', event => {
            checkOrder(event);
        });
        Notifications.addEventInspector(this, 'task_updated', event => {
            checkTask(event);
        });
        Notifications.addEventInspector(this, 'tasks_updated', event => {
            checkTask(event);
        });
        Notifications.addEventInspector(this, 'task_deleted', event => {
            checkTask(event);
        });
        Notifications.addEventInspector(this, 'tasks_deleted', event => {
            checkTask(event);
        });
        Notifications.addEventInspector(this, 'entity_deleted', event => {
            if (event.data.id === this.props.se.getSelectedEntity().id) {
                this.props.se.setSelectedEntity(null);
                if (this.props.userPrompt.isOpen()) {
                    this.props.userPrompt.cancel();
                }
                this.props.userPrompt.alert({
                    props: {
                        title: oh.translate('concurrent_modification'),
                        message: oh.translate('concurrent_modification_desc'),
                    },
                });
            }
        });
    }

    componentDidUpdate(prev_props) {
        if (
            prev_props.store.interaction.selected_entity_id !==
                this.props.store.interaction.selected_entity_id &&
            this.props.store.interaction.selected_task_id
        ) {
            this.props.actions.interaction.set({
                entity_tab: EntityViewTab.INVENTORY,
            });
        }
    }

    componentWillUnmount() {
        Notifications.clearEventInspectors(this);
    }

    renderEntityPropertyList(entity) {
        if (
            this.props.store.interaction.entity_tab !== EntityViewTab.INVENTORY
        ) {
            return null;
        }

        let schema = this.props.store.entity.schema[entity.collection];

        return <EntityPropertyList schema={schema} entity={entity} />;
    }

    renderEntityOrders(entity) {
        if (this.props.store.interaction.entity_tab !== EntityViewTab.TASKS) {
            return null;
        }

        return (
            <EntityTaskList
                setEntityViewTab={tab => {
                    this.props.actions.interaction.set({
                        entity_tab: tab,
                    });
                }}
                entity={entity}
            />
        );
    }
    renderPhotoTab(selected_entity) {
        const photos = Object.values(withStore.getState().photos.by_id).filter(
            photo => {
                const photos = selected_entity.data['meta.photos'];
                if (photos !== '') {
                    const array = JSON.parse(photos);
                    return array.includes(photo.id);
                }
                return false;
            },
        );
        if (!this.state.photos_fetched && !this.state.fetching) {
            this.setState({
                fetching: true,
            });
            if (photos.length === 0) {
                api_helpers
                    .fetchPhotos(selected_entity)
                    .then(fetched_photos => {
                        this.setState({
                            photos_fetched: true,
                            fetching: false,
                        });
                        fetched_photos.forEach(photo =>
                            withStore.actions.photos.addPhoto(photo),
                        );
                    });
            } else {
                this.setState({
                    photos_fetched: true,
                    fetching: false,
                });
            }
        }
        return (
            <div>
                <div className="entity_camera_button">
                    {this.state.delete_mode === true ? (
                        <div>
                            <Button
                                filled
                                block
                                green
                                rounded={true}
                                label={oh.translate('back')}
                                onClick={() => {
                                    this.setState({
                                        delete_mode: !this.state.delete_mode,
                                    });
                                }}
                            />
                            <div className="entity_how_to_remove_photo">
                                {oh.translate('how_to_remove_photo')}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Button
                                filled
                                block
                                dark
                                rounded={true}
                                label={oh.translate('add_images')}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        images_open: true,
                                    });
                                }}
                            />
                            <br />
                            <Button
                                filled
                                block
                                red
                                rounded={true}
                                label={oh.translate('remove_image')}
                                onClick={() => {
                                    this.setState({
                                        delete_mode: !this.state.delete_mode,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="gallery">
                    {photos && this.state.photos_fetched ? (
                        <Gallery
                            images={photos}
                            delete_mode={this.state.delete_mode}
                            onDelete={
                                this.state.delete_mode
                                    ? this.deletePhoto.bind(this)
                                    : () => {}
                            }
                        />
                    ) : (
                        <LocalSpinner />
                    )}
                </div>
            </div>
        );
    }
    deletePhoto(photo_id) {
        let inputs = [
            {
                key: 'ok',
                default_value: 'ok',
                type: 'button',
                label: 'Ok',
                props: {
                    onClick: () => {
                        this.setState({
                            photos_fetched: false,
                            fetching: true,
                        });
                        this.props.userPrompt.cancel();
                        const this_entity = this.props.se.getSelectedEntity();
                        const updated_entity = { ...this_entity };
                        const photos = JSON.parse(
                            updated_entity.data['meta.photos'],
                        );
                        updated_entity.data['meta.photos'] = JSON.stringify(
                            photos.filter(photo => photo !== photo_id),
                        );
                        return api_helpers
                            .updateEntity('vehicles', updated_entity)
                            .then(updated_entity => {
                                withStore.actions.entity.setEntity(
                                    'vehicles',
                                    updated_entity,
                                );
                                return null;
                            })
                            .catch(err => {
                                console.error(err);
                            })
                            .finally(() => {
                                this.setState({
                                    photos_fetched: true,
                                    fetching: false,
                                });
                            });
                    },
                    dark: true,
                    filled: true,
                    block: true,
                },
            },
            {
                key: 'cancel',
                default_value: 'cancel',
                type: 'button',
                label: oh.translate('cancel'),
                props: {
                    onClick: () => {
                        this.props.userPrompt.cancel();
                    },
                    red: true,
                    filled: true,
                    block: true,
                },
            },
        ];

        this.props.userPrompt.confirm({
            props: {
                title: oh.translate('do_you_want_to_delete_this_picture'),
                show_cancel_btn: false,
                show_confirm_btn: false,
            },
            inputs: inputs,
        });
    }

    addPhoto(data, mime_type) {
        let selected_entity = this.props.se.getSelectedEntity();

        this.setState({
            photos_fetched: false,
            fetching: true,
        });

        api_helpers
            .uploadPhotoFiles(data, mime_type)
            .then(photo_id => {
                const photo = {
                    id: photo_id,
                    type: mime_type,
                    data: Uint8Array.from(atob(data), c => c.charCodeAt(0)),
                };

                const photos = selected_entity.data['meta.photos'];
                const photo_ids = photos !== '' ? JSON.parse(photos) : [];
                if (photo_ids.indexOf(photo_id) < 0) {
                    photo_ids.push(photo_id);
                    const copy_entity = {
                        ...selected_entity,
                    };
                    copy_entity.data['meta.photos'] = JSON.stringify(photo_ids);
                    return api_helpers
                        .updateEntity('vehicles', copy_entity)
                        .then(updated_entity => {
                            withStore.actions.entity.setEntity(
                                'vehicles',
                                updated_entity,
                            );
                            withStore.actions.photos.addPhoto(photo);
                        });
                }
                throw new Error('image already exists on entity');
            })
            .catch(e => console.error(e.message))
            .finally(() =>
                this.setState({
                    images_open: false,
                    photos_fetched: true,
                    fetching: false,
                }),
            );
    }

    closeView() {
        this.props.se.setSelectedEntity(null);
        this.props.se.setSelectedTask(null);
        this.setState({
            ...this.state,
            photos_fetched: false,
            photos: null,
            delete_mode: false,
        });
        this.props.actions.interaction.set({
            entity_tab: EntityViewTab.INVENTORY,
        });
    }

    render() {
        let selected_entity = this.props.se.getSelectedEntity();

        if (selected_entity === null) {
            return null;
        }

        const navbar_categories = [
            {
                name: 'inventory',
                icon: 'information',
                onClick: () => {
                    this.props.actions.interaction.set({
                        entity_tab: EntityViewTab.INVENTORY,
                    });
                },
            },
            {
                name: 'photos',
                icon: 'photos',
                onClick: () => {
                    this.props.actions.interaction.set({
                        entity_tab: EntityViewTab.PHOTOS,
                    });
                },
            },
            {
                name: 'tasks',
                icon: 'work',
                onClick: () => {
                    this.props.actions.interaction.set({
                        entity_tab: EntityViewTab.TASKS,
                    });
                },
            },
        ];
        return (
            <this.container>
                {!this.state.images_open && (
                    <React.Fragment>
                        <StyledNavbar
                            bigTitle={selected_entity.data['meta.id']}
                            navigation={{
                                onClick: this.closeView.bind(this),
                            }}
                            selected_category={
                                this.props.store.interaction.entity_tab
                            }
                            categories={navbar_categories}
                        />
                        {this.renderEntityPropertyList(selected_entity)}
                        {this.renderEntityOrders(selected_entity)}
                        {this.props.store.interaction.entity_tab ===
                            EntityViewTab.PHOTOS &&
                            this.renderPhotoTab(selected_entity)}
                    </React.Fragment>
                )}

                {this.state.images_open ? (
                    <ImagesHandling
                        onTakePhoto={this.addPhoto.bind(this)}
                        onClose={() => {
                            this.setState({
                                images_open: false,
                            });
                        }}
                    />
                ) : null}
            </this.container>
        );
    }
}

export default withRouter(withStore(userInputs(EntityView)));
