import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import withConfig from 'with-config';
import ER from 'error-reporting';
import oh from 'output-helpers';
import withStore from 'with-store';
import withProfile from 'with-profile';
import userInputs from 'user-inputs';

import default_config from './default_config';
import translations from './translations';
import states from './store/states';
import store_extensions from './store/general_extensions';
import * as profile_helpers from './helpers/profile_helpers';

import RequiredDataCheck from './components/RequiredDataCheck';
import LoginCheck from './components/LoginCheck';
import MainView from './components/views/MainView';
import OrderView from './components/views/OrderView';
import EntityView from './components/views/EntityView';
import StyledBase from './components/base/StyledBase';
import Spinner from './components/base/Spinner';

import TextareaInput from './components/TextareaInput';
import MultiSelectInput from './components/MultiSelectInput';
import SelectInput from './components/SelectInput';
import TriStateInput from './components/TriStateInput';

withStore.addStates(states);
withStore.setCollectStats(true);
let extensions = Object.assign(
    {},
    store_extensions,
    profile_helpers.extensions,
);
withStore.addExtensions(extensions);

window.store = withStore;

userInputs.setCustomComponents({
    multi_select: MultiSelectInput,
    select: SelectInput,
    textarea: TextareaInput,
    tri_state: TriStateInput,
});

oh.setConfig({
    lang: default_config.default_language,
});
oh.addDictionary(translations);
withConfig.addStore(withStore);
withConfig.setDefault(default_config);
withConfig.fetch().then(config => {
    let error_report_config = {
        header: 'INSTALLATION USER: ',
        slack_webhook: config.slack.webhook_uri,
        disable_slack_posting:
            !config.send_errors_to_slack_in_dev && !config.production,
    };

    ER.setConfig(error_report_config);
    withProfile.config(
        {
            url: config.api.profile.api_uri,
            token: () => {
                return window.sso.getJWT().getRaw();
            },
            store: withStore,
        },
        profile_helpers.default_profile,
    );
});

let LoginCheckViewWithER = ER.withErrorReporting(
    LoginCheck,
    ER.ErrorAlert,
    () => {
        let prefix = 'User not logged in.';

        if (window.sso && window.sso.isLoggedIn && window.sso.isLoggedIn()) {
            let token = window.sso.getJWT();
            let token_issued = new Date(token.getClaim('iat') * 1000);
            prefix =
                'User ' +
                token.getClaim('uid') +
                '/' +
                token.getClaim('username') +
                ' iat@' +
                token_issued.toISOString();
        }

        let status = ER.getStatus();
        let err = ER.getError();
        let label = oh.translate('sending');
        let body = [];
        if (err.display_text) {
            body.concat(err.display_text);
        }
        if (status === 'sent') {
            label = oh.translate('sent');
            body.concat(oh.translate('error_report_sent'));
        } else if (status === 'failed') {
            label = oh.translate('failed');
            body = [
                oh.translate('error_report_failed'),
                oh.translate('please_contact_us'),
            ];
        } else {
            body.concat(oh.translate('sending_error_report'));
        }

        return {
            prefix: prefix,
            custom_error_props: {
                title: oh.translate('error_has_occured'),
                label: label,
                body: body,
                action_label: err.action_label || oh.translate('go_to_start'),
                actionCB: () => {
                    err.actionCB ? err.actionCB() : (window.location = '/');
                },
            },
        };
    },
);

LoginCheckViewWithER = withConfig(LoginCheckViewWithER);
let RequiredDataCheckWithProfile = withProfile(RequiredDataCheck);
ReactDOM.render(
    <Router>
        <StyledBase>
            <LoginCheckViewWithER>
                <RequiredDataCheckWithProfile>
                    <div>
                        <OrderView />
                        <EntityView />
                        <Route exact path="/" component={MainView} />
                    </div>
                </RequiredDataCheckWithProfile>
                <Spinner />
            </LoginCheckViewWithER>
        </StyledBase>
    </Router>,
    document.getElementById('root'),
);
