import React from 'react';
import { ThemeProvider, injectGlobal } from 'styled-components';
import * as themes from '../../themes/allbinary.js';

class StyledBase extends React.Component {

    constructor(props) {
        super(props);

        this.theme = themes.AllbinaryTheme;

        injectGlobal`
            ${themes.reset_css.reset}
        `;
    }


    render() {
        return (
            <ThemeProvider theme={this.theme}>
                {this.props.children}
            </ThemeProvider>
        );
    }
}

export default StyledBase;
