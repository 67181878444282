export function unpackSchema(schema) {
    const meta_props = schema.properties.filter(prop =>
        /^meta./.test(prop.key),
    );
    const other_props = schema.properties.filter(
        prop => !/^meta./.test(prop.key),
    );

    other_props.sort((a, b) => (a.name < b.name ? -1 : 1));

    schema = Object.assign({}, schema, {
        properties: [...meta_props, ...other_props],
    });

    schema.by_id = {};
    schema.properties.forEach(prop => {
        schema.by_id[prop.key] = prop;
    });
    return schema;
}
