import React from 'react';
import styled from 'styled-components';
import userInputs from 'user-inputs';
import withStore from 'with-store';
import oh from 'output-helpers';
import EntityListItem from './EntityListItem';
import Tag from './Tag';
import Notifications from '../../notifications';

class TaskCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

        this.container = styled.div `
            margin: 0 12px 12px;
            background-color: ${props => props.theme.colors.gray[1]};
            padding: 4px;
            border-radius: 4px;
            border: 1px solid ${props => props.theme.colors.border};
            .task_card_container{
                background-color:  #fff;
                color:  ${props => props.theme.colors.text};
                z-index: 1;
                border-radius: 4px;
            }
            .task_head{
                .task_title, .task_action{
                    display: inline-block;
                    vertical-align: middle;
                }
                .task_title{
                    padding: 8px 12px;
                    font-size: 22px;
                    font-weight: bold;
                    width: 85%;
                }
                .task_action{
                    width: 15%;
                    text-align: right;
                    padding: 8px 4px;
                    svg{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .task_tags{
                padding: 2px 12px;
            }
            .task_body{
                padding: 4px 12px 12px;
                .task_description{
                    white-space: pre-wrap;
                    font-size: 14px;
                }
            }
            .task_list{
                display: none;
                z-index: 0;
                &.expaned{
                    display: block;
                }
                ul.task_list_ul{
                    li{
                        margin: 4px 0;
                        background-color: #fff;
                        border: 1px solid ${props => props.theme.colors.border};
                        border-radius: 4px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }

        `;
    }

    componentDidMount() {
        let closePromptAndWarn = () => {
            this.props.se.setSelectedTask(null);
            if (this.props.userPrompt.isOpen()) {
                this.props.userPrompt.cancel();
            }
            this.props.userPrompt.alert({
                props: {
                    title: oh.translate("concurrent_modification"),
                    message: oh.translate("concurrent_modification_desc")
                }
            });
        };

        let checkOrder = (event) => {
            let selected_order = this.props.se.getSelectedOrder();
            if (selected_order && this.props.userPrompt.isOpen()) {
                let prompt_tag = this.props.userPrompt.getTag();
                if (prompt_tag === "task") {
                    if (selected_order.id === event.data.id) {
                        closePromptAndWarn();
                    }
                }
            }
        };

        let checkTask = (event) => {
            let selected_order = this.props.se.getSelectedOrder();
            if (selected_order && this.props.userPrompt.isOpen()) {
                let prompt_tag = this.props.userPrompt.getTag();
                if (prompt_tag === "task") {
                    let problem = false;
                    if (/^tasks_/.test(event.type)) {
                        problem = event.data.some(task => task.order_id === selected_order.id);
                    } else if (/^task_/.test(event.type)) {
                        problem = (event.data.order_id === selected_order.id);
                    }
                    if (problem) {
                        closePromptAndWarn();
                    }
                }
            }
        };

        Notifications.addEventInspector(this, "order_updated", (event) => {
            checkOrder(event);
        });
        Notifications.addEventInspector(this, "order_deleted", (event) => {
            checkOrder(event);
        });
        Notifications.addEventInspector(this, "task_updated", (event) => {
            checkTask(event);
        });
        Notifications.addEventInspector(this, "tasks_updated", (event) => {
            checkTask(event);
        });
        Notifications.addEventInspector(this, "task_deleted", (event) => {
            checkTask(event);
        });
        Notifications.addEventInspector(this, "tasks_deleted", (event) => {
            checkTask(event);
        });
    }

    componentWillUnmount() {
        Notifications.clearEventInspectors(this);
    }

    onClickEntityListItemYes(entity) {
        this.props.onClickEntity("yes", entity.task_id, entity.ref);
    }

    onClickEntityListItemNo(entity) {
        this.props.onClickEntity("no", entity.task_id, entity.ref);
    }

    onClickEntityListItemNote(entity) {
        this.props.onClickEntity("note", entity.task_id, entity.ref);
    }

    render() {
        return (
            <this.container>
                <div className="task_card_container">
                    <div className="task_head">
                        <p className="task_title">
                            {oh.translate(this.props.task.type)}
                        </p>
                    </div>
                    <div className="task_tags">
                        {
                            this.props.task.tags.map((item, i) => {
                                return (<Tag key={i} label={item.name}/>);
                             })
                        }
                    </div>
                    <div className="task_body">
                        <p className="task_description">
                            {this.props.task.data.description}
                        </p>
                    </div>
                </div>
                <div className={`task_list ${this.state.expanded ? 'expaned' : 'expaned'}`}>
                    <ul className="task_list_ul">
                        {
                            this.props.entities.map((entity, y) => {
                                return (
                                    <EntityListItem
                                        onClickItemYes={() => {
                                            this.onClickEntityListItemYes(entity);
                                        }}
                                        onClickItemNo={() => {
                                            this.onClickEntityListItemNo(entity);
                                        }}
                                        onClickNote={() => {
                                            this.onClickEntityListItemNote(entity);
                                        }}
                                        onClickItem={() => {
                                            if (this.props.onClickEntityName) {
                                                this.props.onClickEntityName(entity.ref);
                                            }
                                        }}
                                        key={y}
                                        entity={entity}
                                    />
                                );
                            })
                        }
                    </ul>
                </div>
            </this.container>
        );
    }
}

export default withStore(userInputs(TaskCard));
