import * as store_helpers from './store_helpers';

export const ENTITY_STATE_ASSIGN = 'ENTITY_STATE_ASSIGN';
export const ENTITY_STATE_SET_ENTITY = 'ENTITY_STATE_SET_ENTITY';
export const ENTITY_STATE_SET_ENTITIES = 'ENTITY_STATE_SET_ENTITIES';
export const ENTITY_STATE_SET_SCHEMA = 'ENTITY_STATE_SET_SCHEMA';

let default_state = {
    fetched: {},
    all: [],
    by_id: {},
    by_collection: {},
    schema: {},
};

function sortEntities(a, b) {
    if (a.id < b.id) {
        return -1;
    }
    return 1;
}

export default function entityReducer(state = default_state, action) {
    switch (action.type) {
        case ENTITY_STATE_ASSIGN: {
            let updated_state = Object.assign({}, state, action.payload);
            updated_state.all.sort(sortEntities);
            return updated_state;
        }
        case ENTITY_STATE_SET_SCHEMA: {
            let schema = Object.assign({}, state.schema);
            schema[action.payload.collection] = action.payload.schema;
            return Object.assign({}, state, { schema: schema });
        }
        case ENTITY_STATE_SET_ENTITY: {
            let updated_state = store_helpers.SetItem(
                action.payload.entity,
                state,
            );
            let collection = action.payload.collection;
            if (
                updated_state.by_collection.hasOwnProperty(collection) === false
            ) {
                updated_state.by_collection[collection] = {};
            }
            updated_state.by_collection[collection][action.payload.entity.id] =
                action.payload.entity;
            updated_state.all.sort(sortEntities);
            return updated_state;
        }
        case ENTITY_STATE_SET_ENTITIES: {
            let updated_state = store_helpers.SetItems(
                action.payload.entities,
                state,
            );
            let collection = action.payload.collection;
            action.payload.entities.forEach(entity => {
                if (
                    updated_state.by_collection.hasOwnProperty(collection) ===
                    false
                ) {
                    updated_state.by_collection[collection] = {};
                }
                updated_state.by_collection[collection][entity.id] = entity;
            });
            updated_state.all.sort(sortEntities);
            return updated_state;
        }
        default: {
            return state;
        }
    }
}
