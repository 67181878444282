import React from 'react';
import withStore from 'with-store';
import { withRouter } from 'react-router';

import oh from 'output-helpers';
import api_helpers from '../helpers/api_helpers';
import Notifications from '../notifications';
import LocalSpinner from './base/LocalSpinner';

class RequiredDataCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requirements_met: false,
            users_fetch_initiated: false,
            orders_fetch_initiated: false,
            tags_fetch_initiated: false,
            entities_fetch_initiated: {},
            entities_schema_fetch_initiated: {},
        };
    }

    static getDerivedStateFromProps(next_props, current_state) {
        if (next_props.store.interaction.notifications_ready !== true) {
            return Object.assign({}, current_state);
        }

        let next_state = Object.assign({}, current_state, {
            requirements_met: true,
        });

        let entity_collections_to_fetch = withStore.getState().config
            .managed_entities;

        entity_collections_to_fetch.forEach(collection => {
            if (
                next_props.store.entity.schema.hasOwnProperty(collection) ===
                false
            ) {
                next_state.requirements_met = false;
                if (
                    next_state.entities_schema_fetch_initiated[collection] !==
                    true
                ) {
                    next_state.entities_schema_fetch_initiated[
                        collection
                    ] = true;
                    api_helpers.fetchEntitySchema(collection).then(schema => {
                        withStore.actions.entity.setSchema(collection, schema);
                    });
                }
            } else {
                next_state.entities_schema_fetch_initiated[collection] = false;

                if (next_props.store.entity.fetched[collection] !== true) {
                    next_state.requirements_met = false;
                    if (
                        next_state.entities_fetch_initiated[collection] !== true
                    ) {
                        next_state.entities_fetch_initiated[collection] = true;
                        api_helpers.fetchEntities(collection).then(entities => {
                            withStore.actions.entity.setEntities(
                                collection,
                                entities,
                            );
                            withStore.actions.entity.setEntitiesFetched(
                                collection,
                                true,
                            );
                        });
                    }
                } else {
                    next_state.entities_fetch_initiated[collection] = false;
                }
            }
        });

        if (next_props.store.users.fetched === false) {
            next_state.requirements_met = false;
            if (next_state.users_fetch_initiated === false) {
                next_state.users_fetch_initiated = true;
                api_helpers.fetchUsersAndOrgs().then(users_and_orgs => {
                    withStore.actions.users.setUsersState(
                        users_and_orgs.users_by_id,
                        users_and_orgs.orgs_by_id,
                    );
                    withStore.actions.users.setFetched(true);
                });
            }
        } else {
            next_state.users_fetch_initiated = false;

            if (next_props.store.order.fetched === false) {
                next_state.requirements_met = false;
                if (next_state.orders_fetch_initiated === false) {
                    next_state.orders_fetch_initiated = true;
                    let users = withStore.getState().users;
                    let my_org_id =
                        users.by_id[users.my_id].installation_org_id;
                    api_helpers.fetchOrders(my_org_id).then(orders => {
                        withStore.actions.order.setOrders(orders);
                        withStore.actions.order.setFetched(true);
                    });
                }
            } else {
                next_state.orders_fetch_initiated = false;
            }
        }

        if (next_props.store.tags.fetched === false) {
            next_state.requirements_met = false;
            if (next_state.tags_fetch_initiated === false) {
                next_state.tags_fetch_initiated = true;
                api_helpers.fetchTags().then(tags => {
                    withStore.actions.tags.setTags(tags);
                    withStore.actions.tags.setFetched(true);
                });
            }
        } else {
            next_state.tags_fetch_initiated = false;
        }

        if (
            current_state.requirements_met === false &&
            next_state.requirements_met === true
        ) {
            Notifications.unpause();
        }
        return next_state;
    }

    render() {
        if (this.state.requirements_met === false) {
            return <LocalSpinner message={oh.translate('loading')} />;
        } else if (this.props.store.interaction.offline) {
            return <LocalSpinner message={oh.translate('offline_message')} />;
        }
        return <div>{this.props.children}</div>;
    }
}

export default withRouter(withStore(RequiredDataCheck));
