import * as types from './messages_reducer';

export function setMessage(state, message_data) {
    return {
        type: types.MESSAGES_STATE_SET_MESSAGE,
        payload: message_data,
    };
}

export function setMessages(state, messages) {
    return {
        type: types.MESSAGES_STATE_SET_MESSAGES,
        payload: messages,
    };
}

export function setCompleteMessagesFetched(state, ref) {
    let completed_flags = Object.assign({}, state.complete_messages_fetched);
    completed_flags[ref] = true;
    return {
        type: types.MESSAGES_STATE_ASSIGN,
        payload: {
            complete_messages_fetched: completed_flags,
        },
    };
}

export function clearMessages() {
    return {
        type: types.MESSAGES_CLEAR_MESSAGES,
    };
}
