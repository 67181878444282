import * as types from './tasks_reducer';

export function setTask(state, task) {
    return {
        type: types.TASKS_STATE_SET_TASK,
        payload: task,
    };
}

export function setTasks(state, task_arr) {
    return {
        type: types.TASKS_STATE_SET_TASKS,
        payload: task_arr,
    };
}

export function deleteTasks(state, task_arr) {
    return {
        type: types.TASKS_STATE_DELETE_TASKS,
        payload: task_arr,
    };
}

export function setFetched(state, value) {
    return {
        type: types.TASKS_STATE_ASSIGN,
        payload: {
            fetched: value,
        },
    };
}

export function setCompleteEntityFetched(state, entity_ref) {
    let completed_flags = Object.assign({}, state.complete_entity_fetched);
    completed_flags[entity_ref] = true;
    return {
        type: types.TASKS_STATE_ASSIGN,
        payload: {
            complete_entity_fetched: completed_flags,
        },
    };
}

export function setCompleteOrderFetched(state, order_id) {
    let completed_flags = Object.assign({}, state.complete_order_fetched);
    completed_flags[order_id] = true;
    return {
        type: types.TASKS_STATE_ASSIGN,
        payload: {
            complete_order_fetched: completed_flags,
        },
    };
}

export function clearState(state) {
    return {
        type: types.TASKS_STATE_CLEAR,
        payload: {},
    };
}
