import api_helpers from './helpers/api_helpers';
export default function registerServiceWorker(vapid_public_key) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/service_worker.js', {
                scope: '/',
            })
            .then(serviceWorkerRegistration => {
                console.log('Successfully registered service worker.');
            })
            .catch(err => {
                console.error('Failed to register service worker;');
                console.error(err);
            });

        navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
            const convertedVapidKey = urlBase64ToUint8Array(vapid_public_key);
            const options = {
                userVisibleOnly: true,
                applicationServerKey: convertedVapidKey,
            };
            serviceWorkerRegistration.pushManager
                .subscribe(options)
                .then(sub => {
                    console.debug('[PUSH]' + JSON.stringify(sub));
                    return api_helpers.registerPushSubscription(sub);
                })
                .then(() => {
                    console.log('[PUSH] Registered push subcription...');
                })
                .catch(err => {
                    console.error('[PUSH]', err);
                });
        });
    } else {
        console.error('No service worker support.');
    }
    console.debug('navigator.serviceWorker:', navigator.serviceWorker);
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
