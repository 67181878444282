export const PHOTO_STATE_ADD = 'PHOTO_STATE_ADD';

let default_state = {
    by_id: {},
};

export default function userReducer(state = default_state, action) {
    switch (action.type) {
        case PHOTO_STATE_ADD: {
            const by_id = {...state.by_id};
            by_id[action.payload.id] = action.payload;
            return Object.assign({}, state, {by_id});
        }
        default: {
            return state;
        }
    }
}
