import withStore from 'with-store';

import api_helpers from '../helpers/api_helpers';
import oh from 'output-helpers';

export function createOrder(vehicles, task_data_arr, order_data) {
    let order_req = {
        associated_org_ids: order_data.associated_orgs.map(org => org.id),
        data: {
            description: order_data.description || '',
        },
    };
    if (order_data.data.address) {
        order_req.data.address = order_data.data.address;
    }
    if (order_data.data.invoice_info) {
        order_req.data.invoice_info = order_data.data.invoice_info;
    }

    if (order_data.hasOwnProperty('location')) {
        order_req.location = order_data.location;
    }
    withStore.actions.spinner.add();
    return api_helpers
        .createOrder(order_req)
        .then(order => {
            let tasks = [];
            let task_group_id = 0;

            task_data_arr.forEach(task_data => {
                vehicles.forEach(vehicle => {
                    let task = {
                        order_id: order.id,
                        entity: vehicle.id,
                        type: task_data.type,
                        data: {
                            group_id: task_group_id,
                        },
                    };
                    if (
                        task_data.hasOwnProperty('tags') &&
                        task_data.tags.length > 0
                    ) {
                        task.associated_tag_ids = task_data.tags;
                    }
                    if (
                        task_data.data.hasOwnProperty('description') &&
                        task_data.data.description.length > 0
                    ) {
                        task.data.description = task_data.data.description;
                    }
                    tasks.push(task);
                });
                task_group_id++;
            });

            return Promise.all([api_helpers.createTasks(tasks), order]);
        })
        .then(tasks_and_order => {
            withStore.actions.spinner.remove();
            return {
                tasks: tasks_and_order[0],
                order: tasks_and_order[1],
            };
        });
}

function createOrderMatchStr(order) {
    let args = [];

    args.push(order.id.toString());

    if (order.data.invoice_info) {
        args.push(order.data.invoice_info);
    }
    if (order.data.address) {
        args.push(order.data.address);
    }
    if (order.associated_org_ids) {
        args = args.concat(
            order.associated_org_ids.map(org_id => {
                return withStore.getState().users.orgs_by_id[org_id].name;
            }),
        );
    }

    return args.join(':');
}

export function unpack(order_from_server) {
    order_from_server.match_str = createOrderMatchStr(order_from_server);
    order_from_server.associated_org_ids =
        order_from_server.associated_org_ids || [];
    order_from_server.associated_user_ids =
        order_from_server.associated_user_ids || [];
    order_from_server.data.description =
        order_from_server.data.description || '';
    return order_from_server;
}

export function pack(order) {
    let order_for_server = {
        id: order.id,
        last_modified: order.last_modified,
        state: order.state,
        data: Object.assign({}, order.data),
    };

    if (order.hasOwnProperty('assignee_org_id')) {
        order_for_server.assignee_org_id = order.assignee_org_id;
    }
    if (order.location) {
        order_for_server.location = order.location;
    }

    if (order.associated_org_ids) {
        order_for_server.associated_org_ids = order.associated_org_ids;
    }
    if (order.associated_user_ids) {
        order_for_server.associated_user_ids = order.associated_user_ids;
    }

    return order_for_server;
}

export function fetchOrderAudit(order_id) {
    api_helpers.fetchOrderAudit(order_id).then(audit_log => {
        withStore.actions.audit.setAudit(audit_log, 'order');
    });
}

export function resolve(order) {
    let resolved = Object.assign({}, order);
    resolved.creator = withStore.extensions.getUser(resolved.creator_id);
    resolved.display_date = oh.formatDateAsString(resolved.date_created);

    if (resolved.assignee_org_id) {
        resolved.assignee_org = withStore.extensions.getOrg(
            resolved.assignee_org_id,
        );
    }

    resolved.getAssociatedUsers = () => {
        return resolved.associated_user_ids.map(user_id =>
            withStore.extensions.getUser(user_id),
        );
    };
    resolved.getAssociatedOrgs = () => {
        return resolved.associated_org_ids.map(org_id =>
            withStore.extensions.getOrg(org_id),
        );
    };
    resolved.fetchAudit = () => {
        fetchOrderAudit(resolved.id);
    };

    return resolved;
}
export function loadOrder(order_id) {
    return api_helpers
        .fetchOrder(order_id)
        .then(order => withStore.actions.order.setOrder(order));
}
let fetching_approved = false;
export function fetchApproved() {
    if (
        withStore.getState().order.approved_fetched === false &&
        fetching_approved === false
    ) {
        fetching_approved = true;
        api_helpers.fetchOrders(null, 'approved').then(approved_orders => {
            withStore.actions.order.setApprovedFetched(true);
            withStore.actions.order.setOrders(approved_orders);
            fetching_approved = false;
        });
    }
}
