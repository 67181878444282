import axios from 'axios';
import withConfig from 'with-config';
import withStore from 'with-store';

import * as entity_helpers from './entity_helpers';
import * as schema_helpers from './schema_helpers';
import * as order_helpers from './order_helpers';
import * as task_helpers from './task_helpers';

let config = null;
let slack_queue = [];
const CancelToken = axios.CancelToken;
let paused = false;

let api_funcs = {
    fetchEntitySchema: collection => {
        const url = config.api.entities.api_uri + '/schemas/' + collection;
        return apiCall(url).then(schema => {
            return schema_helpers.unpackSchema(schema);
        });
    },

    fetchEntities: collection => {
        const url = config.api.entities.api_uri + '/entities/' + collection;
        let schema = withStore.getState().entity.schema[collection];
        return apiCall(url).then(entities => {
            return entities.map(entity =>
                entity_helpers.unpack(collection, schema, entity),
            );
        });
    },
    registerPushSubscription: sub => {
        return apiCall(config.api.tasks.api_uri + '/subscriptions', 'POST', {
            data: sub,
        });
    },
    fetchEntity: (collection, entity_id) => {
        //This entity_id is the ID part of a ref.
        const url =
            config.api.entities.api_uri +
            '/entities/' +
            collection +
            '/' +
            encodeURIComponent(entity_id);
        let schema = withStore.getState().entity.schema[collection];
        return apiCall(url).then(entity => {
            return entity_helpers.unpack(collection, schema, entity);
        });
    },
    fetchEntityAudit: (collection, entity_id) => {
        //This entity_id is the ID part of a ref.
        const url =
            config.api.entities.api_uri +
            '/entities/' +
            collection +
            '/' +
            encodeURIComponent(entity_id) +
            '/audit_log';
        return apiCall(url);
    },
    createEntity: (collection, entity_data) => {
        const url =
            config.api.entities.api_uri +
            '/entities/' +
            collection +
            '/' +
            encodeURIComponent(entity_data.data['meta.id']);
        let schema = withStore.getState().entity.schema[collection];
        return apiCall(url, 'POST', entity_data).then(entity => {
            return entity_helpers.unpack(collection, schema, entity);
        });
    },
    updateEntity: (collection, entity) => {
        let schema = withStore.getState().entity.schema[collection];
        let entity_data = entity_helpers.pack(collection, schema, entity);
        const url =
            config.api.entities.api_uri +
            '/entities/' +
            collection +
            '/' +
            encodeURIComponent(entity.data['meta.id']);
        return apiCall(url, 'PUT', entity_data).then(e => {
            return entity_helpers.unpack(collection, schema, e);
        });
    },
    deleteEntity: (collection, entity) => {
        let schema = withStore.getState().entity.schema[collection];
        let entity_data = entity_helpers.pack(collection, schema, entity);
        const url =
            config.api.entities.api_uri +
            '/entities/' +
            collection +
            '/' +
            encodeURIComponent(entity.data['meta.id']);
        return apiCall(url, 'DELETE', entity_data).then(e => {
            return entity_helpers.unpack(collection, schema, e);
        });
    },

    fetchUsersAndOrgs: () => {
        const users_url = config.api.sso.api_uri + '/users';
        const orgs_url = config.api.tasks.api_uri + '/orgs';
        const user_org_url = config.api.tasks.api_uri + '/user_orgs';

        return Promise.all([
            apiCall(users_url),
            apiCall(orgs_url),
            apiCall(user_org_url),
        ]).then(result => {
            let users = result[0];
            let orgs = result[1];
            let user_org_pairs = result[2];
            let orgs_by_id = {};
            orgs.forEach(org => {
                orgs_by_id[org.id] = {
                    id: org.id,
                    name: org.name,
                    users: [],
                };
            });

            let users_by_id = {};
            users.forEach(user => {
                user.installation_org_id = -1;
                user.full_name = user.last_name
                    ? user.first_name + ' ' + user.last_name
                    : user.first_name;
                users_by_id[user.id] = user;
            });
            user_org_pairs.forEach(user_org_pair => {
                let uid = user_org_pair.user_id;
                let oid = user_org_pair.org_id;
                if (orgs_by_id.hasOwnProperty(oid) === false) {
                    throw new Error(
                        'User belongs to non-existing organisation!',
                    );
                }
                orgs_by_id[oid].users.push(uid);
                if (users_by_id.hasOwnProperty(uid) === false) {
                    throw new Error(
                        "Installation org has a user_id which doesn't exist!: " +
                            uid,
                    );
                }
                if (users_by_id[uid].installation_org_id !== -1) {
                    throw new Error(
                        'User is assigned to multiple installation orgs.',
                    );
                }
                users_by_id[uid].installation_org_id = oid;
            });
            return {
                orgs_by_id: orgs_by_id,
                users_by_id: users_by_id,
            };
        });
    },

    fetchMessagesByRef: ref_id => {
        const url =
            config.api.tasks.api_uri +
            '/messages?ref=' +
            encodeURIComponent(ref_id);
        return apiCall(url);
    },
    postMessage: msg => {
        const url = config.api.tasks.api_uri + '/messages';
        return apiCall(url, 'POST', msg);
    },

    fetchTags: () => {
        const url = config.api.tasks.api_uri + '/tags';
        return apiCall(url);
    },
    addTag: new_tag => {
        const url = config.api.tasks.api_uri + '/tags';
        return apiCall(url, 'POST', { name: new_tag });
    },

    fetchTask: task_id => {
        const url = config.api.tasks.api_uri + '/tasks/' + task_id;
        return apiCall(url);
    },
    fetchTasks: (order_id = null, entity = null, tag_id = null) => {
        const url = config.api.tasks.api_uri + '/tasks';
        let params = {};
        if (order_id) {
            params.order_id = order_id;
        }
        if (tag_id) {
            params.tag_id = tag_id;
        }
        if (entity) {
            params.entity = entity;
        }
        return apiCall(url, 'GET', null, params).then(tasks => {
            return tasks.map(task => task_helpers.unpack(task));
        });
    },
    createTasks: task_arr => {
        const url = config.api.tasks.api_uri + '/tasks';
        return apiCall(url, 'POST', task_arr).then(tasks => {
            return tasks.map(task_helpers.unpack);
        });
    },
    updateTask: task => {
        const url = config.api.tasks.api_uri + '/tasks/' + task.id;
        return apiCall(url, 'PUT', task_helpers.pack(task)).then(task => {
            return task_helpers.unpack(task);
        });
    },
    updateTasks: tasks => {
        const url = config.api.tasks.api_uri + '/tasks';
        return apiCall(
            url,
            'PUT',
            tasks.map(task => task_helpers.pack(task)),
        ).then(tasks => {
            return tasks.map(task => task_helpers.unpack(task));
        });
    },
    deleteTasks: tasks => {
        const url = config.api.tasks.api_uri + '/tasks';
        return apiCall(
            url,
            'DELETE',
            tasks.map(task => task_helpers.pack(task)),
        ).then(tasks => {
            return tasks.map(task => task_helpers.unpack(task));
        });
    },
    fetchTaskAudit: task_id => {
        let url = config.api.tasks.api_uri + '/tasks/' + task_id + '/audit_log';
        return apiCall(url);
    },

    fetchOrder: order_id => {
        let url = config.api.tasks.api_uri + '/orders/' + order_id;
        return apiCall(url).then(order => {
            return order_helpers.unpack(order);
        });
    },
    fetchOrders: (org_id = null, states = null) => {
        // return [];
        let url = config.api.tasks.api_uri + '/orders';
        let params = {
            states: states ? states : config.open_states.join(','),
        };
        if (org_id) {
            params.associated_org_id = org_id;
        }
        return apiCall(url, 'GET', null, params).then(orders => {
            return orders.map(order => order_helpers.unpack(order));
        });
    },
    createOrder: order_data => {
        const url = config.api.tasks.api_uri + '/orders';
        return apiCall(url, 'POST', order_data).then(order => {
            return order_helpers.unpack(order);
        });
    },
    updateOrder: order => {
        const url = config.api.tasks.api_uri + '/orders/' + order.id;
        return apiCall(url, 'PUT', order_helpers.pack(order)).then(order => {
            return order_helpers.unpack(order);
        });
    },
    deleteOrder: order => {
        const url = config.api.tasks.api_uri + '/orders/' + order.id;
        return apiCall(url, 'DELETE', order_helpers.pack(order)).then(order => {
            return order_helpers.unpack(order);
        });
    },
    fetchOrderAudit: order_id => {
        let url =
            config.api.tasks.api_uri + '/orders/' + order_id + '/audit_log';
        return apiCall(url);
    },

    postToSlack: msg => {
        if (!config.slack || !config.slack.webhook_uri) {
            let err = new Error(
                "PostToSlack: Property 'slack_webhook' not specified in config.",
            );
            console.error(err);
            return Promise.reject(err);
        }

        if (config.send_posts_to_slack === false) {
            console.log(
                'PostToSlack: Not sent because of disable_slack_posting flag!',
            );
            console.log('PostToSlack msg:', msg);
            return Promise.resolve();
        }

        slack_queue.push(msg);
    },

    flushToSlack: () => {
        if (slack_queue.length === 0) {
            return;
        }
        let msg = 'INSTALLATION USER PostToSlack: \n' + slack_queue.join('\n');
        slack_queue = [];

        let payload = encodeURIComponent(JSON.stringify({ text: msg }));
        let url = config.slack.webhook_uri;
        // return apiCall(url, "POST", "payload=" + payload);
        return axios({
            url: url,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
            data: 'payload=' + payload,
        })
            .then(res => {
                console.log('PostToSlack: Successfully posted to slack.');
            })
            .catch(err => {
                console.error('PostToSlack: Failed to post to slack.');
                console.error(err);
            });
    },
    uploadPhotoFiles: (base64_string, mime_type) => {
        const url = config.api.images.api_uri;
        const data = Uint8Array.from(atob(base64_string), c => c.charCodeAt(0));
        return apiCall(url, 'POST', data, null, null, {
            'content-type': mime_type,
        }).then(photo => photo.id);
    },
    fetchPhotos: entity => {
        const parsed_photos =
            entity.data['meta.photos'] !== ''
                ? JSON.parse(entity.data['meta.photos'])
                : [];
        if (parsed_photos && Array.isArray(parsed_photos)) {
            return Promise.all(
                parsed_photos.map(photo_id => {
                    const url = config.api.images.api_uri + '/' + photo_id;
                    const jwt_token = window.sso.getJWT().getRaw();
                    return axios({
                        url: url,
                        headers: {
                            Authorization: `Bearer ${jwt_token}`,
                        },
                        responseType: 'arraybuffer',
                    });
                }),
            ).then(values => {
                return values.map((value, index) => ({
                    id: parsed_photos[index],
                    data: value.data,
                    type: value.headers['content-type'],
                }));
            });
        }
    },
};

setInterval(api_funcs.flushToSlack, 3000);

let pending_requests = [];

function apiCall(
    url,
    method = 'GET',
    data = null,
    params = null,
    response_type = null,
    headers = null,
) {
    if (paused) {
        return Promise.reject(new axios.Cancel('Network is paused.'));
    }

    let stack = new Error().stack;

    const source = CancelToken.source();

    const jwt_token = window.sso.getJWT().getRaw();
    let req = {
        url: url,
        method: method,
        cancelToken: source.token,
        headers: {
            Authorization: `Bearer ${jwt_token}`,
        },
    };
    if (data) {
        req.data = data;
    }
    if (params) {
        req.params = params;
    }
    if (response_type) {
        req.responseType = response_type;
    }
    if (headers) {
        req.headers = { ...req.headers, ...headers };
    }
    pending_requests.push(source);
    return axios(req)
        .then(res => {
            pending_requests = pending_requests.filter(
                existing_sources => existing_sources !== source,
            );
            return res.data;
        })
        .catch(err => {
            pending_requests = pending_requests.filter(
                existing_sources => existing_sources !== source,
            );
            err.stack = stack;
            err.network_error = true;
            throw err;
        });
}

function cancelAndPreventRequests() {
    paused = true;
    pending_requests.forEach(source => {
        source.cancel();
    });
}

function resumeRequests() {
    paused = false;
}

let export_funcs = {};

export_funcs.cancelAndPreventRequests = cancelAndPreventRequests;
export_funcs.resumeRequests = resumeRequests;

Object.keys(api_funcs).forEach(function_name => {
    export_funcs[function_name] = function() {
        if (config) {
            return api_funcs[function_name](...arguments);
        }
        return withConfig.fetch().then(cfg => {
            config = cfg;
            return api_funcs[function_name](...arguments);
        });
    };
});

export default export_funcs;
