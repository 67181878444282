import * as audit_actions from './audit_actions';
import audit_reducer from './audit_reducer';
import * as entity_actions from './entity_actions';
import entity_reducer from './entity_reducer';
import * as interaction_actions from './interaction_actions';
import interaction_reducer from './interaction_reducer';
import * as messages_actions from './messages_actions';
import messages_reducer from './messages_reducer';
import * as order_actions from './order_actions';
import order_reducer from './order_reducer';
import * as spinner_actions from './spinner_actions';
import spinner_reducer from './spinner_reducer';
import * as tags_actions from './tags_actions';
import tags_reducer from './tags_reducer';
import * as tasks_actions from './tasks_actions';
import tasks_reducer from './tasks_reducer';
import * as users_actions from './users_actions';
import users_reducer from './users_reducer';
import photo_reducer from './photo_reducer';
import * as photo_actions from './photo_actions';

export default {
    audit: { actions: audit_actions, reducer: audit_reducer },
    entity: { actions: entity_actions, reducer: entity_reducer },
    interaction: { actions: interaction_actions, reducer: interaction_reducer },
    messages: { actions: messages_actions, reducer: messages_reducer },
    spinner: { actions: spinner_actions, reducer: spinner_reducer },
    tasks: { actions: tasks_actions, reducer: tasks_reducer },
    order: { actions: order_actions, reducer: order_reducer },
    tags: { actions: tags_actions, reducer: tags_reducer },
    users: { actions: users_actions, reducer: users_reducer },
    photos: { actions: photo_actions, reducer: photo_reducer },
};
