import * as types from './interaction_reducer';

export function set(state, obj) {
    return {
        type: types.INTERACTION_STATE_ASSIGN,
        payload: obj,
    };
}

export function remove(state, key) {
    return {
        type: types.INTERACTION_STATE_REMOVE_KEY,
        payload: key,
    };
}
