import React from "react";
import styled from "styled-components";
import withStore from "with-store";
import { MdDirectionsBus, MdTrain, MdKeyboardArrowRight } from "react-icons/md";

class VehicleList extends React.Component {
  constructor(props) {
    super(props);

    this.container = styled.div`
      .vehicle_list {
        li {
          background-color: #fff;
          border-bottom: 1px solid ${props => props.theme.colors.border};
          .vehicle_icon,
          .vehicle_labels,
          .vehicle_nav {
            display: inline-block;
            vertical-align: middle;
          }
          .vehicle_icon {
            width: 50px;
            .box {
              margin: 10px;
              width: 40px;
              height: 40px;
              border-radius: 12px;
              background-color: ${props => props.theme.colors.blue[2]};
              &.train {
                background-color: #f3c006;
              }
              &.no_vehicle_number {
                background-color: #f48a78;
              }
              svg {
                fill: #fff;
                margin: 7px;
                width: 26px;
                height: 26px;
              }
            }
          }
          .vehicle_labels {
            width: calc(100% - 100px);
            padding-left: 20px;
            white-space: nowrap;
            font-size: 14px;
            .vehicle_list_item_id {
              font-size: 16px;
              font-weight: bold;
            }
            .vehicle_list_item_number {
              font-size: 14px;
              position: absolute;
              right: 56px;
              min-width: 55px;
              text-align: center;
              background-color: #0943a9;
              color: #fff;
              border-radius: 3px;
              padding: 2px 6px;
              font-size: 14px;
            }
            .vehicle_list_item_depot {
              opacity: 0.7;
              font-size: 14px;
              margin-top: 4px;
            }
          }
          .vehicle_nav {
            width: 50px;
            svg {
              fill: ${props => props.theme.colors.gray[1]};
              vertical-align: middle;
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    `;
  }

  getIcon(vehicle) {
    let styleClass = "box";
    let icon = <MdDirectionsBus />;

    if (
      vehicle.data["meta.vehicle_type"] &&
      vehicle.data["meta.vehicle_type"] === "Tåg"
    ) {
      icon = <MdTrain />;
      styleClass += " train";
    }

    if (vehicle.data["meta.vehicle_number"] === null) {
      styleClass += " no_vehicle_number";
    }

    return <div className={styleClass}>{icon}</div>;
  }

  renderVehicleListItems() {
    let vehicles = this.props.se.getVehicles();
    if (!vehicles) {
      return null;
    }
    if (this.props.search !== "") {
      vehicles = vehicles.filter(vehicle =>
        vehicle.match_str
          .toLowerCase()
          .includes(this.props.search.toLowerCase())
      );
    }
    return vehicles.map((vehicle, i) => {
      return (
        <li
          key={i}
          onClick={() => {
            this.props.se.setSelectedEntity(vehicle.id);
          }}
        >
          <div className="vehicle_icon">{this.getIcon(vehicle)}</div>
          <div className="vehicle_labels">
            <p className="vehicle_list_item_id">
              {vehicle.data["meta.id"]}
              <span className="vehicle_list_item_number">
                {vehicle.data["meta.vehicle_number"]
                  ? vehicle.data["meta.vehicle_number"]
                  : "-"}
              </span>
            </p>
            <p className="vehicle_list_item_depot">
              {vehicle.data["meta.depot"]}
            </p>
          </div>
          <div className="vehicle_nav">
            <MdKeyboardArrowRight />
          </div>
        </li>
      );
    });
  }

  render() {
    return (
      <this.container>
        <ul className="vehicle_list">{this.renderVehicleListItems()}</ul>
      </this.container>
    );
  }
}

export default withStore(VehicleList);
