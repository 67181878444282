export const ADD_TO_SPINNER = 'ADD_TO_SPINNER';
export const ADD_TO_SMALL_SPINNER = 'ADD_TO_SMALL_SPINNER';

const default_state = {
    count: 0,
    small_count: 0,
};

export default (state = default_state, action) => {
    switch (action.type) {
        case ADD_TO_SPINNER: {
            let new_count = state.count + action.payload;
            return Object.assign({}, state, {
                count: new_count < 0 ? 0 : new_count,
            });
        }

        case ADD_TO_SMALL_SPINNER: {
            let new_count = state.small_count + action.payload;
            return Object.assign({}, state, {
                small_count: new_count < 0 ? 0 : new_count,
            });
        }

        default: {
            return state;
        }
    }
};
