import * as store_helpers from './store_helpers';

export const ORDER_STATE_CLEAR = 'ORDER_STATE_CLEAR';
export const ORDER_STATE_ASSIGN = 'ORDER_STATE_ASSIGN';
export const ORDER_STATE_SET_ORDER = 'ORDER_STATE_SET_ORDER';
export const ORDER_STATE_SET_ORDERS = 'ORDER_STATE_SET_ORDERS';
export const ORDER_STATE_DELETE_ORDER = 'ORDER_STATE_DELETE_ORDER';

let default_state = {
    approved_fetched: false,
    fetched: false,
    all: [],
    by_id: {},
};

export default function orderReducer(state = default_state, action) {
    switch (action.type) {
        case ORDER_STATE_CLEAR: {
            return Object.assign({}, default_state);
        }
        case ORDER_STATE_ASSIGN: {
            return Object.assign({}, state, action.payload);
        }
        case ORDER_STATE_SET_ORDER: {
            return store_helpers.SetItem(action.payload, state);
        }
        case ORDER_STATE_SET_ORDERS: {
            return store_helpers.SetItems(action.payload, state);
        }
        case ORDER_STATE_DELETE_ORDER: {
            return store_helpers.DeleteItem(action.payload, state);
        }
        default: {
            return state;
        }
    }
}
