export let AllbinaryTheme = {
    sizes: {
        sub_navbar: {
            height: 52,
            extended_height: 110,
            parent_height: 52,
        },
    },
    colors: {
        brand: ['#0A2E6E', '#1563EE', '#5D92F1'],
        disabled_input: '#AAA',
        text: '#37393d', //'rgba(0, 0, 0, 0.87)',//'#252424',
        border: 'rgba(0, 0, 0, 0.12)', //'#CED9EE',
        background: '#E6E9F2;', //'#E6E9F2',//'#eeeeee',//'#E6E9F2',
        fade: 'rgba(44, 53, 70, 0.29)',
        shadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
        blue: ['#0A2E6E', '#1563EE', '#5D92F1', '#EFF9FE'],
        gray: ['#5C6778', '#B1C3DC', '#C0CFE3', '#E0E7F0'],
        dark: ['#2B344D', '#364161', '#3D496C', '#5C6778'],
        red: ['#bf392c', '#EB4D44', '#F47048', '#ff866b'],
        yellow: ['rgb(179, 141, 28)', 'rgb(231, 219, 60)', 'rgb(255, 253, 6)'],
        green: ['#32ab49', '#3fb742', '#57d547'],
        teal: ['#023238', '#046A77', '#0c7785'],
        states: {
            assigned: '#F9AE17',
            published: '#1563EE',
            completed: '#32ab35',
            approved: '#43735a',
        },
        states_dimmed: {
            assigned: '#fff5de',
            published: '#b9caea',
            completed: '#d5edd6',
            approved: '#8da699',
        },
    },
};

export let reset_css = {
    reset: `
        html, body, div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
        }
        /* HTML5 display-role reset for older browsers */
        article, aside, details, figcaption, figure,
        footer, header, hgroup, menu, nav, section {
            display: block;
        }
        body {
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: #E6E9F2;
            color: #252424;
            margin: 0;
            font-family: 'Roboto', sans-serif;
            overscroll-behavior-y: contain;
        }
        ol, ul {
            list-style: none;
        }
        blockquote:before, blockquote:after,
        q:before, q:after {
            content: '';
            content: none;
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
        }
        a {
            color: inherit;
            text-decoration: none;
            &:VISITED{
                color: inherit;
            }
        }
        html{
            padding: 0;
            margin: 0;
        }

        * {
            box-sizing: border-box;
        }
        em{
            font-style: italic;
        }
        strong{
            font-weight: bold;
        }
        input{
            &:FOCUS{
                outline: none;
            }
        }
        .Select{
            .Select-control{
                border-color: #CED9EE;
            }
        }
        .Select-option{
            &:HOVER{
                color: #fff;
                background-color: #5D92F1;
            }
        }
        i{
            font-style: italic;
        }
        b{
            font-weight: bold;
        }
        .debug{
            font-size: 12px;
            opacity: 0.6;
        }
        .user_input{
            input{
                font-size: 22px !important;
                padding: 12px !important;
            }
        }
        .modal_container{
            .modal_title{
                margin: 20px !important;
            }
            .modal_body{
                button{
                    padding: 20px 0 !important;
                    font-size: 16px !important;
                    border-radius: 4px !important;
                }
            }
        }
        .modal_container .modal_footer{
            display: flex !important;
            flex-flow: column !important;

            .modal_footer_cancel, .modal_footer_confirm{
                width: 100% !important;
                display: block;
                button{
                    width: 100% !important;
                    border-radius: 6px !important;
                }
            }
            .modal_footer_cancel{
                order: 2 !important;
            }
            .modal_footer_confirm{
                order: 1 !important;
                margin-bottom: 12px;
            }
        }
    `,
};
