export default {
    'sv-SE': {
        'device-install': 'installera',
        'device-repair': 'reparera',
        add_entities: 'fordon',
        add_existing: 'lägg till från existerande',
        add_item: 'lägg till',
        add_images: 'ta foto',
        add_new_location: 'spara plats',
        add_new_vehicle: 'lägg till nytt fordon',
        add_new: 'lägg till ny',
        add_order: 'arbetsuppgifter',
        add_special_note: 'anmärkning',
        add_what: 'arbetsuppgifter',
        add_where: 'hitta plats',
        add_who: 'entreprenörer',
        add: 'lägg till',
        address: 'adress',
        all: 'alla',
        approved: 'godkända',
        are_you_sure: 'är du säker?',
        assigned: 'pågående',
        assignee_org: 'entreprenör som har antagit ärendet',
        associated_orgs: 'associerade entreprenörer',
        associated_users: 'associerade användare',
        back: 'tillbaka',
        bus: 'buss',
        buses: 'bussar',
        cancel: 'avbryt',
        change_state: 'ändra status',
        completed: 'färdiga',
        concurrent_modification: 'kollision',
        concurrent_modification_desc:
            'Någon annan har manipulerat det objekt du just ville ändra på. Därför har vi avbrutit din ändring tills du har haft en chans att se över det uppdaterade objektet och besluta dig för om du fortfarande vill göra din ändring.',
        confirm_leave_view: 'är du säker på att du vill avbryta?',
        confirm: 'bekräfta',
        create_add_task: 'lägg till arbetsuppgift',
        create_edit_location_subtitle: 'platå, servicehall eller adress',
        create_entities_bread:
            'Till höger lägger du till de fordon som ärendet gäller för. Ärendehanteringssystem, förkortat ÄHS, är informationssystem som skapas för att stödja administration av ärenden i en organisation.',
        create_entities_subtitle: 'Lägg till fordon till höger',
        create_entities_title: 'På vilka fordon ska arbetet utföras?',
        create_manually: 'skapa manuellt',
        create_summary_bread: 'kontrollera att alla uppgifter stämmer',
        create_summary_title: 'Summering av ärende',
        create_what_bread:
            'Lägg till vad som ska göras på fordonen till höger. Ett ärende kan innehålla fritt antal arbetsuppgifter.',
        create_what_subtitle: 'Lägg till Arbetsuppgifter',
        create_what_title: 'Vilka arbetsuppgifter ska utföras?',
        create_where_bread: 'Hitta din plats till höger',
        create_where_subtitle: 'var',
        create_where_title: 'På vilken plats befinner sig fordonen?',
        create_who_bread:
            'Arbetsgivaren har huvudansvaret. Arbetsgivaren kan också välja ut personer som får i uppgift att jobba med arbetsmiljön.',
        create_who_subtitle: 'vem',
        create_who_title: 'Vem ska göra det?',
        created_by: 'skapad av',
        created_date: 'skapad',
        invoice_info: 'faktureringuppgifter',
        date: 'datum',
        done: 'klar',
        describe_task: 'fyll i information om arbetsuppgiften',
        describe_why_admin_order_complete:
            'alla uppgifter som inte svarats av kommer att automatiskt svaras av som ej utförda. beskriv nedan varför ni valt att tvinga en färdigställning av detta uppdrag.',
        describe_why_admin_order_reject:
            'ärendet kommer att skickas tillbaka till entreprenören. beskriv nedan varför ni valt att skicka tillbaka ärendet.',
        describe_why_user_order_complete:
            'det finns fortfarande uppgifter kvar i ärendet som inte har svarats av. om du färdigställer uppdraget nu så kommer alla obesvarade uppgifter att svaras av som ej utförda. beskriv i så fall nedan varför du färdigställer uppdraget i förtid',
        empty_list: 'tomt',
        enter_address: 'ange adress',
        enter_location: 'ange plats',
        enter_message: 'skriv meddelande',
        enter_new_tag: 'lägg till ny tagg',
        enter_note_for: 'anmärkning för',
        enter: 'ange',
        entities: 'fordon',
        entity: 'fordon',
        edit: 'redigera',
        entrepreneur: 'entreprenör',
        error_has_occured: 'något har gått fel',
        do_you_want_to_delete_this_picture:
            'Vill du verkligen ta bort denna bild?',
        fetching_data: 'laddar data',
        go_to_start: 'återvänd till startskärmen',
        have_edit: 'har gjort ändringar',
        how_to_remove_photo:
            'Klicka på den bild du önskar att ta bort. Vill du inte ta bort någon bild, tryck då på "tillbaka" ovan',
        have_not_edit: 'inte gjort ändringar',
        have_you_edited_the_vehicle:
            'har du lagt till, tagit bort eller åtgärdat en inventarie i fordonet?',
        history: 'historik',
        id_reg: 'ID / Reg',
        id: 'id',
        incomplete_create_order_wizard:
            'Lägg till minst en entreprenör, uppgift och fordon.',
        information: 'information',
        inventory: 'inventarier',
        inventory_for: 'inventarier för',
        list: 'lista',
        loading: 'laddar ärenden...',
        map: 'karta',
        mark_done: 'markera som fakturerad',
        messages: 'meddelanden',
        message_required: 'meddelande krävs',
        message_required_long:
            'För att kunna svara av en uppgift som ej utförd krävs att du beskriver varför uppgiften inte blev utförd.',
        mine: 'mina',
        misc: 'fritext',
        new_tag: 'skapa ny tagg',
        next: 'nästa',
        none_added_to_list: 'inga tillagda',
        note: 'anteckning',
        no_location: 'ingen plats angiven',
        no_orders_in_completed_state: 'inga ärenden',
        no_orders_in_completed_state_subtitle:
            'Du har inga uppdrag som väntar på administratörens godkännande',
        no_orders: 'inga ärenden',
        no_org: 'ingen entreprenör',
        no_address: 'ingen angiven plats',
        no_published_orders: 'inga oavklarade ärenden finns utlagda',
        no_result_entities: 'Hittade inget fordon',
        no_result_what: 'Det finns inga mallar för arbetsuppgifter',
        no_result_who: 'Hittade ingen entreprenör',
        no_search_results: 'inga resultat för söktermer',
        no_vehicles: 'inga fordon',
        offline_message: 'internetanslutning saknas...',
        open_camera: 'Öppna kamera',
        pick_from_camera_roll: 'Välj från kamerarulle',
        order: 'ärende',
        orders: 'ärenden',
        order_state_change_assign: 'anta ärende',
        order_state_change_complete: 'färdigställ ärende',
        order_state_change_reclaim: 'ångra färdigställning',
        order_state_change_reject: 'tillbakavisa ärende',
        order_state_change_release: 'frigör ärende',
        order_state_change_approve: 'godkänn ärende',
        others: 'övriga',
        photos: 'foton',
        place_new_order: 'lägg ut nytt ärende',
        place_order: 'lägg ut ärende',
        previous: 'tillbaka',
        published: 'utlagt',
        remove: 'ta bort',
        remove_image: 'ta bort foto',
        save: 'spara',
        search: 'sök',
        send: 'skicka',
        note_for: 'anteckning för',
        singular_done: 'fakturerad',
        singular_approved: 'godkänd',
        singular_assigned: 'pågående',
        singular_completed: 'färdig',
        singular_published: 'utlagd',
        use_image: 'använd bild',
        change_image: 'byt bild',
        you_are_not_in_edit_mode: 'du är inte i redigeringsläget',
        press_edit: "tryck på knappen 'Redigera' i toppen av vyn",
        start: 'start',
        state: 'status',
        task_layout_task_vehicle: 'arbete fordon',
        task_layout_vehicle_task: 'Fordon arbete',
        list_entities: 'fordon att lista',
        summary: 'summering',
        task_description: 'beskrivning',
        yes: 'ja',
        no: 'nej',
        task_save: 'lägg till arbetsuppgift',
        task_tags: 'tagga arbetsuppgiften',
        task_type: 'typ av arbetsuppgift',
        task_uncomplete_desc:
            'beskriv nedan varför uppgiften inte blivit utförd',
        tasks: 'arbetsuppgifter',
        trains: 'tåg',
        unknown: 'okänt',
        vehicle_type: 'fordonstyp',
        vehicles: 'fordon',
        vin_number: 'chassi nummer',
        what: 'arbetsuppgifter',
        where: 'plats',
        who: 'entreprenör',
        work: 'uppgifter',
        you_have_no_associated_orders: 'du har inga antagna ärenden',
        your_organization_has_no_orders: 'det finns inga övriga ärenden',
        you_have_no_associated_orders_subtitle:
            'navigera till taben "Övriga" ovan för att hitta alla utlagda ärenden',
        this_property_is_not_modifiable: 'det här värdet går inte att redigera',
        contact_admin_if_it_is_incorrect:
            'om du anser den vara felaktigt, kontakta admin',
    },
    'en-US': {},
};
