import withProfile from 'with-profile';
import withStore from 'with-store';

export let default_profile = {
    last_viewed: {},
    last_login: '1970-01-01T00:00:00.000Z',
    current_login: '1970-01-01T00:00:00.000Z',
    last_logut: '1970-01-01T00:00:00.000Z',
    last_app_entry: '1970-01-01T00:00:00.000Z',
    current_app_entry: '1970-01-01T00:00:00.000Z',
    last_app_exit: '1970-01-01T00:00:00.000Z',
};

export let extensions = {
    getLastViewed: {
        states: ['profile'],
        mnemonic: 'argument_check',
        fn: (states, id, category = 'default') => {
            let prof = states.profile;
            if (prof.last_viewed.hasOwnProperty(category)) {
                if (prof.last_viewed[category].hasOwnProperty(id)) {
                    return prof.last_viewed[category][id];
                }
            }
            return null;
        },
        help: `getLastViewed(id, category = "default") -
    id <string>,
    category <string, optional> - The category ID belongs to, if any.
    RETURNS the value recorded for ID, if it exists, otherwise returns null.
    `,
    },
};

export function setLastViewed(id, category = 'default', iso_date = null) {
    let last_viewed = withStore.getState().profile.last_viewed;
    last_viewed = Object.assign({}, last_viewed);
    if (last_viewed.hasOwnProperty(category) === false) {
        last_viewed[category] = {};
    }
    last_viewed[category][id] = iso_date || new Date().toISOString();
    withProfile.saveProfileOptimistic({ last_viewed: last_viewed });
}
export function removeLastViewed(id, category = 'default') {
    let last_viewed = withStore.getState().profile.last_viewed;
    last_viewed = Object.assign({}, last_viewed);
    if (last_viewed.hasOwnProperty(category)) {
        if (last_viewed[category].hasOwnProperty(id)) {
            delete last_viewed[category][id];
        }
    }
    withProfile.saveProfileOptimistic({ last_viewed: last_viewed });
}
export function setLastLogin(iso_date = null) {
    withProfile.saveProfileOptimistic({
        current_login: iso_date || new Date().toISOString(),
        last_login: withStore.getState().profile.current_login,
    });
}
export function setLastLogout(iso_date = null) {
    withProfile.saveProfileOptimistic({
        last_logout: iso_date || new Date().toISOString(),
    });
}

export function updateProfile(obj) {
    withProfile.saveProfileOptimistic(obj);
}
export function doAfterFirstFetch(cb) {
    withProfile.doAfterFirstFetch(cb);
}

export function resetProfile() {
    return withProfile.saveProfile(default_profile, {
        current_app_entry: new Date().toISOString(),
    });
}
